<template>
    <div class="all-content">
        <div class="bg-white content-row-new">
            <div class="confirm-list regist-card-success">
                <p style="justify-content: flex-end">
                    <img src="/image/character_happy.svg" alt="캐릭터">
                </p>
                <div class="comment">
                    <em class="t-c pink-title">신용카드가 정상적으로<br/>등록되었습니다.</em>
                    <div class="w-90">
                        <p class="info">
                            <span class="title">카드번호</span>
                            <span class="cardInfo">{{ cardInfo.cardData }}</span>
                        </p>
                        <p class="info mt-10">
                            <span class="title">등록일시</span>
                            <span class="cardInfo">{{ date }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <button class="btn-pink mr-auto btn-m" @click="$router.back()">확 인</button>
        </div>
    </div>
</template>

<script>
import EventBus from "@/main";

export default {
    name: "RegistCardSuccess",

    data() {
        return {
            cardInfo: this.$route.params.cardInfo,
            date: '',
        }
    },

    created() {
        if (!this.cardInfo) {
            this.$router.push({name: 'CardList'})
        }

        let createdAt = this.cardInfo.createdAt.split('T')
        let date = createdAt[0].replaceAll('-', '.')
        let time = createdAt[1].split('.')[0]
        this.date = date + ' ' + time
    },

    beforeRouteLeave(to, from, next) {
        if (to.name === 'RentPaymentInfoInput') {  // 월세-결제정보 입력
            EventBus.$emit(this.$EVENT_REFRESH_CARDINFO)
        }
        if (to.name === 'ChargeSalesMonthlyRepay') {
            EventBus.$emit(this.$EVENT_REFRESH_CARDINFO_REPAY)
        }
        next()
    }
}
</script>

<style scoped>

</style>
