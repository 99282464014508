<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="btnBack"><i class="icon-back"></i></button>
      <img class="mr-10" v-if="logoImg" :src=logoImg alt="logo" style="height: 45px; max-width: 90px; object-fit: contain">
      <p>배달비 충전</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap">
        <div class="charge-form">
          <p class="mb-10 fz-18">배대사</p>
          <div class="select-wrap">
            <button class="select" v-if="selectedDeliveryAgency !== null" @click="openDeliveryAgencyList()">
              {{ selectedDeliveryAgency.nickname ? selectedDeliveryAgency.nickname : '이름없음(' + selectedDeliveryAgency.idx + ')'}} ({{ selectedDeliveryAgency.bankName }} {{ selectedDeliveryAgency.accountNumber }} {{ selectedDeliveryAgency.depositor }})
            </button>
            <button class="select color-gray" v-else-if="deliveryAgencyList.length > 0" @click="openDeliveryAgencyList()">배대사 선택</button>
            <button class="select color-gray" v-else @click="openDeliveryAgencyList()">등록된 신용카드가 없습니다.</button>
          </div>

          <p class="mb-10 mt-30 fz-18">충전금액</p>
          <div class="mt-10 select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'충전금액을 입력해주세요.(10,000원 이상)'"
                :model="amount"
                @update="(_model) => {this.amount=_model}"
                :input-mode="'decimal'"
                :withComma="true"
            />
          </div>
          <span class="color-red">{{ errors.amount }}</span>

          <p class="mb-10 mt-30 fz-18">신용카드</p>
          <div class="select-wrap">
            <button class="select" v-if="selectedCard !== null" @click="openCardList()">
              {{ selectedCard.name + ' ' }}{{ selectedCard.number | cardNumberMasking }}
            </button>
            <button class="select color-gray" v-else-if="cardList.length > 0" @click="openCardList()">카드 선택</button>
            <button class="select color-gray" v-else @click="openCardList()">등록된 신용카드가 없습니다.</button>
          </div>
        </div>
        <span class="color-red">{{ errors.selectedCard }}</span>
        <div v-if="this.amount.replaceAll(',', '') > 50000">
            <p class="mb-10 mt-30 fz-16 color-darkgray weight-light">할부기간을 선택해주세요</p>
            <div class="select-wrap">
                <button class="select" v-if="selectedInstallment !== null" @click="openInstallmentPeriod()">
                    {{ selectedInstallment.name }}
                </button>
            </div>
        </div>
      </div>
      <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="charge()">충전</button>
    </div>

    <charge-card-select
        v-show="showCardList"
        :cards="cardList"
        @selected="setSelectedCard"
        @close="showCardList=false"/>

    <charge-delivery-agency-select
        v-show="showDeliveryAgencyList"
        :deliveryAgencies="deliveryAgencyList"
        @selected="setSelectedDeliveryAgency"
        @close="showDeliveryAgencyList=false"/>

    <installment-period-select
      v-show="showInstallmentList"
      :periods="installmentList"
      @selected="setInstallmentPeriod"
      @close="showInstallmentList=false"/>

    <pin-check
        ref="PinCheck"
        :title="'충전'"
        :amount="amount"
        :unit="'원'"
        :sub-amount="0"
        :sub-unit="''"
    />

  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import ChargeCardSelect from "@/components/charge/ChargeCardSelect";
import ChargeDeliveryAgencySelect from "@/components/charge/ChargeDeliveryAgencySelect";
import PinCheck from "@/components/common/PinCheck";
import InstallmentPeriodSelect from "@/components/payment/rentPayment/InstallmentPeriodSelect.vue";

export default {
  name: "Charge",
  components: {
    InstallmentPeriodSelect,
    ChargeCardSelect,
    ChargeDeliveryAgencySelect,
    RPInputBorder,
    PinCheck
  },
  data() {
    return {
      amount: '',
      installment: 0,
      cardList: [],
      selectedCard: null,
      showCardList: false,
      deliveryAgencyList: [],
      selectedDeliveryAgency: null,
      showDeliveryAgencyList: false,
      errors: {
        amount: '',
        selectedCard: '',
        selectedDeliveryAgency: '',
      },
      showInstallmentList: false, // 할부기간
      installmentList: [
        {
          'installment': 0,
          'name': '일시불'
        },
        {
          'installment': 2,
          'name': '2개월'
        },
        {
          'installment': 3,
          'name': '3개월'
        },
        {
          'installment': 4,
          'name': '4개월'
        },
        {
          'installment': 5,
          'name': '5개월'
        },
        {
          'installment': 6,
          'name': '6개월'
        },
      ],
      selectedInstallment: null,
      logoImg: ''
    }
  },
  mounted() {
    if (this.$route.params.amount) {
      this.amount = this.$route.params.amount
      this.selectedCard = this.$route.params.selectedCard
      this.selectedDeliveryAgency = this.$route.params.selectedDeliveryAgency
    }
    this.getCardList()
    this.getDeliveryAgencyList()
    this.getLogoImg()
  },
  methods: {
    openInstallmentPeriod() {
      this.showInstallmentList = true
    },
    setInstallmentPeriod(_period) {
      this.selectedInstallment = _period
      this.showInstallmentList = false
    },
    errorClear(){
      this.errors = {
        amount: '',
        selectedCard: '',
        selectedDeliveryAgency: '',
      }
    },
    btnBack() {
      this.$root.$refs.Confirm.showDialog('',
          '입력한 모든 정보가 취소됩니다.<br/>충전을 취소하시겠습니까?<br/>',
          '확인', '취소', () => {
            this.$router.back()
          }, () => {
          })
    },
    getCardList() {
      this.$get(this.$QUICK, 'charge_getcardlist',(result) => {
        this.cardList = result.data
        if (this.$route.params.amount) {
          this.selectedInstallment = this.$route.params.selectedInstallment
        } else {
          this.selectedInstallment = this.installmentList[0]
        }

      }, (result) => {
        this.httpError(result.data)
      })
    },
    getLogoImg() {
      this.$get(this.$USER_LOGO_BRANCH, 'logo_img',(result) => {
        console.log(result.data)
        this.logoImg = result.data
      }, (result) => {
        console.log(result.data.message)
      })
    },
    openCardList() {
      this.showCardList = true
    },
    setSelectedCard(_card) {
      this.selectedCard = _card
      this.showCardList = false

      this.changeNextBtn()
    },
    getDeliveryAgencyList() {
      this.$get(this.$ACCOUNT_APPROVED, 'charge_getdeliveryagancylist',(result) => {
        this.deliveryAgencyList = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
    openDeliveryAgencyList() {
      this.showDeliveryAgencyList = true
    },
    setSelectedDeliveryAgency(_deliveryAgency) {
      this.selectedDeliveryAgency = _deliveryAgency
      this.showDeliveryAgencyList = false

      this.changeNextBtn()
    },
    charge() {
      this.errorClear()

      if (this.selectedDeliveryAgency === null){
        this.errors.selectedDeliveryAgency = '배대사를 선택해주세요.'
        return
      }

      if (this.amount === ''){
        this.errors.amount = '충전금액을 입력해주세요.'
        return
      }

      // 최소충전금액 check
      if (this.amount * 1 < 10000){
        this.errors.amount = '충전금액을 10,000원이상 입력해주세요.'
        return
      }

      if (!/^[0-9-][0-9,.]*$/.test(this.amount)) {
        this.errors.amount = '충전금액을 정확하게 입력해주세요.'
        return
      }

      if (this.selectedCard === null){
        this.errors.selectedCard = '카드를 선택해주세요.'
        return
      }

      this.$refs.PinCheck.showPin(() => {
        let formData = new FormData()
        formData.append('accountIdx ', this.selectedDeliveryAgency.idx)
        formData.append('cardIdx', this.selectedCard.idx)
        formData.append('amount', this.amount.replaceAll(',', ''))
        console.log(this.selectedInstallment)
        if(this.amount.replaceAll(',', '') > 50000 && this.selectedInstallment.installment > 0) {
          formData.append('installment', this.selectedInstallment.installment)
        }

        this.$post(this.$QUICK, 'charge_quick', formData, false, (result) => {
          this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
          this.$router.replace({
            name: 'ChargeSuccess',
            params: {amount: result.data.amount, cardInfo: result.data.number, regDate: result.data.regDate, deliveryAgency: this.selectedDeliveryAgency, installment: this.selectedInstallment}
          })

        }, (result) => {
          this.httpError(result.data)
          this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)
          this.$router.replace({name: 'ChargeFail', params: {amount: this.amount, selectedCard: this.selectedCard, selectedDeliveryAgency: this.selectedDeliveryAgency, installment: this.selectedInstallment}})
        })
      })
    },
    changeNextBtn() {
      if (this.amount === '' || this.amount <= 0 || !/^[0-9-][0-9,.]*$/.test(this.amount) || this.selectedCard === null || this.selectedDeliveryAgency === null){
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      } else {
        this.errorClear()
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-pink"
      }
    }
  },
  watch: {
    amount(){
      this.changeNextBtn()
    },
  }
}
</script>

<style scoped>

</style>
