<template>
  <div class="all-content">
    <div class="bg-white content-row-new">
      <div class="confirm-list">
        <p>
          <img class="check-pink mb-30 mt-50" src="/image/check_pink.png" alt="캐릭터">
          <em class="pink-title weight-light">비밀번호가<br/>변경되었습니다.</em>
        </p>
        <p>
          <img src="/image/character_cheer.svg" alt="캐릭터">
        </p>
      </div>
    </div>
    <button class="btn-l btn-pink" @click="$router.back()">확인</button>
  </div>
</template>

<script>
export default {
  name: "FindPwChangeFinish"
}
</script>

<style scoped>

</style>