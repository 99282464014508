<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>로그인</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap pb-80">
        <div class="charge-form">
          <div id="inputId" class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'아이디를 입력해주세요'"
                :model="id"
                :disable="disabledIdInput"
                @update="(_model) => {this.id=_model}"
            />
          </div>
          <div class="select mt-10">
            <RPInputBorder
                :type="'password'"
                :placeholder="'비밀번호를 입력해주세요'"
                :model="password"
                :max-length="20"
                @update="(_model) => {this.password=_model}"
            />
          </div>
          <div class="checks-circle-black mt-10">
            <input type="checkbox" id="autoLogin" @click="setAutoLogin">
            <label for="autoLogin">로그인 상태 유지</label>
          </div>

        </div>
        <button class="btn-m btn-round-5 btn-pink mt-70" @click="login()">로그인</button>
        <div class="mt-10 d-flex-row">
          <button v-show="!hiddenRegist" class="mr-auto color-darkgray-4F4F4F fz-13"
                  @click="$router.push({ name: 'TermAgree' })">회원가입
          </button>
          <button v-show="!hiddenFindId" class="ml-0 mr-5 color-darkgray-4F4F4F fz-13"
                  @click="$router.push({ name: 'FindId' })">아이디 찾기
          </button>
          <button v-show="!hiddenFindPw" class="ml-5 color-darkgray-4F4F4F fz-13"
                  @click="$router.push({ name: 'FindPw' })">비밀번호 찾기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";

export default {
  name: "Login",
  components: {
    RPInputBorder,
  },
  data() {
    return {
      autoLogin: false,
      id: '',
      disabledIdInput: false,
      password: '',

      hiddenRegist: false,
      hiddenFindId: false,
      hiddenFindPw: false
    }
  },
  mounted() {
    if (this.$route.params.id) { // id 넘어오면
      this.id = this.$route.params.id
      this.disabledIdInput = true

      window.document.getElementById('inputId').className = 'select-disable'
      this.hiddenFindId = true
      this.hiddenRegist = true
    }
  },
  methods: {
    setAutoLogin() {
      this.autoLogin = !this.autoLogin
      // localStorage.setItem('autoLogin', this.autoLogin)
    },

    login() {
      if (this.id === '') {
        this.notify('error', '아이디를 정확하게 입력해주세요.')
        return
      }
      if (this.password === '') {
        this.notify('error', '비밀번호를 정확하게 입력해주세요.')
        return
      }
      let formData = new FormData()
      formData.append('id', this.id.trim())
      formData.append('password', this.password.trim())

      this.$post(this.$AUTH_LOGIN, 'login_login', formData, true,(result) => {
        this.loginDone(result)
      }, (result) => {
        this.loginFail(result)
      })

    },
    loginDone(result) {
      sessionStorage.setItem('accessToken', result.data.accessToken)
      sessionStorage.setItem('refreshToken', result.data.refreshToken)

      if (this.autoLogin){
        localStorage.setItem('passToken', result.data.passToken)
      }

      setTimeout(() => {
        this.refreshToken()
      }, this.refreshTokenRate)

      this.refreshPush()
      // this.setPushAlarm(() => {
      localStorage.setItem('firstPinChecked', '0')
      localStorage.setItem('checkedCI', '0')
      this.$router.push({name: 'Main'})
      // })
    },
    loginFail(result) {
      sessionStorage.removeItem('accessToken')
      sessionStorage.removeItem('refreshToken')
      localStorage.removeItem('passToken')
      // localStorage.removeItem('useLocalBio')

      if (result.data.state === 400) {
        console.log(result.data.data)
        // state : 0(일반)
        if (result.data.data.state === '1'){  // 심사중
          this.$router.push({name: 'ReviewingUser'})

        } else if (result.data.data.state === '2'){ // 탈퇴
          this.$router.push({name: 'LeaveUser'})

        } else if (result.data.data.state === '3'){ // 정지
          this.$root.$refs.Alert.showAlert('결제나라 정책 위반',
              '결제나라 정책 위반으로 현재 계정은<br/>사용정지 처리되었습니다.<br/><br/>' +
              '사유 : ' + result.data.reason + '<br/>' + result.data.time,
              () => {
              }, false, false)

        } else if (result.data.data.state === '4'){ // 휴먼
          this.$root.$refs.Confirm.showDialog('결제나라 휴먼계정',
              '현재 계정은 1년간 접속하지 않아<br/>휴먼 상태로 전환된 상태입니다.<br/>' +
              '휴먼 계정 해제를 통해<br/>정상적으로 이용하실 수 있습니다.',
              '휴먼계정 해제', '취소', () => {
                this.$router.push({name: 'InactivityRequest'})
              }, () => {
              })

        } else {
          this.httpError(result.data)
        }

      } else {
        this.httpError(result.data)
      }
    },
  }
}
</script>

<style scoped>

</style>
