<template>
  <div class="all-content bg-white bg-gray leave">
    <header class="header-wrap bg-white border-bottom">
      <p>탈퇴</p>
      <button type="button" class="btn-close" @click="$router.back()"><i class="icon-close"></i></button>
    </header>
    <div class="content-wrap">
      <div class="main-wrap leave-form">
        <div class="charge-form">
          <span class="fz-18 fwb">페이허브 관리자 app 탈퇴 시</span>
          <div>
            <div class="d-flex-row">
              <span class="desc">내 프로필, 배달비 결제내역, 결제 정보 등 그 외 사용자가 설정한 모든 정보가 사라지고 복구가 불가능 합니다.</span>
            </div>
            <div class="mt-30 d-flex-row">
              <span class="desc">단, 관련 법령에 따라 페이허브의 정보보호정책에 명시되어 있는 보관 의무를 가지는 정보는 관련 법령이 규정한 기간 동안 보존됩니다.</span>
            </div>
          </div>

        </div>
        <div class="mt-30">
<!--          <p class="t-c color-pink">-->
<!--            <span class="color-pink fz-16">현재 남은 립페이 : {{ payBalance | numberWithCommas }} PAY</span>-->
<!--          </p>-->
          <!--          <p class="t-c mt-5">-->
          <!--            <span class="color-pink fz-16">현재 남은 립체인 : {{ chainBalance | numberWithCommas }} REAP</span>-->
          <!--          </p>-->
        </div>
      </div>
      <footer class="btn-wrap h-100px">
        <div class="row">
          <div class="checks-circle mb-20">
            <input type="checkbox" id="leaveCheck" v-model="isChecked">
            <label for="leaveCheck">모든 정보를 삭제하는 것에 동의합니다.</label>
          </div>
        </div>
        <button class="align-bottom btn btn-m btn-pink" :class="{'btn-gray':!isChecked,'btn-pink':isChecked}" @click="goLeave">탈퇴</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Leave",

  data() {
    return {
      isChecked: false,
      payBalance: 0,
      chainBalance: 0,
    }
  },
  mounted() {
    // this.$get(this.$WALLET_REAPPAY_BALANCE, 'leave_mounted', (result) => {
    //   this.payBalance = result.data * 1
    // }, (result) => {
    //   this.httpError(result.data)
    // })
    // this.$get(this.$WALLET_REAPCHAIN_BALANCE, 'leave_mounted', (result) => {
    //   this.chainBalance = result.data * 1
    // }, (result) => {
    //   this.httpError(result.data)
    // })
  },
  methods: {
    goLeave() {
      if (!this.isChecked) {
        return
      }

      this.$root.$refs.Confirm.showDialog('탈퇴를 하시겠습니까?', '', '확인', '취소', () => {
        //간편결제 회원 해지
        // this.$delete(this.$ACCOUNT_MEMBER, 'leave_goleave', () => {
        // }, () => {
        // })

        //카카오 연결 해제
        // window.Kakao.API.request({
        //   url: '/v1/user/unlink',
        //   success: (res) => {
        //     console.log(res)
        //   },
        //   fail: (err) => {
        //     console.log(err)
        //   },
        // })

        //탈퇴
        this.$put(this.$AUTH_LEAVE, 'leave_goleave', null, true,() => {
          sessionStorage.removeItem('accessToken')
          sessionStorage.removeItem('refreshToken')
          localStorage.removeItem('passToken')
          localStorage.removeItem('id')
          // localStorage.removeItem('useLocalBio')
          // localStorage.removeItem('autoLogin')
          localStorage.removeItem('firstPinChecked')
          localStorage.removeItem('checkedCI')
          this.$router.replace({ name: 'LeaveSuccess'})
        }, (result) => {
          this.httpError(result.data)
        })
      }, () => {
      }, true)
    }
  }
}
</script>

<style scoped>

</style>
