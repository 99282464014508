<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list acc-select h-auto">
      <div class="accounts">
        <h6 class="fwb t-c mb-30 fz-16">다른 계정으로 간편 로그인</h6>
        <ul>
          <li class="account mt-10 bg-gray" @click="check($event)">
            <span class="fz-15 lh-30">{{ currentAccount }}</span>
            <span class="check"><i class="icon-check color-white"></i></span>
          </li>
          <li class="account mt-10 bg-gray" @click="check($event, account)" v-for="account in accountList" :key="account.idx">
            <span class="fz-15 lh-30">{{ account.id }}</span>
            <span class="check"><i class="icon-check color-white"></i></span>
          </li>
        </ul>
        <p class="mt-30 mb-10 fz-15 t-c" v-show="accountList.length === 0">다른 계정이 없습니다.</p>
      </div>
      <div class="d-flex-row">
        <button class="btn-gray mr-auto btn-half h-auto" @click="$emit('close')">취소</button>
        <button class="btn-pink ml-auto btn-half h-auto" @click="selectAccount">완료</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountSelect",
  props: ['accountList', 'currentAccount'],

  data() {
    return {
      selectedAccount: {}
    }
  },

  methods: {
    reset() {
      let elementList = document.getElementsByClassName('account')
      for (let i = 0; i < elementList.length; i++) {
        if (elementList[i].classList.contains('active')) {
          elementList[i].classList.remove('active')
        }
      }
      elementList[0].classList.add('active')

      this.selectedAccount = {}
    },
    check(e, account = {}) {
      let elementList = e.currentTarget.parentNode.getElementsByClassName('account')
      for (let i = 0; i < elementList.length; i++) {
        if (elementList[i].classList.contains('active')) {
          elementList[i].classList.remove('active')
        }
      }
      e.currentTarget.classList.add('active')

      this.selectedAccount = account
    },

    selectAccount() {
      if(!this.selectedAccount.idx) {
        this.$emit('close')
        return
      }

      let formData = new FormData()
      formData.append('idx', this.selectedAccount.idx)
      formData.append('id', this.selectedAccount.id)

      this.$post(this.$AUTH_SIMPLELOGIN, 'auth_simple_login', formData, true,(result) => {
        sessionStorage.setItem('accessToken', result.data.accessToken)
        sessionStorage.setItem('refreshToken', result.data.refreshToken)

        // if (localStorage.getItem('autoLogin') === 'true' ){
          if (localStorage.getItem('passToken')){
            localStorage.setItem('passToken', result.data.passToken)
          }
        // }

        setTimeout(() => {
          this.refreshToken()
        }, this.refreshTokenRate)

        localStorage.setItem('firstPinChecked', '0')
        localStorage.setItem('checkedCI', '0')
        this.$emit('getUserInfo')
        this.$emit('close')
      }, (result) => {
        this.httpError(result.data)
      })
    },
  }
}
</script>

<style scoped>

</style>
