<template>
  <div class="all-content bg-white sms-pay">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>단말기 결제</p>
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pb-80">
        <div class="charge-form pt-0">
          <p class="mb-10 fz-16 mt-30">상품명<span class="color-red">*</span></p>
          <div class="d-flex-row">
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'상품명을 입력해주세요'"
                  :model="itemName"
                  @update="(_model) => {this.itemName = _model}"
                  :input-mode="'text'"
              />
            </div>
          </div>
          <p class="mb-10 fz-16 mt-30">결제금액<span class="color-red">*</span></p>
          <div class="d-flex-row">
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'결제금액을 입력해주세요.(100원 이상)'"
                  :model="payAmt"
                  @update="updatePayAmt"
                  :input-mode="'decimal'"
              />
            </div>
          </div>
          <div v-show="showPartPay">
            <p class="mb-10 mt-30 fz-16">할부<span class="color-red">*</span></p>

            <div class="select-wrap">
              <button class="select" @click="openSelect()">{{ selectedPay.name }}</button>
            </div>
          </div>
          <p class="mb-10 mt-30 fz-16">부과세</p>
          <div class="tab-tax">
            <div :class="{ activeTax: isTax }" @click="isTax = !isTax">과세</div>
            <div :class="{ activeNoTax: !isTax }" @click="isTax = !isTax">면세</div>
          </div>
        </div>
      </div>
      <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="goNext">결제하기</button>
    </div>
    <installment-period-select
        v-show="showSelect"
        :periods="payList"
        @selected="setSelectedPay"
        @close="showSelect=false"/>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import InstallmentPeriodSelect from "@/components/payment/rentPayment/InstallmentPeriodSelect";

export default {
  name: "TerminalPayment",
  components: {
    RPInputBorder,
    InstallmentPeriodSelect
  },
  data() {
    return {
      itemName: '',
      payAmt: '',
      isTax: true,

      //할부관련:
      showPartPay: false,
      showSelect: false,
      payList: [
        {
          'installment': '00',
          'name': '일시불'
        },
        {
          'installment': '02',
          'name': '2개월'
        },
        {
          'installment': '03',
          'name': '3개월'
        },
        {
          'installment': '04',
          'name': '4개월'
        },
        {
          'installment': '05',
          'name': '5개월'
        },
        {
          'installment': '06',
          'name': '6개월'
        },
        {
          'installment': '07',
          'name': '7개월'
        },
        {
          'installment': '08',
          'name': '8개월'
        },
        {
          'installment': '09',
          'name': '9개월'
        },
        {
          'installment': '10',
          'name': '10개월'
        },
        {
          'installment': '11',
          'name': '11개월'
        },
        {
          'installment': '12',
          'name': '12개월'
        },
      ],
      selectedPay: {
        'installment': '00',
        'name': '일시불'
      },
      //:할부관련
    }
  },
  mounted() {
    window.addEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
  },
  methods: {
    requestConnectDevice() {
      window.requestConnectDevice()
    },
    connectResult(e) {
      let result = e.detail
      if (result === '1') {
        //연결이 성공적으로 됬을때 카드 인식 요청
        this.$router.push({
          name: 'TerminalPaymentCardRead',
          params: {
            itemName: this.itemName,
            payAmt: this.payAmt,
            installment: this.selectedPay.installment,
            isTax: (this.isTax ? '0' : '1')
          }
        })

      } else {
        this.notify('error', '기기 연결에 실패 했습니다.')
      }
    },
    goNext() {
      if (this.itemName === '') {
        this.notify('error', '상품명을 입력해주세요.')
        return
      }

      if (this.payAmt === '' ) {
        this.notify('error', '결제금액을 입력해주세요.')
        return
      }

      if (this.payAmt * 1 < 100 ) {
        this.notify('error', '결제금액을 100원이상 입력해주세요.')
        return
      }

      this.requestConnectDevice() //기기 연결 요청
    },
    updatePayAmt(_model) {
      this.payAmt = _model
      this.showPartPay = Number(this.payAmt) >= 50000
    },

    //할부관련:
    openSelect() {
      this.showSelect = true
    },
    setSelectedPay(pay) {
      this.selectedPay = pay
      this.showSelect = false
    },
    //:할부관련

    changeNextBtn() {
      if (this.itemName === '' || (this.payAmt * 1 <= 0)){
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      } else {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-pink"
      }
    },
  },
  watch: {
    itemName(){
      this.changeNextBtn()
    },
    payAmt(){
      this.changeNextBtn()
    }
  }
}
</script>

<style scoped>

</style>
