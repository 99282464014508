<template>
  <div class="overlay cardSetting" @click.self="$emit('close')">
    <div class="select-list">
      <div class="d-flex-row jc-between">
        <p class="fwb fz-20 lh-30 mb-30">{{ selectedDeliveryAgency.nickname ? selectedDeliveryAgency.nickname : '이름없음('+selectedDeliveryAgency.idx+')' }} {{ selectedDeliveryAgency.number }}</p>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul>
        <li v-for="(_condition, index) in conditions" :key="index">
          <a @click="selected(_condition)">
            <span class="fz-16 color-darkgray-4F4F4F lh-24">{{ _condition.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeliveryAgencySetting",
  props: ['conditions', 'selectedDeliveryAgency'],
  methods: {
    selected(_condition) {
      this.$emit('selected', _condition)
    }
  }
}
</script>

<style scoped>

</style>
