<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>단말기 결제</p>
    </header>
    <div class="content-wrap bg-white content-row pb-80">
      <div class="confirm-list">
        <p>
          <img src="/image/character_fast_one.svg" alt="캐릭터">
          <em class="color-black fz-18">리더기에 카드를 읽혀주세요.</em>
        </p>
      </div>
    </div>
    <div class="align-bottom d-flex-row w-100 bg-gray">
      <div class="w-100 bg-white mt-10 p-15">
        <p class="d-flex-row fz-18 p-10">
          <span class="fz-18">상품명</span>
          <span class="ml-auto fz-18">{{ itemName }}</span>
        </p>
        <p class="d-flex-row mt-5 fz-18 p-10">
          <span class="fz-18">단말기 결제</span>
          <span class="ml-auto fz-18">{{ payAmt | numberWithCommas }} 원</span>
        </p>
        <p class="pl-10">
          <span class="color-gray fz-18">{{ isTax === '0' ? '과세' : '면세' }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TerminalPaymentCardRead",
  data() {
    return {
      payAmt: '0',
      isTax: '0',
      itemName: '',
      installment: '0',
      cardNo: '',
      limitMM: '',
      limitYY: '',

    }
  },
  mounted() {
    this.itemName = this.$route.params.itemName
    this.payAmt = this.$route.params.payAmt
    this.installment = this.$route.params.installment
    this.isTax = this.$route.params.isTax

    window.addEventListener(this.$EVENT_READ_CARD, this.setCardInfo)

    this.requestReadCard()  // 카드정보 요청
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_READ_CARD, this.setCardInfo)
  },
  methods: {
    requestReadCard() {
      window.requestReadCard()
    },
    setCardInfo(e) {
      let cardInfo = e.detail
      if (cardInfo === '//') {
        this.notify('error', '카드인식에 실패 했습니다.')
        return
      }
      this.cardNo = cardInfo.split('/')[0]
      this.limitMM = cardInfo.split('/')[1]
      this.limitYY = cardInfo.split('/')[2]
      this.goNext()
    },
    goNext() {
      if (this.cardNo === '' || this.cardNo.length < 15 ||
          this.limitMM === '' || this.limitMM.length !== 2 ||
          this.limitYY === '' || this.limitYY.length !== 2) {
        this.notify('error', '카드인식에 실패 했습니다.')
        return
      }

      let formData = new FormData()

      formData.append('goodsName', this.itemName)
      formData.append('amount', this.payAmt)
      formData.append('taxType', this.isTax)
      formData.append('cardNumber', this.cardNo)
      formData.append('expireMm', this.limitMM)
      formData.append('expireYy', this.limitYY)

      formData.append('installment', this.installment)

      this.$post(this.$PAYMENT_TERMINAL, 'TerminalPaymentCardRead_gonext', formData, true, (result) => {
        this.$router.replace({
          name: 'TerminalPaymentSuccess',
          params: {payAmount: this.payAmt, cardNo: this.cardNo, paymentInfo: JSON.stringify(result.data)}
        })
      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({
          name: 'TerminalPaymentFail',
          params: {payAmount: this.payAmt, cardNo: this.cardNo, paymentInfo: JSON.stringify(result.data)}
        })
      })
    },
  }
}
</script>

<style scoped>

</style>
