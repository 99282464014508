<template>
  <div class="all-content sms-pay-success">
    <div class="content-wrap bg-white content-row t-0" style="height: calc(100% - 52px);">
      <div class="confirm-list regist-card-success">
        <p style="justify-content: flex-end">
          <img src="/image/character_hat_sad.svg" alt="캐릭터">
        </p>
        <p class="comment">
          <em class="t-c black-title">카톡 발송 실패</em>
          <em class="t-c mt-10">발송이 실패되었습니다.<br/>다시 한번 시도하세요.</em>
        </p>
        <div class="w-90">
          <p class="info mt-10">
            <span class="title">발송일시</span>
            <span class="cardInfo">{{ new Date() | moment('YYYY-MM-DD HH:mm:ss') }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="d-flex-row">
      <button class="btn-gray mr-auto btn-half" @click="$router.push({ name: 'Main' })">취소</button>
      <button class="btn-pink mr-auto btn-half" @click="goBack">다시결제</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SMSPaymentFail",
  data(){
    return {
      goodsName: '',  // 상품명
      amount: '', // 결제금액
      taxType: '',  // 과세여부(0: 과세, 1: 비과세)
      sendType: '', // 발송타입(0: 즉시발송, 1: 예약발송)
      sendTime: '', // 예약발송 시간
      memo: '', // 메모
      additionType: '', // 주소요청여부(0: 요청안함 1:주소요청)

      clientContactList : [],
    }
  },
  mounted() {
    this.goodsName = this.$route.params.goodsName
    this.amount = this.$route.params.amount
    this.taxType = this.$route.params.taxType
    this.sendType = this.$route.params.sendType
    this.sendTime = this.$route.params.sendTime
    this.memo = this.$route.params.memo
    this.additionType = this.$route.params.additionType
    this.clientContactList = this.$route.params.clientContactList
  },
  methods: {
    goBack() {  // 다시 결제
      this.$router.push({name: 'SMSContactAdd', params: { goodsName: this.goodsName, amount: this.amount, taxType: this.taxType, sendType: this.sendType, sendTime: this.sendTime
          ,memo: this.memo, additionType: this.additionType, clientContactList: this.clientContactList }})
    },
  }
}
</script>

<style scoped>

</style>
