<template>
  <div class="all-content">
    <header class="header-wrap">
      <p>알림설정</p>
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
    </header>
    <div class="content-wrap">
      <section class="section-notification-setting pt-30 pb-5">
        <div class="notification-list-title">
          <p>전체</p>
        </div>
        <div class="notification-list-box fwb">
          <p style="font-weight: normal !important;">활동에 필요한<br>모든 알람을 받을 수 있습니다.</p>
          <p class="toggle-group">
            <span class="btn-toggle" :class="{ 'on' : selectedAll }" @click="setNotiSettingInfo(0)"></span>
          </p>
        </div>
      </section>

      <section class="section-notification-setting mt-10">
        <div class="notification-list-title">
          <p>배달비</p>
        </div>
        <div class="notification-list-box border-bottom">
          <span>충전결제알림</span>
          <p class="toggle-group">
            <span class="btn-toggle" :class="{ 'on' : settingData.quickChargePayment }" @click="setNotiSettingInfo(1)"></span>
          </p>
        </div>

        <div class="notification-list-title">
          <p>월세</p>
        </div>
        <div class="notification-list-box">
          <span>결제알림</span>
          <p class="toggle-group">
            <span class="btn-toggle" :class="{ 'on' : settingData.monthlyPayment }" @click="setNotiSettingInfo(2)"></span>
          </p>
        </div>
        <div class="notification-list-box">
          <span>납입알림</span>
          <p class="toggle-group">
            <span class="btn-toggle" :class="{ 'on' : settingData.monthlySettlement }" @click="setNotiSettingInfo(3)"></span>
          </p>
        </div>
        <div class="notification-list-box border-bottom">
          <span>기타(반려/승인)알림</span>
          <p class="toggle-group">
            <span class="btn-toggle" :class="{ 'on' : settingData.monthlyState }" @click="setNotiSettingInfo(4)"></span>
          </p>
        </div>

        <div class="notification-list-title">
          <p>결제</p>
        </div>
        <div class="notification-list-box border-bottom">
          <span>결제알림</span>
          <p class="toggle-group">
            <span class="btn-toggle" :class="{ 'on' : settingData.payPayment }" @click="setNotiSettingInfo(5)"></span>
          </p>
        </div>

        <div class="notification-list-title">
          <p>기타</p>
        </div>
        <div class="notification-list-box">
          <span>공지알림</span>
          <p class="toggle-group">
            <span class="btn-toggle" :class="{ 'on' : settingData.etcNotice }" @click="setNotiSettingInfo(6)"></span>
          </p>
        </div>
        <div class="notification-list-box">
          <span >1:1문의알림</span>
          <p class="toggle-group">
            <span class="btn-toggle" :class="{ 'on' : settingData.etcQna }"  @click="setNotiSettingInfo(7)"></span>
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationSetting",
  data() {
    return {
      // 전체
      selectedAll: false,

      settingData: {
        idx: 0,
        userIdx: 0,
        quickChargePayment: false,  // 배달비충전
        monthlyPayment: false,  // 월세결제
        monthlySettlement: false, // 월세납입
        monthlyState: false,  // 상태(반려/승인) 알림
        payPayment: false,  // 결제
        etcNotice: false, // 공지
        etcQna: false,  // 1:1 문의
      }
    }
  },
  mounted() {
    this.getNotiSettingInfo()
  },
  methods: {
    getNotiSettingInfo() {
      this.$get(this.$USER_NOTIFICATION, 'notificationsetting_getnotisetting', (result) => {
        this.settingData = result.data

        this.checkAll()

      }, (result) => {
        this.httpError(result.data)
      })
    },
    setNotiSettingInfo(_type){
      let formData = new FormData()
      formData.append('target', _type)  // 0: 전체, 1: 배달비 충전, 2: 월세 결제, 3: 월세 납입, 4: 월세 상테, 5: PG결제 알림, 6: 기타 공지 알림, 7: 1:1문의 알림

      let state = false
      if (_type === 0) {
        state = !this.selectedAll
      } else if (_type === 1){
        state = !this.settingData.quickChargePayment
      } else if (_type === 2){
        state = !this.settingData.monthlyPayment
      } else if (_type === 3){
        state = !this.settingData.monthlySettlement
      } else if (_type === 4){
        state = !this.settingData.monthlyState
      } else if (_type === 5){
        state = !this.settingData.payPayment
      } else if (_type === 6){
        state = !this.settingData.etcNotice
      } else if (_type === 7){
        state = !this.settingData.etcQna
      }
      formData.append('state', state)

      this.$put(this.$USER_NOTIFICATION, 'notificationsetting_getnotisetting', formData,true,() => {
        this.setOnOff(_type)

        if (_type === 6){ // 공지 알림
          if (state){ // on
            window.subscribedTopic(process.env.VUE_APP_TOPIC_NOTICE)
          } else {  // off
            window.unsubscribedTopic(process.env.VUE_APP_TOPIC_NOTICE)
          }
        }

      }, (result) => {
        this.httpError(result.data)
      })
    },
    setOnOff(_type) {
      if (_type === 0){ // 전체
        this.changeAll()
        return

      } else if (_type === 1){  // 배달비충전
        this.settingData.quickChargePayment = !this.settingData.quickChargePayment
      } else if (_type === 2){  // 월세결제
        this.settingData.monthlyPayment = !this.settingData.monthlyPayment
      } else if (_type === 3){  // 월세납입
        this.settingData.monthlySettlement = !this.settingData.monthlySettlement
      } else if (_type === 4){  // 월세상태
        this.settingData.monthlyState = !this.settingData.monthlyState
      } else if (_type === 5){  // PG결제 알림
        this.settingData.payPayment = !this.settingData.payPayment
      } else if (_type === 6){  // 기타공지 알림
        this.settingData.etcNotice = !this.settingData.etcNotice
      } else if (_type === 7){
        this.settingData.etcQna = !this.settingData.etcQna
      }

      this.checkAll()
    },
    changeAll(){
      this.selectedAll = !this.selectedAll

      if (this.selectedAll){
        this.settingData.quickChargePayment = true
        this.settingData.monthlyPayment = true
        this.settingData.monthlySettlement = true
        this.settingData.monthlyState = true
        this.settingData.payPayment = true
        this.settingData.etcNotice = true
        this.settingData.etcQna = true

      } else {
        this.settingData.quickChargePayment = false
        this.settingData.monthlyPayment = false
        this.settingData.monthlySettlement = false
        this.settingData.monthlyState = false
        this.settingData.payPayment = false
        this.settingData.etcNotice = false
        this.settingData.etcQna = false
      }
    },
    checkAll(){
      if (this.settingData.quickChargePayment && this.settingData.monthlyPayment && this.settingData.monthlySettlement && this.settingData.monthlyState && this.settingData.payPayment && this.settingData.etcNotice && this.settingData.etcQna) {
        this.selectedAll = true
      } else {
        this.selectedAll = false
      }
    },
  }
}
</script>

<style scoped>

</style>