<template>
  <div class="all-content">
    <div class="bg-white content-row-new">
      <div class="confirm-list">
        <p>
          <em class="pink-title mb-10">심사중</em>
          <small class="t-c mt-10">
            현재 심사중입니다.<br>
            심사가 완료되면 서비스 이용이 가능합니다.<br><br>

            서류심사는 영업일 기준 3~4일 소요되며,<br>
            서류 재요청이 필요한 경우 유선, 문자, 메일로<br>
            연락을 드릴 수 있습니다 :)
          </small>
        </p>
      </div>
    </div>
    <button class="btn-l btn-pink" @click="$router.back()">확 인</button>
  </div>
</template>

<script>
export default {
  name: "ReviewingUser"
}
</script>

<style scoped>

</style>