<template>
  <div class="all-content">
    <div class="intro-wrap bg-white content-row">
      <div class="confirm-list pb-20" style="padding-top: 60%;">
        <!--        <p>-->
        <em class="pink-title mb-50">
          고객님의 아이디는<br>
          아래와 같습니다.
        </em>
        <!--        </p>-->
        <div class="list-wrap">
          <div class="list" v-for="(data, index) in idList" :key="index" @click="goLogin(index)">
            <!--            <span style="color: #777777">{{ data.businessName }}</span><br>-->
            <span class="color-darkgray-4F4F4F fz-18">{{ data.id }}</span>
            <i class="icon-arrow-right icon"></i>
          </div>
        </div>
        <!--        <button class="btn-l btn-pink btn-round mb-50" @click="$router.back()">확 인</button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SsoFindIdSuccess",
  data() {
    return {
      idList: []
    }
  },
  mounted() {
    this.idList = this.$route.params.idList
  },
  methods: {
    goLogin(_index){
      this.$router.replace({ name : 'SsoLogin', params: { id : this.idList[_index].id }})
    }
  }
}
</script>

<style scoped>
/* id List box */
.list-wrap {
  /*margin-top: 20px;*/
  height: 100vh;
  overflow: auto;
  position: relative;
}

.list-wrap .list {
  width: 100%;
  height: 55px;
  padding: 17px 50px 14px 14px;
  border: 1px solid #DDDDDD;
  margin-top: 10px;
  border-radius: 5px;
  background: #FAFAFA;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
}

.list-wrap .list .icon {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  padding-top: 4px;
}
</style>
