<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list h-60">
      <div class="d-flex-row jc-between">
        <h5 class="pt-0">배대사 선택</h5>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul class="content-list">
        <li v-for="(deliveryAgency, index) in deliveryAgencies" :key="index" >
          <a @click="selectDeliveryAgency(deliveryAgency)">
            <span>{{ deliveryAgency.nickname ? deliveryAgency.nickname : '이름없음(' + deliveryAgency.idx + ')' }} <small>({{ deliveryAgency.bankName }} {{ deliveryAgency.accountNumber }} {{ deliveryAgency.depositor }})</small></span>
          </a>
        </li>
        <li>
          <a @click="$router.push({name:'RegistDeliveryAgency'})">
            <span class="color-pink">+ 배대사 등록</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChargeDeliveryAgencySelect",
  props: ['deliveryAgencies'],
  methods: {
    selectDeliveryAgency(_deliveryAgency) {
      this.$emit('selected', _deliveryAgency)
    }
  },
  filters: {
    bankImage: (value) => {
      if (value === '') {
        return ''
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>