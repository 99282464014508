<template>
  <div class="all-content overflow-x-hidden">
    <header class="payment-history-detail-header-wrap">
      <button type="button" class="btn-back" @click="back()"><i
          class="icon-back"></i></button>
    </header>

    <div v-if="paymentInfo" class="content-wrap bg-gray-F4F4F7">
      <section class="pg-detail-top-box">
        <div class="d-flex t-e">
          <span class="color-gray-4F4F4F mb-5 fz-13">결제금액</span>
        </div>

        <div class="d-flex-row jc-between">
          <span class="color-gray-4F4F4F fz-18">{{ paymentInfo.goodsName }}</span>
          <span class="color-black fz-28 fwb" v-if="paymentInfo.paymentType === '3'">{{ paymentInfo.amount }} <em class="fz-24 weight-500">PAY</em></span>
          <span class="color-black fz-28 fwb" v-else>{{ paymentInfo.amount | numberWithCommas }} <em class="fz-24 weight-500">원</em></span>
        </div>
      </section>

      <section class="histroy-box">
        <div class="pg-detail">
          <!-- 결제정보 -->
          <div>
            <h5 class="mt-0">결제정보</h5>
            <div class="d-flex-row jc-between">
              <span class="pg-detail-title" style="">결제상태</span>
              <span class="pg-detail-contents"
                    :class="[ paymentInfo.state === '1' ? 'payment-done-tit' : 'payment-cancel-tit' ]">{{
                  paymentInfo.state | paymentStateFilter
                }}</span>
            </div>
            <div v-if="paymentInfo.state === '4' && paymentInfo.cancelDate != null" class="d-flex-row jc-between">
              <span class="pg-detail-title">취소일자</span>
              <span class="pg-detail-contents">
                {{ paymentInfo.cancelDate.replace('T', ' ') }}
              </span>
            </div>
            <div class="d-flex-row jc-between" :class="[{'border-bottom' :paymentInfo.transactionSeq == null && paymentInfo.paymentType !== '5' }, {'pb-10' : paymentInfo.transactionSeq == null && paymentInfo.paymentType !== '5' }]">
              <span class="pg-detail-title">결제수단</span>
              <span class="pg-detail-contents">{{ paymentInfo.paymentType | paymentTypeFilter }}</span>
            </div>
            <div class="d-flex-row jc-between border-bottom pb-10" v-if="paymentInfo.transactionSeq != null">
              <span class="pg-detail-title">주문번호</span>
              <span class="pg-detail-contents-content">{{ paymentInfo.transactionSeq }}</span>
            </div>
            <div class="d-flex-row jc-between border-bottom pb-10" v-if="paymentInfo.paymentType === '5'">
              <span class="pg-detail-title">예약발송일</span>
              <span class="pg-detail-contents">{{ paymentInfo.sendTime }}</span>
            </div>
          </div>

          <!-- 결제내역 -->
          <div>
            <h5 class="mt-20 mb-15">결제내역</h5>
            <div class="d-flex-row jc-between">
              <span class="pg-detail-title">결제ID</span>
              <span class="pg-detail-contents">{{ paymentInfo.clientId }}</span>
            </div>
            <div class="d-flex-row jc-between" v-if="paymentInfo.paymentType !== '3' && paymentInfo.cardNo != null">
              <span class="pg-detail-title">카드번호</span>
              <span class="pg-detail-contents">{{ paymentInfo.cardNo }}</span>
            </div>
            <div class="d-flex-row jc-between" v-if="paymentInfo.approvalNo !== null">
              <span class="pg-detail-title">승인번호</span>
              <span class="pg-detail-contents">{{ paymentInfo.approvalNo }}</span>
            </div>
            <div class="d-flex-row jc-between" v-if="cardName !== null && cardName !== ''">
              <span class="pg-detail-title">카드종류</span>
              <span class="pg-detail-contents">{{ cardName }}</span>
            </div>
            <div class="d-flex-row jc-between" v-if="paymentInfo.installment !== null">
              <span class="pg-detail-title">할부개월</span>
              <span class="pg-detail-contents">{{
                  paymentInfo.installment * 1 === 0 ? '일시불' : `${paymentInfo.installment * 1}개월`
                }}</span>
            </div>
            <div v-if="paymentInfo.approvalYMDHMS" class="d-flex-row border-bottom jc-between pb-10">
              <span class="pg-detail-title">거래일시</span>
              <span class="pg-detail-contents">
                {{ paymentInfo.approvalYMDHMS }}
              </span>
            </div>
          </div>

          <!-- 이용상세정보 -->
          <div>
            <h5>이용상세정보</h5>
            <div class="d-flex-row jc-between">
              <span class="pg-detail-title">상호명</span>
              <span class="pg-detail-contents-content">{{ paymentInfo.shopName }}</span>
            </div>
            <div class="d-flex-row jc-between">
              <span class="pg-detail-title">대표자명</span>
              <span class="pg-detail-contents">{{ paymentInfo.name }}</span>
            </div>
            <div class="d-flex-row jc-between">
              <span class="pg-detail-title">사업자번호</span>
              <span class="pg-detail-contents">{{ paymentInfo.business | businessNumberMasking }}</span>
            </div>
            <div :class="[{'border-bottom' : paymentInfo.clientName !== null}, {'pb-10' : paymentInfo.clientName !== null}]" class="d-flex-row jc-between">
              <span class="pg-detail-title">주소</span>
              <span class="pg-detail-contents">{{
                  `${paymentInfo.address} ${paymentInfo.addressDetail}`
                }}</span>
            </div>
          </div>

          <!-- 고객 정보 -->
          <div v-if="paymentInfo.clientName !== null">
            <h5>고객 정보</h5>
            <div class="d-flex-row jc-between">
              <span class="pg-detail-title">고객명</span>
              <span class="pg-detail-contents">{{ paymentInfo.clientName }}</span>
            </div>
            <div class="d-flex-row jc-between">
              <span class="pg-detail-title">연락처</span>
              <span class="pg-detail-contents">{{ paymentInfo.clientContact }}</span>
            </div>
            <div class="d-flex-row jc-between">
              <span class="pg-detail-title">고객주소</span>
              <span class="pg-detail-contents">{{
                  `${paymentInfo.clientAddress} ${paymentInfo.clientAddressDetail}`
                }}</span>
            </div>
          </div>
        </div>
      </section> <!-- /histroy-box -->

      <!-- 결제완료일 때 -->
      <div v-if="paymentInfo.state === '1' && enableCancelRequest" class="d-flex-row">
        <button class="btn-gray-4F4F4F mr-auto btn-half fz-18" @click="requestCardCancel">결제취소요청</button>
        <button class="btn-pink ml-auto btn-half fz-18"
                @click="$router.replace({ name : 'PGPaymentHistory' })">확인
        </button>
      </div>

      <!-- 결제취소일 때 -->
      <button v-else-if="paymentInfo.state === '4'" class="btn btn-m btn-pink fz-18"
              @click="$router.replace({ name : 'PGPaymentHistory' })">
        확인
      </button>

    </div> <!-- /content-wrap -->
  </div> <!-- /all-content -->
</template>

<script>
import moment from "moment";

export default {
  name: "PaymentHistoryDetail",
  data() {
    return {
      isPaymentDone: false,
      paymentInfo: '',
      idx: '',
      cancelInfo: '',
      // itemName: '',
      cardName: '',

      enableCancelRequest: true,
    }
  },
  mounted() {
    this.idx = this.$route.params.idx
    // this.itemName = this.$route.params.itemName

    this.getPaymentListDetail()
  },
  methods: {
    back(){
      if (this.$route.params.confirmReceipt){
        this.$router.replace({ name : 'PGPaymentHistory' })
      } else {
        this.$router.back()
      }
    },
    getPaymentListDetail() {
      this.$get(this.$TRANSACTION_DETAIL + this.idx, this.$options.name + '_getPaymentListDetail', (result) => {
        this.paymentInfo = result.data
        if (this.paymentInfo.cardName) {
          this.cardName = this.paymentInfo.cardName.split('/')[0].trim()
        }

        // 결제일자랑 현재날짜 비교 (24시간 이내)
        let approvalDate = new Date(moment(this.paymentInfo.approvalYMDHMS, 'YYYY.MM.DD HH:mm:ss'))
        let current = new Date()
        let minus = current.getTime() - approvalDate.getTime()

        if (Math.floor(minus / (1000 * 60 * 60)) < 24){ // 24시간 이내
          this.enableCancelRequest = true
        } else {
          this.enableCancelRequest = false
        }

      }, (result) => {
        this.httpError(result.data)
      })
    },

    requestCardCancel() {
      if (this.paymentInfo.paymentType === '3') { // qr 취소
        this.$put(this.$PAYMENT_CANCELQR + '/' + this.idx, this.$options.name + '_requestQRCancel', null, true, (result) => {
          this.cancelInfo = result.data
          this.$router.replace({name: 'RequestCancelSuccess', params: { amount: this.paymentInfo.amount, cancelInfo: this.cancelInfo, paymentType: this.paymentInfo.paymentType
          }})

        }, (result) => {
          this.httpError(result.data)
        })

      } else {
        let formData = new FormData()
        formData.append('tranSeq', this.paymentInfo.transactionSeq)

        this.$put(this.$PAYMENT_CANCELCARD, this.$options.name + '_requestCardCancel', formData, true, (result) => {
          this.cancelInfo = result.data
          this.$router.replace({name: 'RequestCancelSuccess', params: { cancelInfo: this.cancelInfo, paymentType: this.paymentInfo.paymentType }})

        }, (result) => {
          this.httpError(result.data)
        })
      }
    },

  },
  filters: {
    paymentStateFilter: (value) => {
      if (value === '1') {
        return '결제완료'
      } else if (value === '4') {
        return '결제취소'
      }
    },
    paymentTypeFilter: (value) => {
      if (value === '0') {
        return '수기결제'
      } else if (value === '1') {
        return '카톡결제'
      } else if (value === '2') {
        return '단말기결제'
      } else if (value === '3') {
        return 'QR결제'
      } else if (value === '4'){
        return '카드결제'  /*바코드결제*/
      } else if (value === '5'){
        return '카톡예약결제'
      }
    },
  },
}
</script>

<style scoped>

</style>
