<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>공지사항</p>
    </header>
    <div class="notice-tab">
      <div class="pt-20">
        <button :class="[type === 0 ? 'tab-notice-active' : 'tab-notice']" @click="getNotice(0)">전체
        </button>
        <span class="ml-5 mr-5" style="color: #c4c4c4"> | </span>
        <button :class="[type === 1 ? 'tab-notice-active' : 'tab-notice']" @click="getNotice(1)">공통</button>
        <span class="ml-5 mr-5" style="color: #c4c4c4"> | </span>
        <button :class="[type === 2 ? 'tab-notice-active' : 'tab-notice']" @click="getNotice(2)">지사
        </button>
      </div>
    </div>
    <div class="content-wrap-notice bg-white">
      <div class="list-none-wrap" v-if="loaded && items.length === 0">
        <div class="list-none">
          <p><img src="/image/character_hat_worry_opacity.svg" alt="캐릭터"></p>
          <p class="list-none-title">등록된 공지가 없습니다.</p>
        </div>
      </div>

      <div class="alram-list p-0" v-if="items.length > 0">
        <ul>
          <li v-for="(item, index) in items" :key="index" class="mb-0">
            <a @click="goDetail(item.idx)" class="display-block position-relative p-20">
              <em class="p-0">{{ item.regDate | moment('YY.MM.DD') }}</em>
              <p class="p-0 faq-content mr-20">
                <!--                {{ item.title }}<small class="ml-10 color-red fz-14" v-show="item.newNotice">NEW</small>-->
                {{ item.title }}
              </p>
              <i class="w-auto h-auto r-20"
                 :class="{'icon-arrow-down':item.idx !== selectedIdx, 'icon-arrow-up':item.idx === selectedIdx}"></i>
            </a>
            <div class="bg-gray p-1">
              <div v-if="item.idx === selectedIdx" class="p-20">
                <div class="p-0">
                  <div class="faq-content" v-html="detailContent"></div>
                </div>
                <!--                <div v-for="(img, imgIndex) in imageList" :key="imgIndex">-->
                <!--                  <img class="max-w-100" :src="img.imagePath" alt="공지사항">-->
                <!--                </div>-->
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import showdown from "showdown"

export default {
  name: "NoticeList",
  data() {
    return {
      type: 0,  // 0: 전체, 1: 공통, 2: 지사
      items: [],
      selectedIdx: '',
      detailContent: '',
      imageList: [],
      loaded: false,
    }
  },

  created() {
    this.getNotice(0)
  },

  methods: {
    getNotice(_type) {
      this.type = _type
      this.$get(this.$NOTICE + '?type=' + this.type, 'noticelist_getitems', (result) => {
        this.items = result.data
        this.loaded = true
      }, (result) => {
        this.httpError(result.data)
      })
    },

    goDetail(_no) {
      if (this.selectedIdx === _no) {
        this.selectedIdx = ''
        return
      }
      this.imageList = []
      this.detailContent = ''
      this.$get(this.$NOTICE + '/' + _no, 'noticelist_godetail', (result) => {
        // this.imageList = result.data.imageList
        let content = result.data.content
        let converter = new showdown.Converter()
        this.detailContent = converter.makeHtml(content).replaceAll('\\', '')

        this.selectedIdx = _no
      }, (result) => {
        this.httpError(result.data)
      })
    }
  }
}
</script>

<style scoped>

</style>
