<template>
  <div class="all-content">
    <div class="bg-white content-row-new">
      <div class="confirm-list">
        <p>
          <img class="check-pink mb-30 mt-50" src="/image/bang_pink.svg" alt="캐릭터">
          <em class="t-c pink-title weight-light">본 계정은 탈퇴된 계정입니다.<br/>다시 한번 확인해주세요.</em>
        </p>
        <p>
          <img src="/image/character_hat_worry.svg" alt="캐릭터">
        </p>

      </div>
    </div>
    <button class="btn-l btn-pink" @click="$router.back()">확 인</button>
  </div>
</template>

<script>
export default {
  name: "LeaveUser"
}
</script>

<style scoped>

</style>