<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.replace({ name : 'ChargeSalesHistoryDetail', params : { monthlyPaymentIdx: idx, type: groupType }})"><i class="icon-back"></i></button>
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pb-80">
        <div class="charge-form mb-50">
          <em class="pink-title">결제 정보를<br>입력해주세요</em>

          <p class="mb-10 mt-30 fz-18">납입타입</p>

          <div class="d-flex-row mb-30">
            <div class="radios-circle">
              <input type="radio" id="normalPayment" :checked="paymentType === 'N'" @click="selectedPaymentType('N')">
              <label for="normalPayment">일반결제</label>
            </div>

            <div class="radios-circle ml-20" v-if="groupType !== '3'">
              <input type="radio" id="regularPayment" :checked="paymentType === 'R'" @click="selectedPaymentType('R')">
              <label for="regularPayment">정기결제</label>
            </div>
          </div>

          <div v-if="paymentType === 'N'">
            <p class="mb-10 mt-30 fz-18">납입예정일을 선택해주세요</p>
            <div class="select">
              <date-picker class="fz-16 t-c h-100 w-100 br-5"
                           v-model="payDate"
                           :config="this.rentCalendarOptions"
                           @input="selectedDatePicker(0)"
                           readonly
              />
            </div>
            <em class="color-gray">납입 예정일은 5일 후 부터 설정이 가능합니다.</em>
          </div>

          <div v-else>
            <div class="payment-select">
              <ul>
                <li class="" :class="{ 'active' :  paymentPeriodType === 0 }" @click="selectedPeriod(0)"><a
                    :class="{ 'color-pink' : paymentPeriodType === 0, 'color-gray' : paymentPeriodType !== 0 }">직접
                  입력</a></li>
                <li class="" :class="{ 'active' :  paymentPeriodType === 1 }" @click="selectedPeriod(1)"><a
                    :class="{ 'color-pink' : paymentPeriodType === 1, 'color-gray' : paymentPeriodType !== 1 }">1년
                  계약</a></li>
                <li class="" :class="{ 'active' :  paymentPeriodType === 2 }" @click="selectedPeriod(2)"><a
                    :class="{ 'color-pink' : paymentPeriodType === 2, 'color-gray' : paymentPeriodType !== 2 }">2년
                  계약</a></li>
              </ul>
            </div>

            <div class="d-flex-row">
              <div class="select">
                <date-picker id="startDatePicker"
                             class="fz-16 t-c h-100 w-100 br-5" v-model="startDate"
                             :config="this.rentCalendarOptions2"
                             @input="selectedDatePicker(1)"
                             readonly
                />
              </div>
              <span class="h-100 t-c self-center" style="width: 40px;"> ~ </span>
              <div class="select">
                <date-picker  id="endDatePicker"
                              class="fz-16 t-c h-100 w-100 br-5" v-model="endDate"
                             :config="this.rentCalendarOptions2"
                             @input="selectedDatePicker(2)"
                             readonly
                />
              </div>
            </div>

            <p class="mb-10 mt-30 fz-18">월 납입일을 선택해주세요</p>
            <div>
              <button class="select t-c" @click="openMonthlyDateList">{{ monthlyDate.name }}</button>
            </div>
          </div>

          <p class="mb-20 mt-30 fz-18">납입상세<span class="color-red"> *</span></p>
          <div class="d-flex-row w-100">
            <p class="fz-16 self-center w-22">납입금액</p>
            <div class="select w-78">
              <RPInputBorder
                  :type="'text'"
                  :model="paymentAmount01"
                  :disable="true"
                  @update="(_model) => {this.paymentAmount01=_model}"
                  @focusout.native="calculatePayAmount"
                  :input-mode="'decimal'"
              />
            </div>
          </div>
          <span class="color-red">{{ errors.paymentAmount01 }}</span>

          <div class="d-flex-row w-100 mt-10">
            <p class="fz-16 w-22 self-center">수수료</p>
            <div class="select-disable w-78">
              <RPInputBorder
                  :type="'text'"
                  :model="displayFee"
                  :disable="true"
                  :placeholder="placeholderFee"
                  @update="(_model) => {this.displayFee=_model}"
              />
            </div>
          </div>

          <div class="d-flex-row w-100 mt-10">
            <p class="fz-16 w-22 self-center">결제금액</p>
            <div class="select-disable w-78">
              <RPInputBorder
                  :type="'text'"
                  :model="paymentAmount02"
                  :disable="true"
                  @update="(_model) => {this.paymentAmount02=_model}"
                  :input-mode="'decimal'"
              />
            </div>
          </div>

          <p class="mb-10 mt-30 fz-18">카드선택<span class="color-red"> *</span></p>

          <div class="select-wrap">
            <button class="select" v-if="selectedCard !== null" @click="openCardList()">
              {{ selectedCard.name + ' ' }}{{ selectedCard.number | cardNumberMasking }}
            </button>
            <button class="select color-gray" v-else-if="cardList.length > 0" @click="openCardList()">카드선택</button>
            <button class="select color-blue-5D8BE1" v-else @click="openCardList()">+ 카드등록 바로가기</button>
          </div>

          <div v-if="paymentAmount02 >= 50000">
            <p class="mb-10 mt-30 fz-16 color-darkgray weight-light">할부기간을 선택해주세요</p>
            <div class="select-wrap">
              <button class="select" @click="openInstallmentPeriod()">
                {{ selectedInstallment.name }}
              </button>
            </div>
            <button class="fz-16 mt-10 color-blue-5D8BE1 ml-auto display-block">카드사별 무이자 혜택 >
            </button>
          </div>

          <div class="payment-sign-box mt-40">
            <div class="checks-box">
              <div class="checks-circle">
                <input type="checkbox" id="autoLogin" @click="agree = !agree">
                <label for="autoLogin">약관 전체 동의</label>
              </div>
            </div>
            <div class="regist-box">
              <div class="checks" @click="goTerm(term.idx)" v-for="(term, index) in termList" :key="index">
                <input type="checkbox" v-bind:id="'chk' + (index + 1)" disabled v-model="agree">
                <label :for="'chk' + (index + 1)" class="color-gray-B8B8B8 fz-15">(필수) {{ term.name }}</label>
                <button type="button" class="btn"><i class="icon-arrow-right color-gray-B8B8B8"></i></button>
              </div>
            </div>
          </div>
        </div>
        <em class="color-gray-B8B8B8 fz-15">신청 후 심사소요기간은 최대 72시간 이내입니다.</em>
      </div>
      <button class="align-bottom btn btn-m btn-pink" @click="applyPayment()">결제 신청</button>
    </div>

    <charge-card-select
        v-show="showCardList"
        :cards="cardList"
        @selected="setSelectedCard"
        @close="showCardList=false"/>

    <installment-period-select
        v-show="showInstallmentList"
        :periods="installmentList"
        @selected="setInstallmentPeriod"
        @close="showInstallmentList=false"/>

    <monthly-date-select
        v-show="showMonthlyDateList"
        :dates="monthlyDateList"
        @selected="setMonthlyDate"
        @close="showMonthlyDateList=false"/>

  </div>
</template>

<script>
import ChargeCardSelect from "@/components/charge/ChargeCardSelect";
import RPInputBorder from "@/components/common/RPInputBorder";
import InstallmentPeriodSelect from "@/components/payment/rentPayment/InstallmentPeriodSelect";
import MonthlyDateSelect from "@/components/payment/rentPayment/MonthlyDateSelect";
import moment from "moment";
import EventBus from "@/main";

export default {
  name: "ChargeSalesMonthlyRepay",
  components: {
    MonthlyDateSelect,
    InstallmentPeriodSelect,
    RPInputBorder,
    ChargeCardSelect,

  },
  data() {
    return {
      repayData : [],

      groupType: '',  // 0:월세, 1:관리비, 2:보증금, 3:수리비, 4:교육비, 5:기타

      rentCalendarOptions: {},
      rentCalendarOptions2: {},

      paymentType: 'N', // N: 일반결제, R: 정기결제

      // 일반결제
      payDate: '',  // 납입예정일

      // 정기결제
      paymentPeriodType: 0,
      startDate: '',  // 납입 시작일
      endDate: '',

      monthlyDateList: [
        {
          date: 1,
          name: '매월 1일',
        },
        {
          date: 2,
          name: '매월 2일',
        },
        {
          date: 3,
          name: '매월 3일',
        },
        {
          date: 4,
          name: '매월 4일',
        },
        {
          date: 5,
          name: '매월 5일',
        },
        {
          date: 6,
          name: '매월 6일',
        },
        {
          date: 7,
          name: '매월 7일',
        },
        {
          date: 8,
          name: '매월 8일',
        },
        {
          date: 9,
          name: '매월 9일',
        },
        {
          date: 10,
          name: '매월 10일',
        }, {
          date: 11,
          name: '매월 11일',
        }, {
          date: 12,
          name: '매월 12일',
        },
        {
          date: 13,
          name: '매월 13일',
        },
        {
          date: 14,
          name: '매월 14일',
        },
        {
          date: 15,
          name: '매월 15일',
        },
        {
          date: 16,
          name: '매월 16일',
        },
        {
          date: 17,
          name: '매월 17일',
        },
        {
          date: 18,
          name: '매월 18일',
        },
        {
          date: 19,
          name: '매월 19일',
        },
        {
          date: 20,
          name: '매월 20일',
        },
        {
          date: 21,
          name: '매월 21일',
        },
        {
          date: 22,
          name: '매월 22일',
        },
        {
          date: 23,
          name: '매월 23일',
        },
        {
          date: 24,
          name: '매월 24일',
        },
        {
          date: 25,
          name: '매월 25일',
        },
        {
          date: 26,
          name: '매월 26일',
        },
        {
          date: 27,
          name: '매월 27일',
        },
        {
          date: 28,
          name: '매월 28일',
        },
        {
          date: 29,
          name: '매월 29일',
        },
        {
          date: 30,
          name: '매월 30일',
        },
        {
          date: 31,
          name: '매월 31일',
        },
      ],
      monthlyDate: null,  // 월 납입일
      showMonthlyDateList: false,

      // 납입상세
      paymentAmount01: '',  // 납입금액
      fee: '',  // 수수료
      tax: '', // 부가세율
      combinedTax: '', // 합산세율
      // feeAmount: '',  // 수수료 금액
      combinedTaxAmount: '',
      displayFee: '',
      placeholderFee: '',
      paymentAmount02: '',  // 결제금액

      cardList: [],
      selectedCard: null, // 결제카드
      showCardList: false,

      showInstallmentList: false, // 할부기간
      installmentList: [
        {
          'installment': '00',
          'name': '일시불'
        },
        {
          'installment': '02',
          'name': '2개월'
        },
        {
          'installment': '03',
          'name': '3개월'
        },
        {
          'installment': '04',
          'name': '4개월'
        },
        {
          'installment': '05',
          'name': '5개월'
        },
        {
          'installment': '06',
          'name': '6개월'
        },
        {
          'installment': '07',
          'name': '7개월'
        },
        {
          'installment': '08',
          'name': '8개월'
        },
        {
          'installment': '09',
          'name': '9개월'
        },
        {
          'installment': '10',
          'name': '10개월'
        },
        {
          'installment': '11',
          'name': '11개월'
        },
        {
          'installment': '12',
          'name': '12개월'
        },
      ],
      selectedInstallment: null,

      agree: false,   // 약관동의

      errors: {
        period: '', // 기간
        paymentAmount01: '',  // 납입금액
        // fee: '',  // 수수료
        combinedTax: '',  // 합산세율
        paymentAmount02: '',  // 결제금액
        selectedCard: '', // 결제카드
        agree: '',  // 약관동의
      },

      type: '',
      // usePurpose: '',

      //수정시 사용
      // deleteIdxs: [],
      idx: '',

      termList: [],
    }
  },
  mounted() {
    this.type = this.$route.params.type
    this.groupType = this.$route.params.groupType

    this.repayData = this.$route.params.repayData
    this.idx = this.repayData.idx
    this.paymentType = this.repayData.type
    this.paymentAmount01 = this.repayData.amount
    this.fee = this.repayData.fee
    this.tax = this.repayData.tax
    this.combinedTax = (this.fee * 1) + (this.tax * 1)
    this.placeholderFee = '(' + this.combinedTax + '%)'
    this.cardList = this.repayData.cardList
    this.cardIdx = this.repayData.cardIdx

    for (let i = 0; i < this.cardList.length; i++) {
      if (this.cardList[i].idx === (this.cardIdx * 1)) {
        this.selectedCard = this.cardList[i]
      }
    }

    for (let i = 0; i < this.installmentList.length; i++) {
      if (this.installmentList[i].installment === this.repayData.installment) {
        this.selectedInstallment = this.installmentList[i]
      }
    }

    this.getMinDate()

    if (this.paymentType === 'R') {
      if (moment(this.repayData.sdate).isAfter(this.startDate)) {
        this.startDate = this.repayData.sdate
      }
      this.endDate = this.repayData.edate
      for (let i = 0; i < this.monthlyDateList.length; i++) {
        if (this.monthlyDateList[i].date === (this.repayData.pdate * 1)) {
          this.monthlyDate = this.monthlyDateList[i]
        }
      }
    } else {
      if (moment(this.repayData.pdate).isAfter(this.payDate)){
        this.payDate = moment(this.repayData.pdate, 'YYYY-MM-DD')
      }
      this.monthlyDate = this.monthlyDateList[0]
    }

    this.getTermList()

    EventBus.$on(this.$EVENT_REFRESH_CARDINFO_REPAY, function () {
      this.getCardList()  // 카드 정보 가져오기
    }.bind(this))
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'RentTerm' || to.name === 'RegistCard'){
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }

    next()
  },
  methods: {
    getTermList() {
      this.$get(this.$TERMS, 'chargesalesmonthlyrepay_terms', (result) => {
        this.termList = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goTerm(_index){
      this.$router.push({ name: 'RentTerm', params: { idx : _index }})
    },
    getCardList(){
      this.$get(this.$MONTHLY_REPAY + '/' + this.idx, this.$options.name + '_monthlyRepay', (result) => {
        this.cardList = result.data.cardList

      }, (result) => {
        this.httpError(result.data)
      })
    },
    getMinDate() { // 현재날짜 + 5일 후 부터 선택 가능
      let currentDate = new Date()
      let year = currentDate.getFullYear()
      let month = currentDate.getMonth()
      let day = currentDate.getDate()

      let endDate = new Date(year, month + 1, 0).getDate()
      let minDate = null
      if ((endDate - day) < 5) { // minDate 날짜 : month + 1 & 4-(현월 마지막 날짜-현재 날짜)
        minDate = new Date(year, month + 1, 5 - (endDate - day))

      } else {  // minDate : 현재날짜에서 + 5
        minDate = new Date(year, month, day + 5)
      }

      this.initCalendarOptions(minDate)

      let minMonth = month + 1
      let _month = minMonth < 10 ? '0' + minMonth : minMonth
      let minDay = day + 5
      let _day = minDay < 10 ? '0' + minDay : minDay
      let _minDate = year + '-' + _month + '-' + _day
      this.payDate = _minDate

      let minDateMonth = minDate.getMonth()
      let selectableMonth = minDateMonth + 1 < 10 ? '0' + (minDateMonth + 1) : minDateMonth + 1
      this.startDate = year + '-' + selectableMonth
      this.endDate = year + '-' + selectableMonth
    },
    initCalendarOptions(_minDate) {
      this.rentCalendarOptions = {
        format: 'YYYY-MM-DD',
        locale: moment.locale('ko'),
        minDate: _minDate,
        icons: {
          time: 'glyphicon glyphicon-time',
          date: 'glyphicon glyphicon-calendar',
          up: 'icon-arrow-up',
          down: 'icon-arrow-down',
          previous: 'icon-arrow-left',
          next: 'icon-arrow-right',
          today: 'glyphicon glyphicon-screenshot',
          clear: 'glyphicon glyphicon-trash',
          close: 'glyphicon glyphicon-remove'
        },
        ignoreReadonly: true,
      },

      this.rentCalendarOptions2 = {
        format: 'YYYY-MM',
        locale: moment.locale('ko'),
        minDate: moment(_minDate.getFullYear() + '-' + (_minDate.getMonth() + 1 > 9 ? '' : '0') + (_minDate.getMonth() + 1)),
        icons: {
          time: 'glyphicon glyphicon-time',
          date: 'glyphicon glyphicon-calendar',
          up: 'icon-arrow-up',
          down: 'icon-arrow-down',
          previous: 'icon-arrow-left',
          next: 'icon-arrow-right',
          today: 'glyphicon glyphicon-screenshot',
          clear: 'glyphicon glyphicon-trash',
          close: 'glyphicon glyphicon-remove'
        },
        ignoreReadonly: true,
      }
    },
    errorClear() {
      this.errors = {
        period: '', // 기간
        paymentAmount01: '',  // 납입금액
        fee: '',  // 수수료율
        tax: '',  // 부가세율
        paymentAmount02: '',  // 결제금액
        selectedCard: '', // 결제카드
        agree: '',  // 약관동의
      }
    },
    selectedPaymentType(_type) {
      this.paymentType = _type
    },
    selectedPeriod(_type) {
      this.paymentPeriodType = _type
      let year = this.startDate.substr(0,4)
      let month = this.startDate.substr(5,2)
      switch (_type) {
        case 0: // 직접입력
          window.document.getElementById("startDatePicker").disabled = false
          window.document.getElementById("endDatePicker").disabled = false
          this.endDate = this.startDate
          break

        case 1: // 1년 계약
          // 종료일을 startdate에서 1년 이후로 설정
          window.document.getElementById("startDatePicker").disabled = true
          window.document.getElementById("endDatePicker").disabled = true
          if (month === '01') {
            this.endDate = year + '-' + '12'
          } else {
            this.endDate = ((year * 1) + 1) + '-' + (month - 1)
          }
          break

        case 2: // 2년 계약
          // 종료일을 startdate에서 2년 이후로 설정
          window.document.getElementById("startDatePicker").disabled = true
          window.document.getElementById("endDatePicker").disabled = true
          if (month === '01') {
            this.endDate = ((year * 1) + 1) + '-' + '12'
          } else {
            this.endDate = ((year * 1) + 2) + '-' + (month - 1)
          }
          break
      }
    },
    selectedDatePicker(_type) { // 예정일, 정기결제 시작일, 정기결제 종료일
      switch (_type) {
        case 0: // 예정일

          break

        case 1:

          break

        case 2:

          break
      }
      // this.year = this.selectedDate.split('.')[0]
      // this.month = this.selectedDate.split('.')[1]
      // this.day = this.selectedDate.split('.')[2]
    },
    calculatePayAmount(){
      // test start
      // this.fee = 4.4
      this.combinedTaxAmount = Math.floor(this.paymentAmount01 * (this.combinedTax / 100))
      this.displayFee = "(" + this.combinedTax + "%) " + this.combinedTaxAmount
      this.paymentAmount02 = (this.paymentAmount01 * 1) + (this.combinedTaxAmount * 1)
    },
    openCardList() {
      this.showCardList = true
    },
    setSelectedCard(_card) {
      this.selectedCard = _card
      this.showCardList = false
    },
    openMonthlyDateList() {
      this.showMonthlyDateList = true
    },
    setMonthlyDate(_date) {
      this.monthlyDate = _date
      this.showMonthlyDateList = false
    },
    openInstallmentPeriod() {
      this.showInstallmentList = true
    },
    setInstallmentPeriod(_period) {
      this.selectedInstallment = _period
      this.showInstallmentList = false
    },
    applyPayment() {
      // console.log(this.startDate)
      // console.log(this.endDate)
      // console.log(this.payDate)
      // return

      this.errorClear()

      if (!this.type || !this.groupType) {
        this.notify('error', '오류가 발생 했습니다. 처음부터 다시 진행 해주세요.')
        this.$router.go(-2)
        return
      }

      if (!this.agree) {
        this.notify('error', '약관에 동의해주세요')
        return
      }

      let formData = new FormData()

      //type : 0:등록, 1:수정, 2:재심사, 3:재결제
      formData.append('type', this.type)
      formData.append('groupType', this.groupType)
      if (this.type !== '0') {
        formData.append('idx', this.idx)
      }
      formData.append('paymentType', this.paymentType)
      if (this.paymentType === 'R') {
        formData.append('startDate', this.startDate)
        formData.append('endDate', this.endDate)
        formData.append('payDate', this.monthlyDate.date)
      } else {
        formData.append('payDate', this.payDate)
      }
      formData.append('amount', this.paymentAmount01)
      formData.append('fee', this.fee)
      formData.append('tax', this.tax)
      formData.append('cardIdx', this.selectedCard.idx)
      formData.append('installment', this.selectedInstallment.installment)

      this.$post(this.$MONTHLY_REPAY, this.$options.name + '_applyPayment', formData, true,(result) => {
        // 재결제 신청 success 화면 출력
        this.$router.replace({
          name: 'RentRepaymentSuccess', params: {
            date: result.data.date,
            p_date: result.data.p_date,
            amount: result.data.amount,
          }
        })
      }, (result) => {
        this.httpError(result.data)
      })
    }

  },
  watch: {
    paymentAmount01() {
      this.calculatePayAmount()
    },
  }
}
</script>

<style scoped>

</style>
