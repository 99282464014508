import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import Intro from "@/components/intro/Intro";
import BeforeLogin from "@/components/intro/BeforeLogin";
import Login from "@/components/user/login/Login";
import InactivityRequest from "@/components/user/login/inactivity/InactivityRequest";
import InactivitySuccess from "@/components/user/login/inactivity/InactivitySuccess";
import InactivityFail from "@/components/user/login/inactivity/InactivityFail";
import LeaveUser from "@/components/user/login/LeaveUser";
import TermAgree from "@/components/user/signup/TermAgree";
import SignUp01 from "@/components/user/signup/SignUp01";
import SignUp02 from "@/components/user/signup/SignUp02";
import SignUpFinish from "@/components/user/signup/SignUpFinish";
import SignUpPinRegist from "@/components/user/signup/pin/SignUpPinRegist";
import SignUpPinCheck from "@/components/user/signup/pin/SignUpPinCheck";
import SignUpPinFinish from "@/components/user/signup/pin/SignUpPinFinish";
import FindId from "@/components/user/find/findId/FindId";
import FindIdSuccess from "@/components/user/find/findId/FindIdSuccess";
import FindIdFail from "@/components/user/find/findId/FindIdFail";
import FindPw from "@/components/user/find/findPw/FindPw";
import FindPwNoId from "@/components/user/find/findPw/FindPwNoId";
import FindPwChange from "@/components/user/find/findPw/FindPwChange";
import FindPwChangeFinish from "@/components/user/find/findPw/FindPwChangeFinish";
import Main from "@/components/main/Main";
import Charge from "@/components/charge/Charge";
import ChargeSuccess from "@/components/charge/ChargeSuccess";
import ChargeFail from "@/components/charge/ChargeFail";
import RentPayment from "@/components/payment/rentPayment/RentPayment";
import QRPayment from "@/components/payment/qrPayment/QRPayment";
import QRPaymentSuccess from "@/components/payment/qrPayment/QRPaymentSuccess";
import QRPaymentFast from "@/components/payment/qrPayment/QRPaymentFast";
import SMSPayment from "@/components/payment/smsPayment/SMSPayment";
import SMSPaymentSuccess from "@/components/payment/smsPayment/SMSPaymentSuccess";
import SMSPaymentFail from "@/components/payment/smsPayment/SMSPaymentFail";
import HandPaymentSuccess from "@/components/payment/handPayment/HandPaymentSuccess";
import HandPayment from "@/components/payment/handPayment/HandPayment";
import HandPaymentFail from "@/components/payment/handPayment/HandPaymentFail";
import TerminalPayment from "@/components/payment/terminalPayment/TerminalPayment";
import TerminalPaymentSuccess from "@/components/payment/terminalPayment/TerminalPaymentSuccess";
import TerminalPaymentFail from "@/components/payment/terminalPayment/TerminalPaymentFail";
import QRPaymentFail from "@/components/payment/qrPayment/QRPaymentFail";
import ReviewingUser from "@/components/user/login/ReviewingUser";
import QRScanInputAmount from "@/components/payment/qrPayment/QRScanInputAmount";
import SMSContactAdd from "@/components/payment/smsPayment/SMSContactAdd";
import SMSContactSelect from "@/components/payment/smsPayment/SMSContactSelect";
import HandPaymentCardInfo from "@/components/payment/handPayment/HandPaymentCardInfo";
import TerminalPaymentCardRead from "@/components/payment/terminalPayment/TerminalPaymentCardRead";
// import ChargePaymentHistory from "@/components/history/chargeHistory/ChargePaymentHistory";
// import ChargeSalesHistory from "@/components/history/chargeHistory/ChargeSalesHistory";
import ChargePaymentHistoryDetail from "@/components/history/chargeHistory/ChargePaymentHistoryDetail";
import PaymentSMSHistoryDetail from "@/components/history/paymentHistory/PaymentSMSHistoryDetail";
// import SalesHistory from "@/components/history/salesHistory/SalesHistory";
// import SalesCardInfo from "@/components/history/salesHistory/SalesCardInfo";
// import SalesCardDetailInfo from "@/components/history/salesHistory/SalesCardDetailInfo";
// import SalesDeliveryInfo from "@/components/history/salesHistory/SalesDeliveryInfo";
// import SalesDeliveryDetailInfo from "@/components/history/salesHistory/SalesDeliveryDetailInfo";
// import RequestCancelFinish from "@/components/history/salesHistory/RequestCancelFinish";
// import SalesAnalysis from "@/components/history/SalesAnalysis";
import MyPage from "@/components/mypage/MyPage";
import NoticeList from "@/components/mypage/notice/NoticeList";
import FaqList from "@/components/mypage/faq/FaqList";
import FaqSubList from "@/components/mypage/faq/FaqSubList";
import MyInfo from "@/components/myinfo/MyInfo";
import DeliveryAgencyList from "@/components/myinfo/deliveryAgency/DeliveryAgencyList";
import RegistDeliveryAgency from "@/components/myinfo/deliveryAgency/regist/RegistDeliveryAgency";
import RegisterComplete from "@/components/myinfo/deliveryAgency/regist/RegisterComplete";
import SalesAlert from "@/components/myinfo/SalesAlert";
import SecuritySetting from "@/components/myinfo/SecuritySetting";
import AddLoginAccount from "@/components/mypage/login/AddLoginAccount";
import ChangePassword from "@/components/myinfo/password/ChangePassword";
import ChangePasswordSuccess from "@/components/myinfo/password/ChangePasswordSuccess";
import ChangePinCheck from "@/components/myinfo/pin/ChangePinCheck";
import ChangePinRegist from "@/components/myinfo/pin/ChangePinRegist";
import ChangePinRegistRE from "@/components/myinfo/pin/ChangePinRegistRE";
import ChangePinSuccess from "@/components/myinfo/pin/ChangePinSuccess";
import RestorePin from "@/components/myinfo/pin/RestorePin";
import CardList from "@/components/myinfo/card/CardList";
import RegistCard from "@/components/myinfo/card/regist/RegistCard";
import RegistCardSuccess from "@/components/myinfo/card/regist/RegistCardSuccess";
import RegistCardFail from "@/components/myinfo/card/regist/RegistCardFail";
import Leave from "@/components/myinfo/leave/Leave";
import LeaveSuccess from "@/components/myinfo/leave/LeaveSuccess";
import PaymentHistoryDetail from "@/components/history/paymentHistory/PaymentHistoryDetail";
import RegistAccount from "@/components/payment/rentPayment/RegistAccount";
import RentRegistSuccess from "@/components/payment/rentPayment/RentRegistSuccess.vue";
import RentPaymentInfoInput from "@/components/payment/rentPayment/RentPaymentInfoInput";
import RentPaymentSuccess from "@/components/payment/rentPayment/RentPaymentSuccess";
import ChargeSalesHistoryDetail from "../components/history/chargeHistory/ChargeSalesHistoryDetail";
import StopPaying from "../components/history/chargeHistory/StopPaying";
import ChangeRentName from "../components/history/chargeHistory/ChangeRentName";
import RequestCancelSuccess from "../components/history/paymentHistory/RequestCancelSuccess";
// import ChargeSalesHistoryCardDetail from "../components/history/chargeHistory/ChargeSalesHistoryCardDetail";
import ChargeSalesMonthlyRepay from "../components/history/chargeHistory/ChargeSalesMonthlyRepay";
import QRCardScanInputAmount from "@/components/payment/qrCardPayment/QRCardScanInputAmount";
import QRCardPaymentFast from "@/components/payment/qrCardPayment/QRCardPaymentFast";
import QRCardPaymentSuccess from "@/components/payment/qrCardPayment/QRCardPaymentSuccess";
import QRCardPaymentFail from "@/components/payment/qrCardPayment/QRCardPaymentFail";
import RentRepaymentSuccess from "@/components/history/chargeHistory/RentRepaymentSuccess";
import QnaList from "@/components/mypage/qna/QnaList";
import QnaDetail from "@/components/mypage/qna/QnaDetail";
import QnaRegist from "@/components/mypage/qna/QnaRegist";
import Term from "@/components/user/signup/term/Term";
import RentTerm from "@/components/payment/rentPayment/RentTerm";
import CardInstallmentPlan from "@/components/payment/rentPayment/CardInstallmentPlan";
import QnaTerm from "@/components/mypage/qna/QnaTerm";
import NotificationList from "@/components/main/notification/NotificationList";
import NotificationRentRepay from "@/components/main/notification/NotificationRentRepay";
import SearchHistory from "@/components/main/searchHistory/SearchHistory";
import NotificationSetting from "@/components/mypage/notification/NotificationSetting";
import QuickPaymentHistory from "@/components/history/chargeHistory/QuickPaymentHistory";
import RentPaymentHistory from "@/components/history/chargeHistory/RentPaymentHistory";
import PGPaymentHistory from "@/components/history/paymentHistory/PGPaymentHistory";
import SMSReservationHistory from "@/components/history/paymentHistory/SMSReservationHistory";
import SsoLogin from "@/components/sso/SsoLogin";
import SsoFindId from "@/components/sso/findid/SsoFindId";
import SsoFindPwChangeFinish from "@/components/sso/findpw/SsoFindPwChangeFinish";
import SsoFindPwChange from "@/components/sso/findpw/SsoFindPwChange";
import SsoFindPwNoId from "@/components/sso/findpw/SsoFindPwNoId";
import SsoFindPw from "@/components/sso/findpw/SsoFindPw";
import SsoFindIdFail from "@/components/sso/findid/SsoFindIdFail";
import SsoFindIdSuccess from "@/components/sso/findid/SsoFindIdSuccess";
import SsoSignUpFinish from "@/components/sso/ssosignup/SsoSignUpFinish";
import SsoSignUp02 from "@/components/sso/ssosignup/SsoSignUp02";
import SsoSignUp01 from "@/components/sso/ssosignup/SsoSignUp01";
import SsoTermAgree from "@/components/sso/ssosignup/SsoTermAgree";
import RentBeforePayment from "@/components/history/chargeHistory/RentBeforePayment.vue";
import RentBeforePaymentDetail from "@/components/history/chargeHistory/RentBeforePaymentDetail.vue";

export default new Router({
    routes: [
        {
          path: '/',
          name: 'Intro',
          component: Intro
        },
        {
            path: '/beforeLogin',
            name: 'BeforeLogin',
            component: BeforeLogin,
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        // 휴먼계정
        {
            path: '/inactivity/request',
            name: 'InactivityRequest',
            component: InactivityRequest,
        },
        {
            path: '/inactivity/success',
            name: 'InactivitySuccess',
            component: InactivitySuccess,
        },
        {
            path: '/inactivity/fail',
            name: 'InactivityFail',
            component: InactivityFail,
        },
        // 탈퇴계정
        {
            path: '/leave/user',
            name: 'LeaveUser',
            component: LeaveUser,
        },
        // 탈퇴계정
        {
            path: '/review/user',
            name: 'ReviewingUser',
            component: ReviewingUser,
        },
        // 회원가입-이용약관
        {
            path: '/term/agree',
            name: 'TermAgree',
            component: TermAgree,
            meta : {
                keepAlive: true
            }
        },
        {
            path: '/term/termDetail',
            name: 'Term',
            component: Term,
        },
        // 회원가입-정보입력
        {
            path: '/signup/step1',
            name: 'SignUp01',
            component: SignUp01,
            meta : {
                keepAlive: true
            }
        },
        {
            path: '/signup/step2',
            name: 'SignUp02',
            component: SignUp02,
            meta : {
                keepAlive: true
            }
        },
        {
            path: '/signup/finish',
            name: 'SignUpFinish',
            component: SignUpFinish,
        },
         //signup - pin
        {
            path: '/signup/pin/regist',
            name: 'SignUpPinRegist',
            component: SignUpPinRegist,
        },
        {
            path: '/signup/pin/check',
            name: 'SignUpPinCheck',
            component: SignUpPinCheck,
        },
        {
            path: '/signup/pin/finish',
            name: 'SignUpPinFinish',
            component: SignUpPinFinish,
        },
        //find id
        {
            path: '/find/id',
            name: 'FindId',
            component: FindId,
        },
        {
            path: '/find/id/success',
            name: 'FindIdSuccess',
            component: FindIdSuccess,
        },
        {
            path: '/find/id/fail',
            name: 'FindIdFail',
            component: FindIdFail,
        },
        //find pw
        {
            path: '/find/pw',
            name: 'FindPw',
            component: FindPw,
        },
        {
            path: '/find/pw/noId',
            name: 'FindPwNoId',
            component: FindPwNoId,
        },
        {
            path: '/find/pw/change',
            name: 'FindPwChange',
            component: FindPwChange,
        },
        {
            path: '/find/pw/change/finish',
            name: 'FindPwChangeFinish',
            component: FindPwChangeFinish,
        },
        //main
        {
            path: '/main',
            name: 'Main',
            component: Main,
        },
        // searchHistory
        {
            path: '/searchhistory',
            name: 'SearchHistory',
            component: SearchHistory,
        },
        // notification
        {
          path: '/notifications',
          name: 'NotificationList',
          component: NotificationList,
        },
        // notificationRentRepay
        {
            path: '/notifications/repay',
            name: 'NotificationRentRepay',
            component: NotificationRentRepay,
            meta : {
                keepAlive: true
            }

        },
        //charge
        {
            path: '/charge',
            name: 'Charge',
            component: Charge,
        },
        {
            path: '/charge/success',
            name: 'ChargeSuccess',
            component: ChargeSuccess,
        },
        {
            path: '/charge/fail',
            name: 'ChargeFail',
            component: ChargeFail,
        },
        //payment-rentPayment
        {
            path: '/payment/rentpayment',
            name: 'RentPayment',
            component: RentPayment,
            meta : {
                keepAlive: true
            }
        },
        {
            path: '/payment/rentpayment/regist/account',
            name: 'RegistAccount',
            component: RegistAccount,
        },
        {
            path: '/payment/rentpayment/regist/success',
            name: 'RentRegistSuccess',
            component: RentRegistSuccess,
        },
        {
            path: '/payment/rentpayment/input/paymentinfo/:type?/:idx?/:amount?',
            name: 'RentPaymentInfoInput',
            component: RentPaymentInfoInput,
        },
        {
            path: '/payment/rentpayment/input/paymentinfo/cardinstallment',
            name: 'CardInstallmentPlan',
            component: CardInstallmentPlan,
        },
        {
            path: '/payment/rentpayment/input/paymentinfo/term',
            name: 'RentTerm',
            component: RentTerm,
        },
        {
            path: '/payment/rentpayment/success',
            name: 'RentPaymentSuccess',
            component: RentPaymentSuccess,
        },
         //payment-qrPayment
        {
            path: '/payment/qrpayment',
            name: 'QRPayment',
            component: QRPayment,
        },
        {
            path: '/payment/qrpayment/input/amount',
            name: 'QRScanInputAmount',
            component: QRScanInputAmount,
        },
        {
            path: '/payment/qrpayment/fast',
            name: 'QRPaymentFast',
            component: QRPaymentFast,
        },
        {
            path: '/payment/qrpayment/success',
            name: 'QRPaymentSuccess',
            component: QRPaymentSuccess,
        },
        {
            path: '/payment/qrpayment/fail',
            name: 'QRPaymentFail',
            component: QRPaymentFail,
        },
        //payment-qrCardPayment
        {
            path: '/payment/qrcardpayment/input/amount',
            name: 'QRCardScanInputAmount',
            component: QRCardScanInputAmount,
        },
        {
            path: '/payment/qrcardpayment/fast',
            name: 'QRCardPaymentFast',
            component: QRCardPaymentFast,
        },
        {
            path: '/payment/qrcardpayment/success',
            name: 'QRCardPaymentSuccess',
            component: QRCardPaymentSuccess,
        },
        {
            path: '/payment/qrcardpayment/fail',
            name: 'QRCardPaymentFail',
            component: QRCardPaymentFail,
        },
        //payment-sms
        {
            path: '/payment/smspayment',
            name: 'SMSPayment',
            component: SMSPayment,
            meta : {
                keepAlive: true
            }
        },
        {
            path: '/payment/smspayment/contact/add',
            name: 'SMSContactAdd',
            component: SMSContactAdd,
        },
        {
            path: '/payment/smspayment/contact/select',
            name: 'SMSContactSelect',
            component: SMSContactSelect,
        },
        {
            path: '/payment/smspayment/success',
            name: 'SMSPaymentSuccess',
            component: SMSPaymentSuccess,
        },
        {
            path: '/payment/smspayment/fail',
            name: 'SMSPaymentFail',
            component: SMSPaymentFail,
        },
        //payment-hand
        {
            path: '/payment/handpayment',
            name: 'HandPayment',
            component: HandPayment,
            meta : {
                keepAlive: true
            }
        },
        {
            path: '/payment/handpayment/cardinfo',
            name: 'HandPaymentCardInfo',
            component: HandPaymentCardInfo,
        },
        {
            path: '/payment/handpayment/success',
            name: 'HandPaymentSuccess',
            component: HandPaymentSuccess,
        },
        {
            path: '/payment/handpayment/fail',
            name: 'HandPaymentFail',
            component: HandPaymentFail,
        },
        //payment-terminal
        {
            path: '/payment/terminalpayment',
            name: 'TerminalPayment',
            component: TerminalPayment,
        },
        {
            path: '/payment/terminalpayment/cardread',
            name: 'TerminalPaymentCardRead',
            component: TerminalPaymentCardRead,
        },
        {
            path: '/payment/terminalpayment/success',
            name: 'TerminalPaymentSuccess',
            component: TerminalPaymentSuccess,
        },
        {
            path: '/payment/terminalpayment/fail',
            name: 'TerminalPaymentFail',
            component: TerminalPaymentFail,
        },
        //history-chargehistory
        // {
        //     path:'/history/chargehistory/payment',
        //     name: 'ChargePaymentHistory',
        //     component: ChargePaymentHistory,
        //     meta: {
        //         keepAlive: true
        //     }
        // },
        {
            path:'/history/chargehistory/payment/detail',
            name: 'ChargePaymentHistoryDetail',
            component: ChargePaymentHistoryDetail
        },
        {
            path:'/history/chargehistory/rent',
            name: 'RentBeforePayment',
            component: RentBeforePayment
        },
        {
            path:'/history/chargehistory/rent/:monthlyPaymentIdx?',
            name: 'RentBeforePaymentDetail',
            component: RentBeforePaymentDetail
        },
        // {
        //     path:'/history/chargehistory/sales',
        //     name: 'ChargeSalesHistory',
        //     component: ChargeSalesHistory
        // },
        {
            path:'/history/chargehistory/sales/:monthlyPaymentIdx?',
            name: 'ChargeSalesHistoryDetail',
            component: ChargeSalesHistoryDetail
        },
        {
            path:'/history/chargehistory/sales/monthly/repay',
            name: 'ChargeSalesMonthlyRepay',
            component: ChargeSalesMonthlyRepay,
            meta : {
                keepAlive: true
            }
        },
        {
            path:'/history/chargehistory/sales/monthly/repay/success',
            name: 'RentRepaymentSuccess',
            component: RentRepaymentSuccess,
        },
        // {
        //     path:'/history/chargehistory/sales/card/detail/:type',
        //     name: 'ChargeSalesHistoryCardDetail',
        //     component: ChargeSalesHistoryCardDetail
        // },
        {
            path:'/history/chargehistory/stop',
            name: 'StopPaying',
            component: StopPaying
        },
        {
            path:'/history/chargehistory/change/rentname',
            name: 'ChangeRentName',
            component: ChangeRentName
        },
        //history-paymenthistory
        {
            path:'/history/paymenthistory',
            name: 'PGPaymentHistory',
            component: PGPaymentHistory,
            meta : {
                keepAlive: true
            }
        },
        {
            path:'/history/smshistory',
            name: 'SMSReservationHistory',
            component: SMSReservationHistory,
            meta : {
                keepAlive: true
            }
        },
        {
            path:'/history/paymenthistory/detail',
            name: 'PaymentHistoryDetail',
            component: PaymentHistoryDetail
        },
        {
            path:'/history/paymenthistory/detail/sms',
            name: 'PaymentSMSHistoryDetail',
            component: PaymentSMSHistoryDetail
        },
        {
            path:'/history/paymenthistory/request/cancel/success',
            name: 'RequestCancelSuccess',
            component: RequestCancelSuccess
        },

        //history-quickpayment
        {
            path: '/history/quickpaymenthistory',
            name: 'QuickPaymentHistory',
            component: QuickPaymentHistory,
            meta: {
                keepAlive: true
            }
        },
        //history-rentpayment
        {
            path: '/history/rentpaymenthistory',
            name: 'RentPaymentHistory',
            component: RentPaymentHistory,
        },

        //history-salesHistory
        // {
        //     path:'/history/saleshistory',
        //     name: 'SalesHistory',
        //     component: SalesHistory
        // },
        // {
        //     path:'/history/saleshistory/card',
        //     name: 'SalesCardInfo',
        //     component: SalesCardInfo
        // },
        // {
        //     path:'/history/saleshistory/card/detail',
        //     name: 'SalesCardDetailInfo',
        //     component: SalesCardDetailInfo
        // },
        // {
        //     path:'/history/saleshistory/delivery',
        //     name: 'SalesDeliveryInfo',
        //     component: SalesDeliveryInfo
        // },
        // {
        //     path:'/history/saleshistory/delivery/detail',
        //     name: 'SalesDeliveryDetailInfo',
        //     component: SalesDeliveryDetailInfo
        // },
        // {
        //     path:'/history/saleshistory/cancel/finish',
        //     name: 'RequestCancelFinish',
        //     component: RequestCancelFinish
        // },
        //anlysis
        // {
        //     path:'/history/anlysis',
        //     name: 'SalesAnalysis',
        //     component: SalesAnalysis
        // },
        //mypage
        {
            path: '/mypage',
            name: 'MyPage',
            component: MyPage,
        },
        //mypage - login
        {
            path: '/mypage/add/login/account',
            name: 'AddLoginAccount',
            component: AddLoginAccount,
        },
         //mypage - notice
        {
            path: '/mypage/notices',
            name: 'NoticeList',
            component: NoticeList,
        },
        // mypage - notification
        {
            path: '/mypage/notificationsetting',
            name: 'NotificationSetting',
            component: NotificationSetting,
        },
        //mypage - qna
        {
            path: '/mypage/qna/list',
            name: 'QnaList',
            component: QnaList,
        },
        {
            path: '/mypage/qna/detail',
            name: 'QnaDetail',
            component: QnaDetail,
        },
        {
            path: '/mypage/qna/regist',
            name: 'QnaRegist',
            component: QnaRegist,
            meta: {
                keepAlive: true
            }
        },
        {
            path: '/mypage/qna/regist/term',
            name: 'QnaTerm',
            component: QnaTerm,
        },
        //mypage - faq
        {
            path: '/mypage/faq/list',
            name: 'FaqList',
            component: FaqList,
        },
        {
            path: '/mypage/faq/sub/list',
            name: 'FaqSubList',
            component: FaqSubList,
        },
         //myinfo
        {
            path: '/mypage/myinfo',
            name: 'MyInfo',
            component: MyInfo,
        },
        {
            path: '/mypage/myinfo/deliveryagencies',
            name: 'DeliveryAgencyList',
            component: DeliveryAgencyList,
        },
        {
            path: '/mypage/myinfo/deliveryagency/regist/:idx?',
            name: 'RegistDeliveryAgency',
            component: RegistDeliveryAgency,
        },
        {
            path: '/mypage/myinfo/deliveryagency/regist/complete',
            name: 'RegisterComplete',
            component: RegisterComplete,
        },
        {
            path: '/mypage/salesalert',
            name: 'SalesAlert',
            component: SalesAlert,
        },
        {
            path: '/mypage/securitysetting',
            name: 'SecuritySetting',
            component: SecuritySetting,
        },
//         //myinfo - change phone
//         {
//             path: '/mypage/myinfo/change/phone',
//             name: 'ChangePhone',
//             component: ChangePhone,
//         },
//         {
//             path: '/mypage/myinfo/change/phone/success',
//             name: 'ChangePhoneSuccess',
//             component: ChangePhoneSuccess,
//         },
//         {
//             path: '/mypage/myinfo/change/phone/fail',
//             name: 'ChangePhoneFail',
//             component: ChangePhoneFail,
//         },
        //myinfo - change password
        {
            path: '/mypage/myinfo/change/password',
            name: 'ChangePassword',
            component: ChangePassword,
        },
        {
            path: '/mypage/myinfo/change/password/success',
            name: 'ChangePasswordSuccess',
            component: ChangePasswordSuccess,
        },
        //myinfo - change pin
        {
            path: '/mypage/myinfo/change/pin/check',
            name: 'ChangePinCheck',
            component: ChangePinCheck,
        },
        {
            path: '/mypage/myinfo/change/pin/regist',
            name: 'ChangePinRegist',
            component: ChangePinRegist,
        },
        {
            path: '/mypage/myinfo/change/pin/re',
            name: 'ChangePinRegistRE',
            component: ChangePinRegistRE,
        },
        {
            path: '/mypage/myinfo/change/pin/success',
            name: 'ChangePinSuccess',
            component: ChangePinSuccess,
        },
        {
            path: '/mypage/myinfo/change/pin/restore',
            name: 'RestorePin',
            component: RestorePin,
        },
        //myinfo - card
        {
            path: '/mypage/myinfo/cards',
            name: 'CardList',
            component: CardList,
        },
        {
            path: '/mypage/myinfo/card/regist',
            name: 'RegistCard',
            component: RegistCard,
        },
        {
            path: '/mypage/myinfo/card/regist/success',
            name: 'RegistCardSuccess',
            component: RegistCardSuccess,
        },
        {
            path: '/mypage/myinfo/card/regist/fail',
            name: 'RegistCardFail',
            component: RegistCardFail,
        },
        //myinfo - leave
        {
            path: '/mypage/myinfo/leave',
            name: 'Leave',
            component: Leave,
        },
        {
            path: '/mypage/myinfo/leave/success',
            name: 'LeaveSuccess',
            component: LeaveSuccess,
        },
        /**
         * SSO path*/
        {
            path: '/sso/login',
            name: 'SsoLogin',
            component: SsoLogin,
        },
        //sso find id
        {
            path: '/sso/find/id',
            name: 'SsoFindId',
            component: SsoFindId,
        },
        {
            path: '/sso/find/id/success',
            name: 'SsoFindIdSuccess',
            component: SsoFindIdSuccess,
        },
        {
            path: '/sso/find/id/fail',
            name: 'SsoFindIdFail',
            component: SsoFindIdFail,
        },
        //sso find pw
        {
            path: '/sso/find/pw',
            name: 'SsoFindPw',
            component: SsoFindPw,
        },
        {
            path: '/sso/find/pw/noId',
            name: 'SsoFindPwNoId',
            component: SsoFindPwNoId,
        },
        {
            path: '/sso/find/pw/change',
            name: 'SsoFindPwChange',
            component: SsoFindPwChange,
        },
        {
            path: '/sso/find/pw/change/finish',
            name: 'SsoFindPwChangeFinish',
            component: SsoFindPwChangeFinish,
        },
        // 회원가입-이용약관
        {
            path: '/sso/term/agree',
            name: 'SsoTermAgree',
            component: SsoTermAgree,
            meta : {
                keepAlive: true
            }
        },
        // 회원가입-정보입력
        {
            path: '/sso/signup/step1',
            name: 'SsoSignUp01',
            component: SsoSignUp01,
            meta : {
                keepAlive: true
            }
        },
        {
            path: '/sso/signup/step2',
            name: 'SsoSignUp02',
            component: SsoSignUp02,
            meta : {
                keepAlive: true
            }
        },
        {
            path: '/sso/signup/finish',
            name: 'SsoSignUpFinish',
            component: SsoSignUpFinish,
        },
    ]
})
