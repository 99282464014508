<template>
  <div class="all-content">
    <div class="intro-wrap bg-white content-row">
      <div class="confirm-list pb-50">
        <p>
          <img class="check-pink mb-30 mt-50" src="/image/check_pink.png" alt="캐릭터">
          <em class="pink-title weight-light">핀번호가 정상적으로<br/>설정되었습니다.</em>
        </p>
        <p>
          <img src="/image/character_cheer.svg" alt="캐릭터">
        </p>
<!--        <div>-->
<!--          <button @click="bioAuth()" class="btn-m btn-pink btn-round mb-50 w-47per">생체인증</button>-->
<!--          <button @click="goNext()" class="btn-m btn-dgray btn-round mb-50 w-47per fr">다음에</button>-->
<!--        </div>-->

      </div>
    </div>
    <button class="align-bottom btn btn-m btn-pink" @click="$router.back()">확인</button>
  </div>
</template>

<script>
export default {
  name: "SignUpPinFinish",
  mounted() {
    // window.addEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
  },
  beforeDestroy() {
    // window.removeEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
  },
  methods: {
    // bioAuth() {
    //   window.requestBio()
    // },
    // bioResult(e) {
    //   let pin = e.detail
    //   if (pin.length === 6) {
    //     this.$router.replace({name: 'SignUpBioSuccess'})
    //   } else {
    //     this.$router.replace({name: 'SignUpBioFail'})
    //   }
    // },
    goNext() {
      localStorage.setItem('firstPinChecked', '1')
      this.$router.replace({name: 'Main'})
    }
  }
}
</script>

<style scoped>

</style>