<template>
  <div class="all-content security bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.push({ name: 'MyPage' })"><i class="icon-back"></i></button>
      <p>보안설정</p>
    </header>
    <div class="content-wrap">
      <section class="section bg-white">
        <div class="list-box p-20 border-bottom" @click="$router.push({ name: 'ChangePassword' })">
          <span class="fz-16 color-darkgray-4F4F4F">비밀번호 변경</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
        <div class="list-box p-20 border-bottom" @click="$router.push({ name: 'ChangePinCheck' })">
          <span class="fz-16 color-darkgray-4F4F4F">PIN 번호 변경</span>
          <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecuritySetting"
}
</script>

<style scoped>

</style>
