<template>
  <div class="all-content bg-white sms-pay">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>수기 결제</p>
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pb-80">
        <div class="charge-form pt-0">
          <div>
            <button class="br-20 btn-gray-white mr-10 p-tab-card" :class="{'btn-pink' : isPersonal}" @click="setPersonal(true)">개인카드</button>
            <button class="br-20 mr-10 btn-gray-white p-tab-card" :class="{'btn-pink' : !isPersonal}" @click="setPersonal(false)">법인카드</button>
          </div>
          <p class="mb-10 fz-16 mt-30">카드번호<span class="color-red">*</span></p>
          <div class="d-flex-row">
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'카드번호를 입력해주세요'"
                  :model="cardNo"
                  :max-length="16"
                  @update="(_model) => {this.cardNo = _model}"
                  :input-mode="'numeric'"
              />
            </div>
          </div>
          <div class="d-flex-row mt-30">
            <div class="w-47per">
              <p class="fz-16 mb-10">유효기간<span class="color-red">*</span></p>
              <div class="d-flex-row">
                <div class="select">
                  <RPInputBorder
                      :type="'text'"
                      :placeholder="'MMYY'"
                      :model="limit"
                      :max-length="4"
                      @update="(_model) => {this.limit = _model}"
                      :input-mode="'numeric'"
                  />
                </div>
              </div>
            </div>
            <div class="w-47per ml-auto">
              <p class="fz-16 mb-10">할부<span class="color-red">*</span></p>
              <div class="select-wrap">
                <button class="select" :class="{'color-gray' : !showPartPay}" @click="openSelect()">{{ selectedPay.name }}</button>
              </div>
            </div>
          </div>
          <div class="d-flex-row mt-30">
            <div class="w-100" v-if="type == 4">
              <p class="fz-16 mb-10">비밀번호<span class="color-red">*</span></p>
              <div class="d-flex-row">
                <div class="select">
                  <RPInputBorder
                      :type="'password'"
                      :placeholder="'앞 2자리'"
                      :model="pw"
                      :max-length="2"
                      @update="(_model) => {this.pw = _model}"
                      :input-mode="'numeric'"
                  />
                </div>
              </div>
            </div>
          </div>
          <p class="mb-10 fz-16 mt-30" v-if="type == 4">본인확인<span class="color-red">*</span></p>
          <div class="d-flex-row" v-if="type == 4">
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="isPersonal ? '주민번호 앞 6자리를 입력해주세요.' : '사업자번호 10자리를 입력해주세요.'"
                  :model="personalNo"
                  :max-length="isPersonal ? 6 : 10"
                  @update="(_model) => {this.personalNo = _model}"
                  :input-mode="'numeric'"
              />
            </div>
          </div>
          <p class="mt-5" v-if="type == 4">
            <span class="color-gray">
              *개인카드 : 주민번호 앞 6자리
              <br/>
              *법인카드 : 사업자번호 10자리
            </span>
          </p>
        </div>
      </div>
      <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="goNext">결제</button>
    </div>
    <installment-period-select
        v-show="showSelect"
        :periods="payList"
        @selected="setSelectedPay"
        @close="showSelect=false"/>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import InstallmentPeriodSelect from "@/components/payment/rentPayment/InstallmentPeriodSelect";

export default {
  name: "HandPaymentCardInfo",
  components: {
    RPInputBorder,
    InstallmentPeriodSelect
  },
  data() {
    return {
      isPersonal: true,
      cardNo: '',
      limit: '',
      pw: '',
      personalNo: '',

      itemName:'',
      payAmt: '',
      phone:'',
      isTax: '0',
      type: '',
      //할부관련:
      showPartPay: false,
      showSelect: false,
      payList: [
        {
          'installment': '00',
          'name': '일시불'
        },
        {
          'installment': '02',
          'name': '2개월'
        },
        {
          'installment': '03',
          'name': '3개월'
        },
        {
          'installment': '04',
          'name': '4개월'
        },
        {
          'installment': '05',
          'name': '5개월'
        },
        {
          'installment': '06',
          'name': '6개월'
        },
        {
          'installment': '07',
          'name': '7개월'
        },
        {
          'installment': '08',
          'name': '8개월'
        },
        {
          'installment': '09',
          'name': '9개월'
        },
        {
          'installment': '10',
          'name': '10개월'
        },
        {
          'installment': '11',
          'name': '11개월'
        },
        {
          'installment': '12',
          'name': '12개월'
        },
      ],
      selectedPay: {
        'installment': '00',
        'name': '일시불'
      },
      //:할부관련
    }
  },
  mounted() {
    this.itemName = this.$route.params.itemName
    this.payAmt = this.$route.params.payAmt
    this.showPartPay = Number(this.payAmt) >= 50000
    this.phone = this.$route.params.phone
    this.isTax = this.$route.params.isTax
    this.type = this.$route.params.type
  },
  methods: {
    setPersonal(_personal){
      this.isPersonal = _personal
      this.changeNextBtn()
    },
    goNext() {
      if (this.cardNo === '') {
        this.notify('error', '카드번호를 입력해주세요.')
        return
      }
      if (this.cardNo.length < 15) {
        this.notify('error', '카드번호를 정확하게 입력해주세요.')
        return
      }
      if (this.limit === '') {
        this.notify('error', '카드 유효기간을 입력해주세요.')
        return
      }
      if (this.limit.length !== 4) {
        this.notify('error', '카드 유효기간을 정확하게 입력해주세요.')
        return
      }
      if (this.pw === '' && this.type == 4) {
        this.notify('error', '비밀번호 앞 2자리를 입력해주세요.')
        return
      }
      if (this.pw.length !== 2 && this.type == 4) {
        this.notify('error', '비밀번호 앞 2자리를 정확하게 입력해주세요.')
        return
      }
      if (this.personalNo === '' && this.type == 4) {
        let message = this.isPersonal ? '주민등록번호 앞 6자리를 입력해주세요.' : '사업자번호 10자리를 입력해주세요.'
        this.notify('error', message)
        return
      }
      if (this.isPersonal && this.personalNo.length !== 6 && this.type == 4) {
        this.notify('error', '주민등록번호 앞 6자리를 정확하게 입력해주세요.')
        return
      }
      if (!this.isPersonal && this.personalNo.length !== 10 && this.type == 4) {
        this.notify('error', '사업자번호 10자리를 정확하게 입력해주세요.')
        return
      }

      let formData = new FormData()

      formData.append('goodsName', this.itemName)
      formData.append('amount', this.payAmt)
      formData.append('taxType', this.isTax)
      formData.append('clientContact', this.phone)
      formData.append('cardNumber', this.cardNo)
      formData.append('cardType', this.isPersonal ? '0' : '1')
      formData.append('expireMm', this.limit.substr(0,2))
      formData.append('expireYy', this.limit.substr(2,2))
      formData.append('installment', this.selectedPay.installment)
      if(this.type == 4) {
        formData.append('cardPassword', this.pw)
        formData.append('identification', this.personalNo)
      }


      this.$post(this.$PAYMENT_HANDWRITTEN, 'HandPaymentCardInfo_gonext', formData, true,(result) => {
        this.$router.replace({ name: 'HandPaymentSuccess', params : { payAmount:this.payAmt, cardNo:this.cardNo, paymentInfo: JSON.stringify(result.data) }})
      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({ name: 'HandPaymentFail', params : { payAmount:this.payAmt, cardNo:this.cardNo, paymentInfo: JSON.stringify(result.data) }})
      })
    },

    //할부관련:
    openSelect() {
      if (this.showPartPay) {
        this.showSelect = true
      }
    },
    setSelectedPay(pay) {
      this.selectedPay = pay
      this.showSelect = false
    },
    //:할부관련

    changeNextBtn() {
      if (this.cardNo === '' || this.cardNo.length < 15 || this.limit === '' || this.limit.length !== 4 || this.pw === '' || this.pw.length !== 2 || this.personalNo === '' ||
          (this.isPersonal && this.personalNo.length !== 6) || (!this.isPersonal && this.personalNo.length !== 10)){
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      } else {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-pink"
      }
    },
  },
  watch: {
    cardNo(){
      this.changeNextBtn()
    },
    limit(){
      this.changeNextBtn()
    },
    pw(){
      this.changeNextBtn()
    },
    personalNo(){
      this.changeNextBtn()
    },
  }
}
</script>

<style scoped>

.p-tab-card {
  padding: 6px 10px;
}

</style>
