<template>
  <div class="all-content sms-pay-success">
    <div class="content-wrap bg-white content-row t-0" style="height: calc(100% - 52px);">
      <div class="confirm-list regist-card-success">
        <p class="jc-f-end">
          <img src="/image/character_happy.svg" alt="캐릭터">
        </p>
        <p class="comment" v-if="isReservation">
          <em class="t-c black-title">총 {{ count }}건의 <span class="pink-title">카톡 발송 예약이</span><br/> 완료되었습니다.</em>
        </p>
        <p class="comment" v-else>
          <em class="t-c black-title">총 {{ count }}건의 <span class="pink-title">카톡 발송이</span><br/> 완료되었습니다.</em>
        </p>
        <div class="w-90" v-if="isReservation">
          <p class="info" v-if="goodsName !== ''">
            <span class="title">상품명</span>
            <span class="cardInfo">{{ goodsName }}</span>
          </p>
          <p class="info mt-10">
            <span class="title">결제금액</span>
            <span class="cardInfo">{{ amount | numberWithCommas }} 원</span>
          </p>
          <p class="info mt-10">
            <span class="title">예약발송일</span>
            <span class="cardInfo">{{ date }}</span>
          </p>
        </div>
        <div class="w-90" v-else>
          <p class="info">
            <span class="title">고객명</span>
            <span class="cardInfo">{{ customer }}</span>
          </p>
          <p class="info mt-10">
            <span class="title">상품명</span>
            <span class="cardInfo">{{ goodsName }}</span>
          </p>
          <p class="info mt-10">
            <span class="title">결제금액</span>
            <span class="cardInfo">{{ amount | numberWithCommas }} 원</span>
          </p>
          <p class="info mt-10">
            <span class="title">발송일</span>
            <span class="cardInfo">즉시발송</span>
          </p>
          <div v-if="failList.length > 0">
            <p class="info mt-10" v-for="(index, contact) in failList" :key="index">
              <span class="title">발송실패 연락처</span>
              <span class="cardInfo">{{ contact }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <button class="btn-pink mr-auto btn-m" @click="$router.push({ name: 'Main' })">확 인</button>
  </div>
</template>

<script>
export default {
  name: "SMSPaymentSuccess",
  data() {
    return {
      isReservation: false,
      count: 0,
      goodsName: '',
      amount: '',
      customer: '',
      date: '',
      failList: [],
    }
  },
  mounted() {
    this.isReservation = this.$route.params.isReservation
    this.count = this.$route.params.count
    this.goodsName = this.$route.params.goodsName
    this.amount = this.$route.params.amount
    this.customer = this.$route.params.customer
    this.date = this.$route.params.date
    this.failList = this.$route.params.failList
  }
}
</script>

<style scoped>

</style>
