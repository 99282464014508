<template>
  <div class="all-content">
    <header class="header-wrap border-bottom">
      <p>알림</p>
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
    </header>
    <div class="content-wrap bg-white">
      <div class="list-none-wrap" v-if="items.length === 0">
        <div class="list-none">
          <p><img src="/image/character_hat_worry_opacity.svg" alt="캐릭터"></p>
          <p class="list-none-title">등록된 알림이 없습니다.</p>
        </div>
      </div>
      <div class="new-alram-list" v-else>
        <ul>
          <li v-for="(item, index) in items" :key="index"
              :class="{ 'border-bottom-eaeaea' : index < items.length - 1 }">
            <a @click="goDetailPage(index, item.state)">
              <span class="notification">
                  <img class="thmb" :src="item.icon" alt="공지">
              </span>
              <div class="d-flex-row">
                <p>{{ item.type }}</p>
                <p class="ml-auto pr-0 p-repay" v-if="item.monthlyIdx !== null"
                   @click="$router.push({ name: 'NotificationRentRepay', params: { idx: item.monthlyIdx }})">재결제하기</p>
              </div>
              <p v-if="item.message.substring(0,2) === '정기'" class="mr-auto w-80 pr-0" style="word-break: keep-all;"><strong style="color: #5064AF;">{{ item.message.substring(0,2) }}</strong>{{ item.message.substring(2, item.message.length - 1) }}</p>
              <p v-else-if="item.message.substring(0,8) === '[카톡예약결제]'" class="mr-auto w-80 pr-0" style="word-break: keep-all;"><strong>{{ item.message.substring(0,8) }}</strong>{{ item.message.substring(8, item.message.length - 1) }}</p>
              <p v-else class="mr-auto w-80 pr-0" style="word-break: keep-all;">{{ item.message }}</p>
              <em class="mb-20">{{ item.date }}</em>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationList",
  data() {
    return {
      items: [],
      // idx, monthlyIdx(재결제 idx), type, message, date, icon, linkIdx, linkType(이동화면)
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$get(this.$NOTIFICATION, 'notification_getlist', (result) => {
        this.items = result.data
        console.log(this.items)

      }, (result) => {
        this.httpError(result.data)
      })

    },
    goDetailPage(_index, _state) {
      let linkType = this.items[_index].linkType
      let linkIdx = this.items[_index].linkIdx

      if (linkType !== null && linkType !== '') {
        let pageName = this.getLink(linkType)

        if (linkType === '0') { //배달비 상세
          this.$router.push({name: pageName, params: {quickIdx: linkIdx}})
        } else if (linkType === '1') {  //월세 상세
          this.$router.push({name: 'RentPaymentHistory', params: {state: _state}})
        } else if (linkType === '2') {  //결제 상세
          this.$router.push({name: 'RentPaymentHistory', params: {state: _state}})
        } else if (linkType === '3') {  // 공지사항
          this.$router.push({name: pageName})
        } else if (linkType === '4') {  // qna
          this.$router.push({name: pageName, params: {idx: linkIdx}})
        } else if (linkType === '5') {  // 배대사 목록
            this.$router.push({name: pageName})
        }
      }
    },
    getLink(value) {
      if (value === '0') { // 배달비(상세)
        return 'ChargePaymentHistoryDetail'
      } else if (value === '1') {  // 월세(상세)
        return 'ChargeSalesHistoryDetail'
      } else if (value === '2') {  // 결제(상세)
        return 'PaymentHistoryDetail'
      } else if (value === '3') {  // 공지사항
        return 'NoticeList'
      } else if (value === '4') {  // QNA
        return 'QnaDetail'
      } else if (value === '5') {  // 배대사 목록
          return 'DeliveryAgencyList'
      }
    }
  },
}
</script>

<style scoped>

</style>
