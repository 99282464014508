<template>
  <div class="all-content mypage">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.push({ name: 'Main' })"><i class="icon-back"></i></button>
    </header>
    <div class="content-wrap bg-white">
      <div>
        <section class="section bg-white">
          <div class="mypage-title bg-white">
            <div class="profile-con mr-20">
              <img class="profile-mypage" id="user_img" :src="profile.path ? profile.path : '/image/user.svg'"
                   alt="사용자 사진"/>
              <div>
                <input type="file" class="custom-input" @change="upload($event)" accept="image/*">
                <img src="/image/pen.svg"/>
              </div>
            </div>
            <div class="profile-desc">
              <span class="nickname">{{ profile.businessName }}</span>
              <p @click="showSelectAccount"><span class="fz-20">{{ profile.account }}</span><i
                  class="icon-arrow-down"></i></p>
              <span class="fz-13 color-gray mt-10">{{ profile.quote }}</span>
            </div>
          </div>
        </section>
        <div class="list-box p-20 pt-25 bg-white">
          <div class="go-statis w-100 align-f radios br-10" @click="goReapPay">
            <div>
              <p class="fz-13 color-darkgray-4F4F4F">다양한 결제수단, 매출 관리와 정산</p>
              <p><span class="fz-16 lh-30">페이허브</span><i
                  class="icon-arrow-right fz-13 pl-25 color-darkgray-4F4F4F fwb"></i></p>
            </div>
            <img src="/image/graph.png" alt="그래프"/>
          </div>
        </div>
        <section class="section pt-20 pb-20 bg-white">
          <div class="mypage-title align-f">
            <div class="img-menu" @click="$router.push({ name: 'MyInfo' })">
              <img src="/image/subMenu1.svg" alt="사용자"/>
              <p class="mt-10">회원정보</p>
            </div>
            <div class="img-menu" @click="$router.push({ name: 'DeliveryAgencyList' })">
              <img src="/image/subMenu2.svg" alt="사용자"/>
              <p class="mt-10">배대사</p>
            </div>
            <div class="img-menu" @click="$router.push({ name: 'SalesAlert' })">
              <img src="/image/subMenu3.svg" alt="사용자"/>
              <p class="mt-10">매출알림톡</p>
            </div>
            <div class="img-menu" @click="$router.push({ name: 'CardList' })">
              <img src="/image/subMenu4.svg" alt="사용자"/>
              <p class="mt-10">신용카드</p>
            </div>
          </div>
        </section>
        <div class="bar-10 ir_so">bar</div>
        <section class="section bg-white">
          <div>
            <div class="list-box p-20 border-bottom" @click="$router.push({ name: 'NoticeList' })">
              <span class="fz-15 color-darkgray">공지사항</span>
              <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
            </div>
            <div class="list-box p-20 border-bottom" @click="$router.push({ name: 'SecuritySetting' })">
              <span class="fz-15 color-darkgray">보안설정</span>
              <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
            </div>
            <div class="list-box p-20 border-bottom" @click="$router.push({ name: 'NotificationSetting' })">
              <span class="fz-15 color-darkgray">알림설정</span>
              <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
            </div>
            <div class="list-box p-20 border-bottom" @click="$router.push({ name: 'FaqList' })">
              <span class="fz-15 color-darkgray">FAQ</span>
              <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
            </div>
            <div class="list-box p-20 border-bottom" @click="$router.push({ name: 'QnaList' })">
              <span class="fz-15 color-darkgray">1:1 문의</span>
              <span class="btn-arrow-right"><i class="icon-arrow-right"></i></span>
            </div>
            <div class="list-box p-20 border-bottom" @click="goLogout()">
              <span class="fz-16 color-darkgray">로그아웃</span>
            </div>
            <!-- 20211123 탈퇴기능 막음 -->
            <!--            <div class="list-box p-20 border-bottom" @click="$router.push({ name: 'Leave' })">-->
            <!--              <span class="fz-16 color-darkgray">회원탈퇴</span>-->
            <!--            </div>-->
          </div>
        </section>
      </div>
      <footer class="list-box p-20 d-flex t-c section bg-white">
        <p>070-7591-8896</p>
        <p>Copyright © 2021 REAP PAY Inc.</p>
      </footer>
    </div>
    <account-select
        ref="childSelectCmp"
        v-show="showAccounts"
        :accountList="accountList"
        :currentAccount="currentAccount"
        @getUserInfo="getUserInfo"
        @close="showAccounts = false"
    />

  </div>
</template>

<script>
import AccountSelect from "@/components/mypage/AccountSelect"

export default {
  name: "MyPage",

  components: {AccountSelect},

  data() {
    return {
      profile: {
        account: '',
        businessName: '',
        path: '',
        quote: ''
      },
      isPay: true,
      version: '1.0.0',
      isPending: false,
      showAccounts: false,
      accountList: [],
      currentAccount: '',
    }
  },

  mounted() {
    this.getUserInfo()
  },

  methods: {
    goReapPay() {
      window.open('https://payhub.co.kr/')
    },

    getUserInfo() {
      this.$get(this.$USER, 'created_get_user', (result) => {
        this.profile = result.data

        this.currentAccount = this.profile.account
      }, () => {
        // console.log(result)
      })
    },

    upload(e) {
      let file = e.target.files
      if (!file.length) {
        return
      }

      let profileImage = URL.createObjectURL(file[0])
      let output = document.getElementById('user_img')
      let userImg = profileImage
      output.onload = function () {
        URL.revokeObjectURL(userImg)
      }

      let formData = new FormData()
      formData.append('image', file[0])

      this.$post(this.$USER_PROFILE_IMAGE, 'myPageInfoPutImg', formData, true, () => {
        this.getUserInfo()
      }, (result) => {
        this.httpError(result.data)
      })
    },

    goLogout() {
      this.$root.$refs.Confirm.showDialog('', '로그아웃 하시겠습니까?', '확인', '취소', () => {
        this.$get(this.$AUTH_LOGOUT, 'myinfo_checkid', () => {
          sessionStorage.removeItem('accessToken')
          sessionStorage.removeItem('refreshToken')
          localStorage.removeItem('passToken')
          localStorage.removeItem('id')
          // localStorage.removeItem('useLocalBio')
          this.$router.replace({name: 'BeforeLogin'})
          this.$parent.$data.readyMain = false
        }, (result) => {
          this.httpError(result.data)
        })
      }, () => {
      })
    },

    showSelectAccount() {
      this.$refs.childSelectCmp.reset()

      this.$get(this.$USER_ANOTHER, 'select_user_another', (result) => {
        this.accountList = result.data

      }, () => {
        // console.log(result)
      })
      this.showAccounts = true
    },

    // changeEventAlarm() {
    //   if (this.isPending) {
    //     return
    //   }
    //   this.isPending = true
    //
    //   let formData = new FormData()
    //   formData.append('type', '1')
    //
    //   this.$put(this.$USER_CHANGE_ALARM, 'refreshPush', formData, () => {
    //     this.profile.eventAlarm = !this.profile.eventAlarm
    //     this.profile.eventAlarm ? window.subscribedTopic(process.env.VUE_APP_TOPIC_EVENT) : window.unsubscribedTopic(process.env.VUE_APP_TOPIC_EVENT)
    //
    //     this.isPending = false
    //   }, () => {
    //     this.notify('error', '푸시 서비스 등록에 문제가 발생했습니다.')
    //     this.isPending = false
    //   })
    // },
    // changeNoticeAlarm() {
    //   if (this.isPending) {
    //     return
    //   }
    //   this.isPending = true
    //
    //   let formData = new FormData()
    //   formData.append('type', '0')
    //
    //   this.$put(this.$USER_CHANGE_ALARM, 'refreshPush', formData, () => {
    //     this.profile.noticeAlarm = !this.profile.noticeAlarm
    //     this.profile.noticeAlarm ? window.subscribedTopic(process.env.VUE_APP_TOPIC_NOTICE) : window.unsubscribedTopic(process.env.VUE_APP_TOPIC_NOTICE)
    //
    //     this.isPending = false
    //   }, () => {
    //     this.notify('error', '푸시 서비스 등록에 문제가 발생했습니다.')
    //     this.isPending = false
    //   })
    // }
  }
}
</script>

<style scoped>

</style>
