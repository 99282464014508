<template>
  <div class="all-content">
    <div class="bg-white content-row-new">
      <div class="confirm-list regist-card-fail">
        <p>
          <img src="/image/character_hat_sad.svg" alt="캐릭터">
          <span class="mt-20 color-black">신용카드 등록에<br>실패하였습니다.</span>
        </p>
<!--        <div class="w-90">-->
<!--          <p class="info">-->
<!--            <span class="title">카드번호</span>-->
<!--            <span class="cardInfo">삼성카드 2343****2423</span>-->
<!--&lt;!&ndash;            <span class="cardInfo">{{ cardInfo.cardData }}</span>&ndash;&gt;-->
<!--          </p>-->
<!--          <p class="info mt-10">-->
<!--            <span class="title">등록일시</span>-->
<!--            <span class="cardInfo">{{ new Date() | moment('YY.MM.DD HH:mm:ss') }}</span>-->
<!--&lt;!&ndash;            <span class="cardInfo">{{ date }}</span>&ndash;&gt;-->
<!--          </p>-->
<!--        </div>-->
      </div>
    </div>
    <div class="d-flex-row">
      <button class="btn-dgray-4D4D4D ml-auto btn-half" @click="$router.replace({name:'RegistCard'})">다시 등록</button>
      <button class="btn-pink mr-auto btn-half" @click="$router.back()">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistCardFail",

  // data() {
  //   return {
  //     cardInfo: this.$route.params.cardInfo,
  //     date: '',
  //   }
  // },
  //
  // created() {
  //   let createdAt = this.cardInfo.createdAt.split('T')
  //   let date = createdAt[0].replace('-', '.')
  //   let time = createdAt[1].split('.')[0]
  //   this.date = date + ' ' + time
  //
  // }
}
</script>

<style scoped>

</style>
