<template>
  <div class="all-content bg-white restore-pin">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>PIN 번호 분실</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap">
        <div class="charge-form">
          <p class="mb-10">비밀번호</p>
          <div class="select mt-10">
            <RPInputBorder
                :type="'password'"
                :placeholder="'비밀번호를 입력해주세요'"
                :model="password"
                :max-length="20"
                @update="(_model) => {this.password=_model}"
            />
          </div>
        </div>
      </div>
      <button class="align-bottom btn btn-m btn-pink" @click="checkPassword()">확인</button>
    </div>
  </div>
</template>

<script>

import RPInputBorder from "@/components/common/RPInputBorder";

export default {
  name: "RestorePin",

  data() {
    return {
      password: '',
    }
  },
  components: {
    RPInputBorder,
  },
  mounted() {
  },
  methods: {
    checkPassword() {
      if (this.password !== '') {
        let formData = new FormData()
        formData.append('password', this.password)

        this.$post(this.$USER_CHECKPASSWORD, 'restorepin_checkpassword', formData, true, () => {
          this.$router.replace({name:'ChangePinRegist',params:{old_pin:'', type: '1'}})
        }, (result) => {
          this.httpError(result.data)
        })

      } else {
        this.notify('error', '비밀번호를 입력해주세요')
      }
    },
  }
}
</script>

<style scoped>

</style>
