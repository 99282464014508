<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <p>다른 계정 추가</p>
      <button type="button" class="btn-close" @click="$router.back()"><i class="icon-close"></i></button>
    </header>
    <div class="content-wrap">
      <div class="main-wrap pb-10">
        <div class="charge-form pt-0">
          <p class="mb-10 fz-16">계정</p>
          <div class="mt-10 select px-20">
            <RPInputBorder
                :type="'text'"
                :placeholder="'아이디를 입력해주세요'"
                :model="id"
                :input-mode="'text'"
                @update="(_model) => {this.id=_model}"
            />
          </div>
          <span class="color-red"></span>
          <p class="mb-10 mt-30 fz-16">비밀번호</p>
          <div class="mt-10 select px-20">
            <RPInputBorder
                :type="'password'"
                :placeholder="'비밀번호를 입력해주세요'"
                :model="password"
                :input-mode="'text'"
                :max-length="20"
                @update="(_model) => {this.password=_model}"
            />
          </div>
        </div>
      </div>
      <div class="mt-10 d-flex-row pr-20 pl-20">
        <button class="ml-0 mr-5 color-darkgray-4F4F4F fz-13" @click="$router.push({ name: 'FindId' })">아이디 찾기 </button>
        <button class="mr-auto ml-5 color-darkgray-4F4F4F fz-13" @click="$router.push({ name: 'FindPw' })">비밀번호 찾기</button>
        <button class="color-darkgray-4F4F4F fz-13" @click="$router.push({ name: 'TermAgree' })">회원가입</button>
      </div>
      <button class="align-bottom btn btn-m btn-pink" @click="add">추 가</button>
    </div>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";

export default {
  name: "AddLoginAccount",

  components: {
    RPInputBorder
  },

  data() {
    return {
      chargeAmount: '',
      id: '',
      password: '',
    }
  },

  methods: {
    add() {
      if (this.id === '') {
        this.notify('error', '아이디를 정확하게 입력해주세요.')
        return
      }
      if (this.password === '') {
        this.notify('error', '비밀번호를 정확하게 입력해주세요.')
        return
      }
      let formData = new FormData()
      formData.append('id', this.id.trim())
      formData.append('password', this.password.trim())
    },

  }


}
</script>

<style scoped>

</style>
