<template>
  <div class="myinfo-menu">
    <header class="header-wrap">
      <button type="button" class="btn-close" @click="$router.push({ name: 'MyPage' })"><i class="icon-close"></i></button>
    </header>
    <div class="pay-tab" id="pay-tab">
      <router-link id="tab0" :to="{ name: 'MyInfo' }" class="fz-16 mb-10">회원정보</router-link>
      <router-link id="tab1" :to="{ name: 'DeliveryAgencyList' }" class="fz-16 mb-10">배대사</router-link>
      <router-link id="tab2" :to="{ name: 'SalesAlert' }" class="fz-16 mb-10">매출알림톡</router-link>
      <router-link id="tab3" :to="{ name: 'CardList' }" class="fz-16 mb-10">신용카드</router-link>
    </div>
  </div>

</template>

<script>
export default {
  name: "MenuCmp",

  methods: {
    focusTab(tabNo) {
      window.document.getElementById("tab" + tabNo).className = "fz-16 mb-10 active"
    }
  }
}
</script>

<style scoped>

</style>
