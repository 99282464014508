<template>
  <div class="all-content overflow-x-hidden">
    <header class="history-header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <button v-if="paymentStatus === '1'" type="button" class="btn-dot" @click="showSelectChargeSalesType">
        <img src="/image/icon_dotBtn.svg" alt="상세"/>
      </button>
    </header>
    <div v-if="items.main" class="content-wrap bg-white">
      <section class="top-box2 bg-white">

        <!-- 심사반려 상단 -->
        <div v-if="paymentStatus === '2'" class="d-flex t-e">
          <button class="ml-auto paying-reject-btn">
            심사반려
          </button>
        </div>
        <!-- /심사반려 상단 -->

        <h5 class="top-box-tit">{{ items.main.name }}</h5>
        <div class="d-flex-row mt-5 jc-between">
          <span class="color-darkgray mb-15 tz-15">{{ items.main.address }}</span>
        </div>
        <div class="mt-10">
          <span class="color-darkgray-4F4F4F fz-28 weight-bold">{{ items.main.total | numberWithCommas }} 원</span>
          <span class="color-darkgray ml-5 tz-14">(수수료 포함)</span>
        </div>
      </section> <!--/top-box2 -->

      <div class="bar-10 ir_so">bar</div>
      <section class="histroy-box">
        <div class="top-box2">
          <div class="d-flex-row mt-10 mb-20 jc-between">
            <span class="color-darkgray fz-17">{{ type | lessorTitleFilter }}</span>
            <span class="color-darkgray color-darkgray-4F4F4F fz-17">{{ items.main.lessor }}</span>
          </div>
          <div class="d-flex-row mb-20 jc-between">
            <span class="color-darkgray fz-17">은행이름</span>
            <span class="color-darkgray-4F4F4F fz-17">{{ items.main.bankName }}</span>
          </div>
          <div class="d-flex-row border-bottom jc-between">
            <span class="color-darkgray fz-17 mb-25">계좌번호</span>
            <span class="color-darkgray-4F4F4F fz-17">{{ items.main.lessorAccount }}</span>
          </div>
          <div class="d-flex-row jc-between mt-25">
            <span class="color-darkgray fz-17">입금자명</span>
            <span class="color-darkgray-4F4F4F fz-17 mb-25">{{ items.main.depositor }}</span>
          </div>

          <!-- 심사반려, 납입실패 하단 시작-->
          <div v-if="paymentStatus === '2' || paymentStatus === '3'" class="border-top">
            <div class="d-flex-row jc-between mt-20">
              <span class="color-darkgray mb-20 fz-17">납입금액</span>
              <span class="color-darkgray-4F4F4F fz-17 fwb">{{ items.main.amount | numberWithCommas }}원</span>
            </div>
            <div class="d-flex-row jc-between">
              <span class="color-darkgray mb-20 fz-17">수수료({{ items.main.feePercent }}%)</span>
              <span class="color-darkgray-4F4F4F fz-17">{{ items.main.fee | numberWithCommas }}원</span>
            </div>
          </div>
          <!-- /심사반려, 납입실패 하단 하단 -->

          <!-- 납입중단, 납입완료 하단 시작-->
          <div v-else class="border-top">
            <div class="d-flex-row jc-between mt-25">
              <span class="color-darkgray mb-20 fz-17">납입금액</span>
              <span class="color-darkgray-4F4F4F fz-17 fwb">{{ items.main.amount | numberWithCommas }}원</span>
            </div>
            <div class="d-flex-row jc-between">
              <span class="color-darkgray mb-20 fz-17">수수료({{ items.main.feePercent }}%)</span>
              <span class="color-darkgray-4F4F4F fz-17">{{ items.main.fee | numberWithCommas }}원</span>
            </div>
          </div>
          <!-- /납입중단, 납입완료 하단 -->
        </div>
      </section> <!-- /histroy-box -->

      <div v-if="isShowChargeHistory">
        <div v-if="items.sub != null && items.sub.data != null && items.sub.data.length > 0">
          <section v-for="(item, index) in items.sub.data" :key="index" class="p-payment-history">
            <!-- v-for 들어갈 부분 -->
            <div class="white-box-br-10">
              <div class="p-15">
                <div class="d-flex-row mb-10 jc-between">
                  <span class="color-darkgray fz-16">결제금액</span>
                  <span class="fz-17 color-darkgray-4F4F4F">{{ item.total | numberWithCommas }}원</span>
                </div>
                <div class="d-flex-row jc-between">
                  <span class="color-darkgray fz-16">납입일</span>
                  <span class="fz-15 color-darkgray-4F4F4F">{{ item.paymentDate | moment('YYYY년 M월 D일') }}</span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div v-else>
          <section class="p-payment-history">
            <div class="white-box-br-10">
              <div class="p-15">
                <div class="d-flex-row jc-center">
                  <span class="color-darkgray fz-16">확인할 결제 내역이 없습니다.</span>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <!-- 결제내역 확인 / 납입중단, 납입완료일 때 활성화 -->

      <button v-if="paymentStatus === '4' || paymentStatus === '5'" class="btn btn-m history-btn-light-gray fz-18 color"
              style="color: #3B3B3B;" @click="showChargeHistory">
        결제내역 확인
        <i v-if="!isShowChargeHistory" class="icon-arrow-down ml-5"></i>
        <i v-else class="icon-arrow-up ml-5"></i>
      </button>


      <!-- 재결제 / 납입완료일 때 활성화 -->
      <button v-if="paymentStatus === '5'" @click="monthlyRepay" class="btn btn-m history-btn-dark-gray-4F4F4F fz-22">
        재결제
      </button>

      <!-- 삭제, 재심사 / 심사반려일 때 활성화 -->
      <div v-if="paymentStatus === '2'" class="d-flex-row btn_bottom">
        <button @click="monthlyDelete" class="btn-gray-4F4F4F mr-auto btn-half fz-22">삭제</button>
        <button @click="monthlyReexamination" class="btn-pink ml-auto btn-half fz-22">재심사</button>
      </div>

      <charge-sales-type-select
          v-show="showChargeSalesType"
          :conditions="typeList"
          @selectedType="setSelectedType"
          @close="showChargeSalesType = false"
      />

    </div> <!-- /content-wrap -->
  </div> <!-- /all-content -->
</template>

<script>
import ChargeSalesTypeSelect from "./ChargeSalesTypeSelect";

export default {
  name: "ChargeSalesHistoryDetail",
  components: {ChargeSalesTypeSelect},
  data() {
    return {
      isShowChargeHistory: false,
      paymentStatus: '',
      // 0 : 심사중, 1 : 심사 통과, 2 : 심사 반려, 3 : 실패, 4 : 중단(정기), 5 : 완료
      // type: '', // 0:월세, 1:관리비, 2:보증금, 3:수리비, 5:기타, 6은 전체(프론트 임의)
      type: '', // 0:월세, 1:관리비, 2:보증금, 3:수리비, 5:기타, '': 전체
      monthlyPaymentIdx: '',
      items: '',
      size: 999,
      page: 999,

      typeList: [
        {
          name: '월세명 변경',
        },
        {
          name: '수정(재심사)',
        },
        {
          name: '중단',
        },
        // {
        //   name: '공유',
        // },
      ],
      showChargeSalesType: false,
    }
  },
  methods: {
    showChargeHistory() {
      this.isShowChargeHistory = !this.isShowChargeHistory
    },
    showSelectChargeSalesType() {
      this.showChargeSalesType = true
    },
    setSelectedType(_type) {
      this.showChargeSalesType = false
      // if (_type.name === '월세명 변경') {
      if (_type.name.includes('명 변경')){
        this.$router.replace({
          name: 'ChangeRentName',
          params: {monthlyPaymentIdx: this.items.main.idx, oldRentName: this.items.main.name, type: this.type}
        })
      } else if (_type.name === '수정(재심사)') {
        this.monthlyReexamination()
      } else if (_type.name === '중단') {
        this.$router.replace({
          name: 'StopPaying',
          params: {
            monthlyPaymentIdx: this.items.main.idx,
            oldRentName: this.items.main.name,
            type: this.type,
            paymentType: this.items.main.paymentType
          }
        })
      } else {
        console.log(_type.name)
      }
    },

    getMonthlyDetail() {
      let params = new URLSearchParams()
      params.append('page', this.page)
      params.append('size', this.size)

      this.$get(this.$LOG_MONTHLY_DETAIL + this.monthlyPaymentIdx + '?' + params.toString(), this.$options.name + '_getMonthlyDetail', (result) => {
        this.items = result.data
        this.paymentStatus = result.data.main.type

        let groupType = result.data.main.groupType
        let typeName = '월세'
        if (groupType === '1') {
          typeName = '관리비'
        } else if (groupType === '2') {
          typeName = '보증금'
        } else if (groupType === '3') {
          typeName = '수리비'
        } else if (groupType === '5') {
          typeName = '기타'
        }
        this.typeList[0].name = typeName + '명 변경'
        console.log(result.data)
      }, (result) => {
        this.httpError(result.data)
      })
    },

    monthlyDelete() { // 삭제
      this.$put(this.$MONTHLY_DELETE + '_' + this.monthlyPaymentIdx, this.$options.name + '_monthlyDelete', null, true, () => {
        this.notify('success', '성공적으로 삭제되었습니다.', false)
        this.$router.replace({name: 'RentPaymentHistory'})

      }, (result) => {
        this.httpError(result.data)
      })
    },

    monthlyReexamination() { // 수정,재심사
      console.log(this.paymentStatus)
      this.$router.replace({
        name: 'RentPayment', params: {
          idx: this.monthlyPaymentIdx.toString(),
          groupType: this.items.main.groupType,  // 0:월세, 1:관리비, 2:보증금, 3:수리비, 4:교육비, 5:기타
          type: 1,
          paymentType: this.items.main.paymentType,
          depositor: this.items.main.depositor,
          name: this.items.main.name,
          amount: this.items.main.amount,
          amountNoFee: this.items.main.amount,
          randomVal: Math.random().toString(),
        }
      })
    },

    monthlyRepay() { // 재결제
      this.$get(this.$MONTHLY_REPAY + '/' + this.monthlyPaymentIdx, this.$options.name + '_monthlyRepay', (result) => {
        this.$router.replace({
          name: 'ChargeSalesMonthlyRepay', params: {
            repayData: result.data,
            //type : 1=수정, 2=재심사, 3=재결제
            type: 3,
            groupType: this.type,
          }
        })
      }, (result) => {
        this.httpError(result.data)
      })
    },

  },
  mounted() {
    this.monthlyPaymentIdx = this.$route.params.monthlyPaymentIdx
    // this.type = this.$route.params.type

    this.getMonthlyDetail()
  },
  filters: {
    stateFilter: (value) => {
      if (value === '0') {
        return '심사중'
      } else if (value === '1') {
        return '납입중'
      } else if (value === '2') {
        return '심사반려'
      } else if (value === '3') {
        return '납입실패'
      } else if (value === '4') {
        return '납입중단'
      } else if (value === '5') {
        return '납입완료'
      } else if (value === '6') {
        return '결제대기'
      }
    },
    //0:월세, 1:관리비, 2:보증금, 3:수리비, 4:교육비, 5:기타
    lessorTitleFilter: (value) => {
      if (value === '3' || value === '5') {  // 수리비, 기타
        return '예금주'
      }
      return '임대인명(예금주)'
    },
  },
}
</script>

<style scoped>

.p-payment-history {
  padding: 18px 20px;
}
.btn_bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.top-box2 {
  padding: 15px 20px 70px 20px;
}
</style>
