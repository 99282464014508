import axios from 'axios';
import https from "https";

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('accessToken'),
        },
        timeout: 60000
    }
}
let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze({
    install(Vue) {
        if (process.env.VUE_APP_DEBUG === '1') {
            const httpsAgent = new https.Agent({ rejectUnauthorized: false });
            axios.defaults.httpsAgent = httpsAgent
        }

        Vue.prototype.$DOMAIN = process.env.VUE_APP_DOMAIN + "/api/v1"

        Vue.prototype.$TERMS = "/terms" // 약관종류
        Vue.prototype.$TERMS_CONTENT = "/terms/content" // 약관내용
        Vue.prototype.$AUTH_SIGNUP_STEP01 = "/auth/signUpStep1" // 회원가입(step1)
        Vue.prototype.$AUTH_SIGNUP_STEP02 = "/auth/signUpStep2" // 회원가입(step2)
        Vue.prototype.$AUTH_SIGNUP = "/auth/signUp" // 회원가입 처리
        Vue.prototype.$AUTH_DUPLICATECHECK = "/auth/duplicateCheck" // 사용자 중복확인

        Vue.prototype.$AUTH_LOGIN = "/auth/login"// 로그인
        Vue.prototype.$AUTH_AUTOLOGIN = "/auth/autoLogin"// 자동로그인
        Vue.prototype.$AUTH_SIMPLELOGIN = "/auth/simpleLogin"// 간편로그인
        Vue.prototype.$AUTH_DORMANCY = "/auth/dormancy"// 휴먼계정 해제
        Vue.prototype.$AUTH_FINDID = "/auth/findId" // 아이디 찾기
        Vue.prototype.$AUTH_FINDPW = "/auth/findPw" // 비밀번호 찾기
        Vue.prototype.$AUTH_UPDATE_FIND_PW = "/auth/updateFindPw" // 비밀번호 찾기 후 변경
        Vue.prototype.$AUTH_REFRESHTOKEN = "/auth/refreshToken" // 토큰 갱신

        Vue.prototype.$USER_PIN = "/user/pin"   // 핀 등록
        Vue.prototype.$USER_CHECK_PIN = "/user/checkPin"   // 핀 확인

        Vue.prototype.$AUTH_CI = "/auth/ci" // ci 값 존재여부 확인, 등록

        Vue.prototype.$BANK = "/bank"   // 은행 리스트
        Vue.prototype.$VAN = "/van" // van사 목록
        Vue.prototype.$DELIVERY = "/delivery" // 배달앱 목록

        Vue.prototype.$MAIN_INFO = "/main"  // 메인정보

        Vue.prototype.$SEARCH = "/search/" // 주문번호 검색

        Vue.prototype.$NOTIFICATION = "/notification"   // 알림리스트
        Vue.prototype.$MONTHLY_REPAYMENT = "/monthly/repayment/" // 월세 재결제(결제실패)

        Vue.prototype.$ACCOUNT = "/account" // 계좌 등록, 입금 내역, 계좌 수정
        Vue.prototype.$ACCOUNT_CHECK = "/account/check" // 계좌 조회
        Vue.prototype.$ACCOUNT_LIST = "/account/list" // 계좌 목록
        Vue.prototype.$ACCOUNT_DELETE = "/account/delete" // 계좌 삭제
        Vue.prototype.$ACCOUNT_APPROVED = "/account/approved" // 심사 완료 된 퀵비 입금 계좌 내역

        Vue.prototype.$QUICK = "/quick" // 배달비 충전
        Vue.prototype.$CHECK_ACCOUNT = "/quick/checkAccount" // 배대사 계좌
        Vue.prototype.$USER_LOGO_BRANCH = "/user/logo/branch" // 사용자 상위 지사 이미지 경로 조회

        Vue.prototype.$QUICK_LIST = "/log/quick"
        Vue.prototype.$QUICK_LIST_DETAIL = "/log/quick/?"
        Vue.prototype.$RESERVED_SMS = "/log/payment/reservedSms" // 월별 카톡 예약 현황
        Vue.prototype.$RESERVED_SMS_DETAIL = "/log/payment/reservedSms/" // SMS 예약건 상세 내역
        Vue.prototype.$REVENUE_DAY = "/revenue/day" // 일별 매출 합계 금액
        Vue.prototype.$REVENUE_DAY_DETAIL = "/revenue/dayDetail" // 일별 매출 상세 금액
        Vue.prototype.$REVENUE_DAYS = "/revenue/days" // 일별 매출 리스트
        Vue.prototype.$REVENUE_MONTH = "/revenue/month" // 월별 결제 합계 금액
        Vue.prototype.$TRANSACTION_DETAIL = "/log/payment/" // 결제 내역 상세
        Vue.prototype.$TRANSACTION_MONTHS = "/log/payment/months" // 월별 결제 현황
        Vue.prototype.$LOG_MONTHLY = "/log/monthly" // 월세 전체 등록 내역
        Vue.prototype.$LOG_MONTHLY_TYPE = "/log/monthly/" // 월세 납입 중 내역
        Vue.prototype.$LOG_MONTHLY_YEAR_TYPE = "/log/monthly/" // 월세 납입 중 내역
        Vue.prototype.$LOG_MONTHLY_DETAIL = "/log/monthly/detail/" // 월세 상세 내역
        Vue.prototype.$LOG_MONTHLY_TOTALPAYRESERVE = "/log/monthly/totalPayReserve" // 월 예정 납입금액
        Vue.prototype.$MONTHLY_RENAME = "/monthly/rename/?" // 월세 이름 변경
        Vue.prototype.$MONTHLY_REPAY = "/monthly/repay" // 월세 재결재 폼 데이터, 월세 재결재(get, post)
        Vue.prototype.$MONTHLY_STOP_PAYMENT = "/monthly/stopPayment/?" // 월세 납입 중단-처리
        Vue.prototype.$MONTHLY_STOP_PAYMENT_CHECK = "/monthly/stopPayment/check/?" // 월세 납입 중단-확인
        Vue.prototype.$PAYMENT_CANCELCARD = "/payment/cancelCard" // 결제 취소 요청
        Vue.prototype.$PAYMENT_CANCELQR = "/payment/qr/cancel" // qr결제 취소 요청
        Vue.prototype.$PAYMENT_SMS_ADD = "/payment/sms/add" // 결제 sms 예약 추가
        Vue.prototype.$PAYMENT_SMS_CANCEL = "/payment/sms/cancel" // 결제 sms 예약 추가
        Vue.prototype.$MONTHLY_DELETE = "/monthly/delete" // 월세 심사 반려 내역 삭제

        Vue.prototype.$MONTHLY = "/monthly" // 월세등록
        Vue.prototype.$MONTHLY_DETAIL = "/monthly/detail" // 월세등록
        Vue.prototype.$MONTHLY_STEPONE = "/monthly/stepOne" // 월세등록 확인1
        Vue.prototype.$MONTHLY_STEPTWO = "/monthly/stepTwo" // 월세등록 확인2
        Vue.prototype.$MONTHLY_ACCOUNTAUTH = "/monthly/accountAuth" // 계좌인증
        Vue.prototype.$MONTHLY_INTERESTFREE = "/monthly/interestFree" // 카드사별 무이자 혜택

        Vue.prototype.$PAYMENT_QR_CREATE = "/payment/qr/create" // QR 생성
        Vue.prototype.$PAYMENT_QR_LOOKUPRESULT = "/payment/qr/lookUpResult" // QR 결제 결과 조회
        Vue.prototype.$PAYMENT_QR = "/payment/qr" // QR 스캔

        Vue.prototype.$PAYMENT_BARCODE = "/payment/barcode" // 신용카드 바코드 스캔

        Vue.prototype.$PAYMENT_SMS_SEND = "/payment/sms/send" // 결제 SMS 발송

        Vue.prototype.$AUTH_LEAVE = "/auth/leave"
        Vue.prototype.$AUTH_LOGOUT = "/auth/logout"
        Vue.prototype.$FAQ = "/faq"
        Vue.prototype.$FAQ_CATEGORY = "/faq/category"
        Vue.prototype.$USER_FCM_UPDATE = "/auth/fcmToken"
        Vue.prototype.$NOTICE = "/notice"
        Vue.prototype.$USER_NOTIFICATION = "/user/notification" // 사용자 알림설정, (get, put)
        Vue.prototype.$QNA_LIST = "/qna/"
        Vue.prototype.$QNA_REGIST = "/qna/"
        Vue.prototype.$QNA_DETAIL = "/qna/"
        Vue.prototype.$QNA_CATEGORY = "/qna/category"
        Vue.prototype.$QNA_DELETE = "/qna/"
        Vue.prototype.$QNA_EDIT = "/qna/"

        Vue.prototype.$USER = "/user"
        Vue.prototype.$USER_DETAIL = "/user/detail"
        Vue.prototype.$USER_PASSWORD = "/user/password"
        Vue.prototype.$USER_SALES_NOTIFICATION = "/user/sales-notification"
        Vue.prototype.$USER_DELIVERY_AGENT = "/user/delivery-agent"
        Vue.prototype.$USER_ANOTHER = "/user/another"
        Vue.prototype.$USER_CHECK_PIN = "/user/checkPin"
        Vue.prototype.$USER_PIN = "/user/pin"
        Vue.prototype.$USER_CHECKPASSWORD = "/user/checkPassword"
        Vue.prototype.$USER_RESET = "/user/reset"
        Vue.prototype.$USER_INFO_DETAIL = "/user/detail"
        Vue.prototype.$USER_PROFILE_IMAGE = "/user/profile-image"

        Vue.prototype.$CARD = "/card"
        Vue.prototype.$CREDITCARD_LIST = "/creditCard/"

        Vue.prototype.$PAYMENT_HANDWRITTEN = "/payment/handwritten"
        Vue.prototype.$PAYMENT_TERMINAL = "/payment/terminal"

        Vue.prototype.$SSO_AUTH_WITH_ID_PW = "/sso/login"
        Vue.prototype.$SSO_AUTH_WITH_TOKEN = "/sso/accessLogin"

        Vue.prototype.$post = function (callUrl, caller, postData, useIndicator, success, fail) {
            console.log('test url' + callUrl)

            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }

            if (useIndicator){
                this.$root.$refs.PNLoading.activeLoading(true)
            }

            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                if (useIndicator) {
                    this.$root.$refs.PNLoading.activeLoading(false)
                }

                pending_post[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {

                if (useIndicator) {
                    this.$root.$refs.PNLoading.activeLoading(false)
                }

                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$put = function (callUrl, caller, postData, useIndicator, success, fail) {

            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }

            if (useIndicator){
                this.$root.$refs.PNLoading.activeLoading(true)
            }

            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                if (useIndicator){
                    this.$root.$refs.PNLoading.activeLoading(false)
                }

                pending_put[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                if (useIndicator){
                    this.$root.$refs.PNLoading.activeLoading(false)
                }

                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$delete = function (callUrl, caller, useIndicator, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }

            if (useIndicator){
                this.$root.$refs.PNLoading.activeLoading(true)
            }

            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                if (useIndicator){
                    this.$root.$refs.PNLoading.activeLoading(false)
                }

                pending_delete[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                if (useIndicator){
                    this.$root.$refs.PNLoading.activeLoading(false)
                }

                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }

            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('accessToken')
            if (token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false
                if (response.data.result === 'success') {
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch(e => {

                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.prototype.$niceAuth = function (success, fail) {
            this.$root.$refs.PNLoading.activeLoading(true)

            axios.post("https://nice.payhub.co.kr/check-pay-plus/request", null, {

                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                },
                timeout: 60000

            }).then((response) => {
                this.$root.$refs.PNLoading.activeLoading(false)

                if (response.data.success) {
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch(e => {
                this.$root.$refs.PNLoading.activeLoading(false)
                console.log(e)
                fail(e.response)
            })
        }
    }
})
