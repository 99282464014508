<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>비밀번호 찾기</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap pb-80">
        <div class="charge-form">
          <p class="mb-10">아이디</p>
          <div class="select">
            <RPInputBorder
                :type="'text'"
                :placeholder="'아이디를 입력해주세요'"
                :model="id"
                @update="(_model) => {this.id=_model}"
            />
          </div>
        </div>
      </div>
      <button class="align-bottom btn btn-m btn-pink" @click="findPw">본인인증</button>
    </div>
    <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
      <input type="hidden" name="m" value="checkplusService">
      <input type="hidden" name="EncodeData" id="nice-enc">
    </form>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";


export default {
  name: "FindPw",
  components: {
    RPInputBorder,
  },
  data() {
    return {
      id: '',
      nice: '',
      phone: '',
    }
  },
  methods: {
    findPw() {
      if (this.id === ''){
        this.notify('error', '아이디를 정확하게 입력해주세요.')
        return
      }
      this.niceAuth()
    },
    niceAuth() {
      this.$niceAuth((result) => {
        this.niceShow(result.enc_data)

      }, (result) => {
        console.log(result)
      })
    },
    niceShow(enc_data) {
      window.removeEventListener('message', this.niceDone)

      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      window.$('#nice-enc').val(enc_data)
      window.$('#nice-form').attr('target', 'popupChk')
      window.$('#nice-form').submit()

      window.addEventListener('message', this.niceDone);
    },
    niceDone(e) {
      if(e.origin === 'https://nice.payhub.co.kr') {
        this.phone = e.data.phone_no.substr(0,3)+'-'+e.data.phone_no.substr(3,4)+'-'+e.data.phone_no.substr(7,4)
        this.nice = e.data.payload

        window.removeEventListener('message', this.niceDone)

        this.niceCheck()
      }
    },
    niceCheck() {
      let formData = new FormData()
      formData.append('id', this.id)
      formData.append('auth', this.nice)

      this.$post(this.$AUTH_FINDPW, 'findpw_findpw', formData, true,() => {
        this.$router.replace({name: 'FindPwChange', params: { id: this.id, auth: this.nice }})

      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({name: 'FindPwNoId'})
      })
    },
  }
}
</script>

<style scoped>

</style>