<template>
  <div class="overlay" v-if="show">
    <div class="all-content w-100">
      <header class="header-wrap bg-clear">
<!--        <p><img src="/image/logo_white.svg" alt="reappaypg"></p>-->
        <button v-show="showClose" type="button" class="btn-close" @click="close()"><i class="icon-close color-white"></i></button>
      </header>
      <div class="certification-wrap">
        <div class="certification-box bg-pink">
          <div class="w-100 p-20 d-flex" style="align-items: center;" v-show="title !== '생체 인증 등록' && title !== 'PIN 확인'">
<!--            <p><small class="fz-20 weight-light">{{ title }}</small></p>-->
            <p class="pt-15 pb-15">
              <small v-if="title === '충전'" class="color-black t-c" style="font-size: 25px">{{ amount | numberWithCommasAll }}원을 충전합니다.</small>
<!--              <strong class="fz-40 weight-normal" v-show="amount > 0">{{ amount | numberWithCommasAll }}</strong>-->
<!--              <small v-show="amount > 0" class="fz-20 weight-light"> {{ unit }}</small>-->
<!--              <strong v-show="subAmount > 0" class="fz-20"> <small class="weight-light"> + </small> {{ subAmount | numberWithCommas }}</strong>-->
<!--              <small v-show="subAmount > 0" class="fz-20 weight-light"> {{ subUnit }}</small>-->
            </p>
<!--            <p v-show="unit.toUpperCase() === 'REAP'" class="fz-20 pb-15">= {{ amount * usdPrice | numberWithCommas}} USD</p>-->
            <p>
              <small class="weight-light fz-20">핀번호를 입력해주세요.</small>
            </p>
          </div>
          <div v-show="title === '생체 인증 등록' || title === 'PIN 확인'">
            <span>PIN</span>
            <p><em v-show="title !== 'PIN 확인'">{{ title }}</em><br>핀번호를 입력해주세요.</p>
          </div>
          <div class="certification-bullets pt-65">
            <span :class="{'bullets-active' : pin.length >= 1}"></span>
            <span :class="{'bullets-active' : pin.length >= 2}"></span>
            <span :class="{'bullets-active' : pin.length >= 3}"></span>
            <span :class="{'bullets-active' : pin.length >= 4}"></span>
            <span :class="{'bullets-active' : pin.length >= 5}"></span>
            <span :class="{'bullets-active' : pin.length >= 6}"></span>
          </div>
          <button class="btn-under color-white reset-pin-btn" @click="$router.replace({name:'RestorePin'})">핀번호를 잊어버렸어요.</button>
        </div>
        <div class="number-area">
          <ul>
            <li>
              <button @click="numBtn('1')">1</button>
              <button @click="numBtn('2')">2</button>
              <button @click="numBtn('3')">3</button>
            </li>
            <li>
              <button @click="numBtn('4')">4</button>
              <button @click="numBtn('5')">5</button>
              <button @click="numBtn('6')">6</button>
            </li>
            <li>
              <button @click="numBtn('7')">7</button>
              <button @click="numBtn('8')">8</button>
              <button @click="numBtn('9')">9</button>
            </li>
            <li>
              <button v-show="showClose" @click="cancel()" class="cancel">취소</button>
              <button v-show="!showClose" class="cancel"></button>
              <button @click="numBtn('0')">0</button>
              <button @click="backspace()">
                <img src="/image/cancel.svg" alt="지우기">
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="overlay text-center d-flex" v-show="isLoading">
      <div class="spinner-border color-gray m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  name: "PinCheck",
  // props: ['title', 'amount', 'unit', 'subAmount', 'subUnit'],
  props: {
    title: {
      required: false,
    },
    amount: {
      required: false,
    },
    unit: {
      required: false,
    },
    subAmount: {
      required: false,
    },
    subUnit: {
      required: false,
    },
    showClose: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      callback: null,
      pin: '',
      // failCount: 0,
      isLoading: false,
    }
  },
  mounted() {
    // window.addEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
    this.$on(this.$EVENT_CLOSE_PINCHECK, this.close)
  },
  beforeDestroy() {
    // window.removeEventListener(this.$EVENT_BIO_RESULT, this.bioResult)
    this.$off(this.$EVENT_CLOSE_PINCHECK)
  },
  methods: {
    showPin(callback) {
      this.callback = callback
      this.pin = ''
      this.failCount = 0
      this.show = true
      // if (localStorage.getItem('useLocalBio') === 'true') {
      //   window.requestBio()
      // }
    },
    close() {
      this.pin = ''
      this.failCount = 0
      this.show = false
      this.isLoading = false
    },
    numBtn(_num) {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin + _num
      if (this.pin.length === 6 ) {
        this.checkPin()
      }
    },
    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },
    cancel() {
      this.close()
    },
    // bioResult(e) {
    //   let pin = e.detail
    //   if (localStorage.getItem('useLocalBio') === 'true') {
    //     if (pin.length === 6) {
    //       this.pin = pin
    //       this.checkPin()
    //     } else {
    //       this.notify('error', '생체 인증에 실패 했습니다.')
    //     }
    //   }
    // },
    checkPin() {
       let formData = new FormData()
       formData.append('pinNumber', this.pin)
       this.$post(this.$USER_CHECK_PIN, 'pincheck_checkpin', formData, true, () => {
         window.setPin(this.pin)
         this.isLoading = true
         this.callback()

       }, (result) => {
         this.httpError(result.data)
         this.isLoading = false
         this.pin = ''
         this.failCount++
       })

      if (this.failCount >= 5) { // 핀번호 재설정으로 이동
        this.$root.$refs.Alert.showAlert('',
            '비밀번호 오류횟수 5회 초과입니다.<br/>핀번호를 재설정해주세요.<br/>',
            () => {
              this.$router.replace({name: 'RestorePin'})
            }, false, false)
      }
    }
  }

}
</script>

<style scoped>

</style>
