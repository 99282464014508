<template>
  <div class="all-content">
    <header class="header-wrap">
    </header>
    <div class="content-wrap bg-white content-row pb-80">
      <div class="confirm-list">
        <p>
          <img src="/image/character_happy.svg" alt="캐릭터">
          <span>
            {{ paymentInfo.amount | numberWithCommas }}
            <i> 원</i>
            <br/>
            결제완료
          </span>
          <em>결제가 완료되었습니다.</em>
        </p>
        <ul class="price-list">
          <li>
          </li>
          <li>
            <strong>결제일시</strong>
<!--            <em>{{ regDate | moment('YYYY-MM-DD HH:mm:ss') }}</em>-->
            <em>{{ displayRegDate }}</em>
          </li>
          <li class="b-none">
          </li>
        </ul>
      </div>
    </div>
    <div class="align-bottom d-flex-row w-100">
      <button class="btn-dgray-4D4D4D mr-auto btn-half" @click="printReceipt">영수증 확인</button>
      <button class="btn-pink ml-auto btn-half" @click="$router.go(-2)">확인</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "QRCardPaymentSuccess",
  data() {
    return {
      paymentInfo: {
        shopData: {
          shopName: "",
          business: "",
          address: "",
          addressDetail: "",
          name: "",
          shopHp: "",
          goodsName: ""
        },
        amount: "",
        regDate: "",
        billKeyPay: {
          billkeytid: "", // pgseq
          billkeytradeDateTime: "", //승인일자
          billkeyrespCode: "",  // 응답코드
          billkeyrespMessage: "", // 사용카드명 + / + 승인번호
          billkeyissuerCardType: "", // 발급사 카드 타입
          billkeyissuerCardName: "", // 발급사 카드 이름
          billkeypurchaseCardType: "", // 매입사 카드 타입
          billkeypurchaseCardName: "", // 매입사 카드 이름
          billkeymaskedCardNumb: "", // 마스킹된 카드 번호
          billkeycardType: "", // 카드 구분
          billkeybillingToken: "", // 빌링키
          billkeyTranseq: "", // TranSeq
          billkeypayload: "", // payload
          billkeytotAmt: "", // 결제금액
          billkeyvatAmt: "", // 공급가액
          billkeyapprovalNumb: "", // 승인번호
        },
        idx:"",
      },
      displayRegDate: '',
    }
  },
  mounted() {
    //영수증 데이터 받아올것
    this.paymentInfo = JSON.parse(this.$route.params.paymentInfo)

    let date = moment(this.paymentInfo.regDate, 'YYYYMMDDHHmmss')
    this.displayRegDate = moment(date).format('YYYY-MM-DD HH:mm:ss')

    window.addEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
    window.addEventListener(this.$EVENT_PRINT, this.printResult)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_CONNECT_DEVICE, this.connectResult)
    window.removeEventListener(this.$EVENT_PRINT, this.printResult)

    clearTimeout(this.timeout)
  },
  methods: {
    printReceipt() {
      this.$router.push({ name : 'PaymentHistoryDetail', params : { idx: this.paymentInfo.idx, confirmReceipt: true } })
      //디바이스 연결 상태 요청
      // window.requestConnectDevice()
    },
    connectResult(e) {
      let result = e.detail
      if (result === '1') {
        this.timeout = setTimeout(() => {
          let date = this.paymentInfo.regDate.substr(0, 4) + '.' +
              this.paymentInfo.regDate.substr(4, 2) + '.' +
              this.paymentInfo.regDate.substr(6, 2) + ' ' +
              this.paymentInfo.regDate.substr(8, 2) + ':' +
              this.paymentInfo.regDate.substr(10, 2) + ':' +
              this.paymentInfo.regDate.substr(12, 2)

          //인쇄
          window.addPrintBoldString('영수증\n')
          window.addPrintString('상호명 : ' + this.paymentInfo.shopData.shopName + '\n')
          window.addPrintString('대표자 : ' + this.paymentInfo.shopData.name + '\n')
          window.addPrintString('사업자번호 : ' + this.paymentInfo.shopData.business + '\n')
          window.addPrintString('전화번호 : ' + this.paymentInfo.shopData.shopHp + '\n')
          window.addPrintString('주소 : ' + this.paymentInfo.shopData.address + ' ' + this.paymentInfo.shopData.addressDetail + '\n')
          window.addPrintBoldString(this.paymentInfo.billKeyPay.billkeymaskedCardNumb + '\n')
          window.addPrintString('거래일시 : ' + date + '\n')
          window.addPrintBoldString('승인번호 : ' + this.paymentInfo.billKeyPay.billkeyapprovalNumb + '\n')
          window.addPrintBoldString('거래금액 : ' + this.$options.filters.numberWithCommas(this.paymentInfo.billKeyPay.billkeytotAmt) + ' 원\n')
          window.addPrintString('감사합니다.\n')

          window.requestPrintStart()

        }, 1000)
      } else {
        this.notify('error', '기기 연결에 실패했습니다.', false)
      }
    },
    printResult(e) {
      let result = e.detail
      if (result === '1') {
        console.log('인쇄에 성공했습니다.')
      } else {
        console.log('인쇄에 실패했습니다.')
      }
    }
  }
}
</script>

<style scoped>

</style>
