<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list">
      <div class="d-flex-row jc-between">
        <h5 class="pt-0">설정</h5>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul>
        <li v-for="(condition, index) in conditions" :key="index">
          <a @click="selectedType(condition)">
            <span>{{ condition.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChargeSalesTypeSelect",
  props: ['conditions'],
  methods: {
    selectedType(_condition) {
      this.$emit('selectedType', _condition)
    }
  }
}
</script>

<style scoped>

</style>