<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>계좌인증</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap pb-80">
        <div class="charge-form">
          <div class="select-wrap">
            <button class="select" v-if="selectedBank !== null" @click="openBankList()">{{
                selectedBank.name
              }}
            </button>
            <button class="select" v-else @click="openBankList()">은행/증권사 선택</button>
          </div>
        </div>
        <div class="mt-10 select">
          <RPInputBorder
              :type="'text'"
              :placeholder="'계좌번호 입력'"
              :model="lessorAccount"
              @update="(_model) => {this.lessorAccount=_model}"
              :input-mode="'numeric'"
          />
        </div>
        <div class="mt-10 select">
          <RPInputBorder
              :type="'text'"
              :placeholder="'예금주명 입력'"
              :model="lessor"
              @update="(_model) => {this.lessor=_model}"
          />
        </div>

        <p class="mb-10 mt-30 fz-18">개인/사업자 구분</p>
        <div class="d-flex-row mb-30">
          <div class="radios-circle">
            <input type="radio" id="normalPayment" :checked="lessorAccountType === '1'"
                   @click="selectedAccountType('1')">
            <label for="normalPayment">개인</label>
          </div>

          <!-- 수리비(3)는 일반결제만 -->
          <div class="radios-circle ml-20">
            <input type="radio" id="regularPayment" :checked="lessorAccountType === '2'"
                   @click="selectedAccountType('2')">
            <label for="regularPayment">사업자</label>
          </div>
        </div>
      </div>
      <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="goNext()">인증확인</button>
    </div>

    <bank-select
        v-show="showBankList"
        :bank-list="bankList"
        @selected="setSelectedBank"
        @close="showBankList=false"
    />
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import BankSelect from "@/components/charge/BankSelect";
import EventBus from "@/main";

export default {
  name: "RegistAccount",
  components: {
    RPInputBorder,
    BankSelect
  },
  data() {
    return {
      bankList: [],
      groupType: '',
      selectedBank: null,
      showBankList: false,
      lessorAccount: '',
      lessorAccountType: '1',
      lessor: '',
      errors: {
        selectedBank: '',
        lessorAccount: '',
        lessor: '',
      }
    }
  },
  mounted() {
    this.bankList = this.$route.params.bankList
    this.groupType = this.$route.params.groupType

    // console.log('registAccount groupType' + this.groupType)
  },
  methods: {
    selectedAccountType(_type) {
      this.lessorAccountType = _type
    },

    openBankList() {
      this.showBankList = true
    },
    setSelectedBank(_bank) {
      this.selectedBank = _bank
      this.showBankList = false

      this.changeNextBtn()
    },
    goNext() {
      if (this.selectedBank === null){
        this.errors.selectedBank = '은행을 선택해주세요.'
        return
      }
      if (this.lessorAccount === ''){
        this.errors.lessorAccount = '계좌번호를 입력해주세요.'
        return
      }
      if (this.lessor === ''){
        this.errors.lessor = '예금주명을 입력해주세요.'
        return
      }

      let formData = new FormData()
      // formData.append('groupType', this.groupType)
      formData.append('lessorBankCode', this.selectedBank.code)
      formData.append('lessorAccount', this.lessorAccount)
      formData.append('lessorAccountType', this.lessorAccountType)
      formData.append('lessor', this.lessor)

      this.$post(this.$MONTHLY_ACCOUNTAUTH, 'registaccount_accountauth', formData, true,() => {
        EventBus.$emit(this.$EVENT_ACCOUNT, {
          'lessorAccount': this.lessorAccount,
          'lessorBankCode': this.selectedBank.code,
          'lessor': this.lessor
        });
        this.$router.back()

      }, (result) => {
        this.httpError(result.data)
      })

      //test
      // EventBus.$emit(this.$EVENT_ACCOUNT, {
      //   'lessorAccount': this.lessorAccount,
      //   'lessorBankCode': this.selectedBank.code,
      //   'lessor': this.lessor
      // });
      // this.$router.back()
    },
    changeNextBtn() {
      if (this.selectedBank === null || this.lessorAccount === '' || this.lessor === ''){
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      } else {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-pink"
      }
    },
  },
  watch: {
    lessorAccount(){
      this.changeNextBtn()
    },
    lessor(){
      this.changeNextBtn()
    },
  }
}
</script>

<style scoped>

</style>
