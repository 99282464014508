<template>
  <div class="all-content bg-white card">
    <Menu ref="myInfoMenu"/>
    <div class="content-wrap" v-show="items.length === 0">
      <div class="list-none-wrap noCard">
        <div class="list-none">
          <p class="list-none-title fz-20 color-darkgray-4F4F4F">등록된 카드가 없습니다.</p>
        </div>
        <button class="btn-l btn-pink btn-round-5" @click="registCard()">신용카드 등록</button>
      </div>
    </div>
    <div class="content-wrap-footer" v-show="items.length > 0">
      <div class="main-wrap">
        <section class="section-radius p-15 bg-white mb-10" v-for="item in items" :key="item.idx">
          <div class="bank" @click="openSetting(item)">
            <i class="main_bank" v-show="item.main">주카드</i>
            <div>
              <img class="thmb" :src="item.name | cardImage" alt="카드">
            </div>
            <div class="text align-f card-detail">
              <strong>{{ item.name }}</strong>
              <em class="fz-16 color-darkgray-4F4F4F">{{ item.number }}</em>
            </div>
          </div>
        </section>
      </div>
    </div>

    <footer class="btn-wrap" v-show="items.length > 0">
      <div class="row">
        <button class="btn-l btn-pink btn-round-5" @click="registCard()">신용카드 등록</button>
      </div>
    </footer>

    <card-setting
        v-show="showSetting"
        :conditions="typeList"
        :selectedCard="selectedCard"
        @selected="setSelectedCondition"
        @close="showSetting=false"
    />
  </div>
</template>

<script>
import CardSetting from "@/components/myinfo/card/CardSetting";
import Menu from '@/components/myinfo/MenuCmp'

export default {
  name: "CardList",
  components: {CardSetting, Menu},
  data() {
    return {
      items: [],
      showSetting: false,
      typeList: [
        {
          type: '0',
          name: '주카드 설정',
        },
        {
          type: '1',
          name: '삭제',
        },
      ],
      selectedCard: {},
    }
  },

  created() {
    this.getItems()
  },

  mounted() {
    this.$refs.myInfoMenu.focusTab(3)
  },

  methods: {
    getItems() {
      this.$get(this.$CARD, 'cardlist_mounted',(result) => {
        this.items = result.data
      }, (result) => {
        this.httpError(result.data)
      })
    },

    openSetting(_card) {
      this.selectedCard = _card
      if (this.selectedCard.main){
        this.typeList = [
          {
            type: '1',
            name: '삭제',
          }]

      } else {
        this.typeList = [
          {
            type: '0',
            name: '주카드 설정',
          },
          {
            type: '1',
            name: '삭제',
          },
        ]
      }

      this.showSetting = true
    },

    setSelectedCondition(_condition) {
      this.showSetting = false
      if (_condition.type === '0') {
        // if (this.selectedCard.main) {
        //   str = '해지'
        // }
        //주카드 설정
        this.$put(this.$CARD + '/' + this.selectedCard.idx, 'cardlist_setselectedcondition1', null, true, () => {
          this.notify('success', '성공적으로 설정되었습니다.', false)
          this.getItems()
        }, (result) => {
          this.httpError(result.data)
        })

      } else if (_condition.type === '1') {
        //삭제
        this.$root.$refs.Confirm.showDialog('', '해당 신용카드를 삭제합니다.', '확인', '취소', () => {
          this.$delete(this.$CARD + '/' + this.selectedCard.idx, 'cardlist_setselectedcondition2', true, () => {
            this.notify('success', '성공적으로 삭제되었습니다.', false)
            this.getItems()
          }, (result) => {
            this.httpError(result.data)
          })
        }, () => {
        })
      }
    },

    registCard() {
      this.$router.push({name:'RegistCard'})
    }
  },
  filters: {
    cardImage: (value) => {
      if (value.startsWith('삼성') || value.toLowerCase().startsWith('samsung')) {
        return '/image/bank_samsung.svg'
      } else if (value.startsWith('비씨') || value.toLowerCase().startsWith('bc')) {
        return '/image/bank_bc.png'
        // } else if (value.startsWith('외환') || value.toLowerCase().startsWith('keb')) {
        //   return '/image/.svg'
      } else if (value.startsWith('롯데') || value.toLowerCase().startsWith('lotte')) {
        return '/image/bank_lotte.png'
      } else if (value.startsWith('현대') || value.toLowerCase().startsWith('hyundai')) {
        return '/image/bank_hyundai.png'
      } else if (value.startsWith('국민') || value.toLowerCase().startsWith('kb')) {
        return '/image/bank_kb.svg'
      } else if (value.startsWith('신한') || value.toLowerCase().startsWith('shinhan')) {
        return '/image/bank_shingan.svg'
      } else if (value.startsWith('시티') || value.toLowerCase().startsWith('citi')) {
        return '/image/bank_citi.svg'
      } else if (value.startsWith('농협') || value.toLowerCase().startsWith('nh')) {
        return '/image/bank_nh.svg'
      } else if (value.startsWith('하나') || value.toLowerCase().startsWith('hana')) {
        return '/image/bank_hana.svg'
      } else if (value.startsWith('비자') || value.toLowerCase().startsWith('visa')) {
        return '/image/bank_visa.png'
      } else if (value.startsWith('마스터') || value.toLowerCase().startsWith('master')) {
        return '/image/bank_master.png'
        // } else if (value.startsWith('제이씨비') || value.toLowerCase().startsWith('jbc')) {
        //   return '/image/.svg'
        // } else if (value.startsWith('아멕스') || value.toLowerCase().startsWith('amex')) {
        //   return '/image/.svg'
        // } else if (value.startsWith('다이너스') || value.toLowerCase().startsWith('diners')) {
        //   return '/image/.svg'
      }
      return '/image/user.svg'
    }
  }
}
</script>

<style scoped>

</style>
