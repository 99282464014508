<template>
  <div class="all-content">
    <header class="header-wrap">
      <p>내 문의</p>
      <button type="button" class="btn-close" @click="$router.back()"><i class="icon-close"></i></button>
    </header>
    <div class="content-wrap bg-white">
      <div class="main-wrap p-0">
        <div class="d-flex-row p-20">
          <small class="fz-16 qna-title">{{ item.title }}</small>
          <small class="ml-auto" :class="stateClass(item.state)">{{ item.state | state }}</small>
        </div>
        <div v-show="item.state === '2'" class="p-20 mb-10 bg-gray">
          <strong class="fz-16 d-flex-row">답변<small class="ml-auto weight-normal color-darkgray">{{ item.answerDate }}</small></strong>
          <br/>
          <div v-if="item.answer" v-html="item.answer" class="faq-content"></div>
        </div>
        <div class="p-20 bg-gray">
          <strong class="fz-16">내용</strong>
          <br/><br/>
          <p v-html="item.content"></p>
        </div>

        <div class="p-20">
          <div class="question-content">
            <button type="button" class="btn-dgray question-btn question-btn-set" >첨부파일</button>
            <input type="text" class="input-box question-input-box pl-110" v-model="item.originFileName" disabled>
          </div>
          <div class="question-content">
            <input type="text" class="input-box question-input-box fz-13" value="" disabled v-model="item.version">
          </div>
        </div>
        <div class="d-flex-row px-20" v-show="item.state === '0'">
          <button type="button" class="ml-auto mr-10 btn-dgray question-btn" @click="goEdit()">수정</button>
          <button type="button" class="btn-dgray question-btn" @click="goDelete()">삭제</button>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import showdown from "showdown"

export default {
  name: "QnaDetail",
  data() {
    return {
      item: {
        idx: '',
        title: '',
        content: '',
        version: '',
        answer: '',
        answerDate: '',
        fileIdx: '',
        originFileName: '',
        state: '',
      }
    }
  },
  mounted() {
    this.$get(this.$QNA_DETAIL + this.$route.params.idx, 'qnadetail_mounted', (result) => {
      this.item = result.data

      let content = this.item.content
      let converter = new showdown.Converter()
      this.item.content = converter.makeHtml(content).replaceAll('\\', '')

      if (this.item.answer != null && this.item.answer !== ''){
        content = this.item.answer
        this.item.answer = converter.makeHtml(content).replaceAll('\\', '')
      }

    }, (result) => {
      this.httpError(result.data)
    })
  },
  methods: {
    goEdit() {
      this.$router.replace({name:'QnaRegist', params:{idx:this.$route.params.idx}})
    },
    goDelete() {
      this.$root.$refs.Confirm.showDialog('', '해당 문의를 삭제합니다.', '확인', '취소', () => {
        this.$delete(this.$QNA_DELETE + this.$route.params.idx, 'qnadetail_godelete', true, () => {
          this.$router.back()
        }, (result) => {
          this.httpError(result.data)
        })
      }, () => {

      })
    },
    stateClass(value) {
      if (value === '0' ) {
        return 'qna-state-0'
      } else if (value === '1' ) {
        return 'qna-state-1'
      } else if (value === '2' ) {
        return 'qna-state-2'
      }
      return ''
    }
  },
  filters: {
    state: (value) => {
      if (value === '0' ) {
        return '답변대기'
      } else if (value === '1' ) {
        return '처리중'
      } else if (value === '2' ) {
        return '답변완료'
      }
      return ''
    },
  }
}
</script>

<style scoped>

</style>
