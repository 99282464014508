<template>
  <loading :active="show"
           :can-cancel="true"
           :is-full-page="true"
           :color="'#EC758B'"
           :background-color="'#000000'"
           :width="50"
           :height="50"
           :blur="''"
  />
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "PNLoading",
  component: {
    Loading,
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    activeLoading(_isShow){
      this.show = _isShow
    },
  }
}
</script>

<style scoped>

</style>