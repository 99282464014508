<template>
  <div class="input-close h-100 border-zero">
    <input :type="type"
           :placeholder="placeholder"
           v-model.trim="inputValue"
           @focusin="isFocus = true"
           @focusout="isFocus = false"
           :disabled="disable"
           :class="{'font-28' : isBig, 't-c-15px' : isCenter, 't-r-45px' : isRight, 'border-pink': isFocus }"
           class="h-100"
           :inputmode="inputMode"
           :maxlength="maxLength > 0 ? maxLength : ''"
           @input="formatInput"
    />

    <!-- TODO: 입력창 활성화 될 때만 close-circle 나오게 처리 해주세요 -->
    <button v-show="inputValue !== '' && !disable" type="button" class="close-circle" @click="deleteAll()"></button>
  </div>
</template>

<script>
export default {
  name: "RPInputBorder",
  props: {
    model: {
      required: true
    },
    placeholder: {
      required: false
    },
    type: {
      required: true
    },
    disable: {
      required: false
    },
    isBig: {
      required: false,
      type: Boolean,
      default: false,
    },
    isCenter: {
      required: false,
      type: Boolean,
      default: false,
    },
    isRight: {
      required: false,
      type: Boolean,
      default: false,
    },
    inputMode: {
      required: false,
      type: String,
      default: '',
    },
    withComma: {
      required: false,
      type: Boolean,
      default: false,
    },
    maxLength: {
      required: false,
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      inputValue: '',
      isFocus: false,
    }
  },

  created() {
    this.inputValue = this.model
  },

  methods: {
    formatInput() {
      if(this.withComma){
        // 입력된 값을 숫자로 파싱하고 천 단위 콤마 추가
        const parsedAmount = parseFloat(this.inputValue.replace(/,/g, "")) || 0;
        this.inputValue = parsedAmount.toLocaleString("en-US");
      }
    },
    deleteAll() {
      this.inputValue = ''
      this.$emit('update', '')
    },
  },

  watch: {
    inputValue() {
      this.$emit('update', this.inputValue)
    },
    model() {
      this.inputValue =this.model
    },
  }
}
</script>

<style scoped>
.t-c-15px {
  text-align: center;
  padding-right: 15px !important;
}
.t-r-45px {
  text-align: right;
  padding-right: 15px !important;
  //padding-right: 45px !important;
}
</style>
