<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list h-60" v-show="dateList.length > 0">
      <div class="d-flex-row jc-between">
        <h5 class="pt-0">날짜</h5>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul class="content-list">
        <li v-for="(date, idx) in dateList" :key="idx">
          <a @click="selectDate(date)">
            <span>{{ date | moment('YYYY.MM.DD') }}</span>
<!--            <em class="disabled">pppppppp</em>-->
          </a>
        </li>
      </ul>
    </div>
    <div class="select-list h-60" v-show="timeList.length > 0">
      <div class="d-flex-row jc-between">
        <h5 class="pt-0">시간</h5>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul class="content-list">
        <li v-for="(time, idx) in timeList" :key="idx">
          <a @click="selectTime(time)">
            <span>{{ time | moment('HH:00') }}</span>
<!--            <em class="disabled">pppppppp</em>-->
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SMSPaySelect",

  props: ['dateList', 'timeList'],
  methods: {
    selectDate(date) {
      this.$emit('selectedDate', date)
    },

    selectTime(time) {
      this.$emit('selectedTime', time)
    }
  },
}
</script>

<style scoped>

</style>
