<template>
  <div v-if="chargePaymentInfo" class="all-content overflow-x-hidden bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="goBack"><i class="icon-back"></i></button>
      <p class="fz-18">상세결제내역</p>
    </header>
    <div class="content-wrap">
      <section class="top-box2 border-top">
        <h5 class="top-box-tit mt-20 mb-30">결제정보</h5>
        <div class="d-flex-row jc-between">
          <span class="color-darkgray mb-15 top-box-contents">상품명</span>
          <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.productName }}</span>
        </div>
        <div class="d-flex-row jc-between">
          <span class="color-darkgray mb-15 top-box-contents">판매금액</span>
          <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.amount | numberWithCommas }}원</span>
        </div>
      </section>

      <!-- 결제내역 -->
      <div class="bar-10 ir_so">bar</div>
      <section class="histroy-box">
        <div class="top-box2 bg-white">
          <h5 class="top-box-tit mt-20 mb-30">결제내역</h5>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">카드번호</span>
            <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.cardNumber }}</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">승인번호</span>
            <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.approvalNumber }}</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">카드종류</span>
            <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.cardType }}</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">할부개월</span>
            <span class="color-darkgray top-box-contents">{{
                chargePaymentInfo.installment * 1 === 0 ? '일시불' : (chargePaymentInfo.installment * 1) + '개월'
              }}</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">거래일시</span>
            <span class="color-darkgray top-box-contents">{{
                chargePaymentInfo.registerDate | moment('YYYY.MM.DD HH:mm:ss')
              }}</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">공급가</span>
            <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.vat | numberWithCommas }}원</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">부가세</span>
            <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.vos | numberWithCommas}}원</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">총결제금액</span>
            <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.amount | numberWithCommas }}원</span>
          </div>
        </div>
      </section>
      <div class="bar-10 ir_so">bar</div>

      <!-- 이용상세정보 -->
      <section class="histroy-box">
        <div class="top-box2 bg-white">
          <h5 class="top-box-tit mt-20 mb-30">이용상세정보</h5>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">사업자명</span>
            <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.shopName }}</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">사업자번호</span>
            <span class="color-darkgray top-box-contents">{{
                chargePaymentInfo.business | businessNumberMasking
              }}</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">대표자명</span>
            <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.name }}</span>
          </div>
          <div class="d-flex-row jc-between">
            <span class="color-darkgray mb-15 top-box-contents">주소</span>
            <span class="color-darkgray top-box-contents">{{ chargePaymentInfo.address }}</span>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: "ChargePaymentHistoryDetail",
  data() {
    return {
      quickIdx: '',
      chargePaymentInfo: '',
      // tax: '',
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    getQuickPaymentListDetail() {
      this.$get(this.$QUICK_LIST_DETAIL.replace('?', this.quickIdx), this.$options.name + '_getQuickPaymentListDetail', (result) => {
        if (this.chargePaymentInfo === undefined) {
          this.chargePaymentInfo = '';
          return
        }

        this.chargePaymentInfo = result.data
        // this.tax = this.chargePaymentInfo.vos + this.chargePaymentInfo.fee // 부가세 + 수수료

      }, (result) => {
        this.httpError(result.data)
      })
    },

  },
  mounted() {
    if (this.$route.params.quickIdx) {
      this.quickIdx = this.$route.params.quickIdx
    }

    this.getQuickPaymentListDetail()

  },

}
</script>

<style scoped>
.top-box-tit {
  font-size: 18px;
  color: #4F4F4F !important;
}

.top-box-contents {
  font-size: 16px;
}

.top-box2 {
  position: relative;
  padding: 15px 20px;
  width: 100%;
}
</style>
