<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>{{ title }}</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap" v-html="convertedContent" style="white-space: pre-line;">
      </div>
    </div>
  </div>
</template>

<script>
import showdown from 'showdown'

export default {
  name: "RentTerm",
  data(){
    return {
      idx : '',
      title : '',
      content : '',
      convertedContent : '',
    }
  },
  mounted() {
    this.idx = this.$route.params.idx

    this.$get(this.$TERMS_CONTENT + '?type=' + this.idx, 'term_terms', (result) => {
      this.title = result.data.title
      this.content = result.data.content
      let converter = new showdown.Converter()
      this.convertedContent = converter.makeHtml(this.content).replaceAll('\\', '')

    }, (result) => {
      this.httpError(result.data)
    })
  },
}
</script>

<style scoped>

</style>
