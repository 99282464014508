<template>
  <div class="all-content">
    <div class="intro-wrap bg-white content-row">
      <div class="confirm-list">
        <p>
          <img class="check-pink mb-20 mt-120" src="/image/check_pink.png" alt="캐릭터">
          <em class="pink-title mb-10">휴먼계정이 해제되었습니다.</em>
          <em class="pink-title weight-light">다시 돌아와주셔서<br/>너무 감사합니다!</em>
        </p>
        <p class="pb-140">
          <img src="/image/character_happy.svg" alt="캐릭터">
        </p>
      </div>
    </div>
    <button class="align-bottom btn btn-m btn-pink" @click="$router.back()">확인</button>
  </div>
</template>

<script>
export default {
  name: "InactivitySuccess"
}
</script>

<style scoped>

</style>