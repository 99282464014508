<template>
  <div class="all-content">
    <header class="header-wrap">
    </header>
    <div class="content-wrap bg-white content-row pb-80">
      <div class="confirm-list">
        <p>
          <img src="/image/character_hat_sad.svg" alt="캐릭터">
          <span class="color-darkgray-4F4F4F">
            {{ payAmount | numberWithCommas }}
            <i> 원</i>
            <br/>
            결제실패
          </span>
          <em>결제가 실패되었습니다.<br/>다시 한번 시도하세요.</em>
        </p>
        <ul class="price-list">
          <li>
          </li>
          <li>
            <strong>결제일시</strong>
            <em>{{ new Date() | moment('YYYY-MM-DD HH:mm:ss') }}</em>
          </li>
          <li class="b-none">
          </li>
        </ul>
      </div>
    </div>
    <div class="align-bottom d-flex-row w-100">
      <button class="btn-gray mr-auto btn-half" @click="$router.go(-2)">취소</button>
      <button class="btn-pink ml-auto btn-half" @click="$router.back()">다시 결제</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QRCardPaymentFail",
  data() {
    return {
      payAmount: 0,
    }
  },
  mounted() {
    this.payAmount = this.$route.params.amount
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
