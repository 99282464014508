<template>
  <div class="all-content">
    <header class="header-wrap">
    </header>
    <div class="content-wrap bg-white content-row pb-80">
      <div class="confirm-list">
        <p>
          <img src="/image/character_fast.svg" class="img-fast" alt="캐릭터">
          <span>
            <i>신속하게 결제중입니다.</i>
          </span>
          <em>잠시만 기다려주세요.</em>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QRPaymentFast",
  data() {
    return {
      qr: '',
      amount: '',
    }
  },
  mounted() {
    //결제 여부 파악 후 화면 이동(성공, 실패)
    this.qr = this.$route.params.qr
    this.amount = this.$route.params.amount

    this.getPaymentState()
  },
  methods: {
    getPaymentState() {
      let formData = new FormData()
      formData.append('qr', this.qr)
      formData.append('amount', this.amount)

      //읽은 qr코드, 금액을 백으로 넘겨서 결과 받아올것
      this.$post(this.$PAYMENT_QR, 'qrscaninputacmount_paymentqr', formData, false,(result) => {
        // 결제성공
        this.$router.replace({name:'QRPaymentSuccess', params: { payAmount: this.amount, paymentInfo: JSON.stringify(result.data) }})

      }, (result) => { // 결제 실패
          this.httpError(result.data)
          this.$router.replace({name: 'QRPaymentFail', params:{ amount: this.amount }})
      })
    },
  }
}
</script>

<style scoped>

</style>
