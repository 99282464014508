<template>
  <div class="all-content">
    <div class="intro-wrap bg-white content-row">
      <div class="confirm-list">
        <p>
          <img src="/image/character_cheer.svg" alt="캐릭터">
          <em class="t-c color-darkgray-4F4F4F fz-25 weight-normal mt-20">아쉽지만,</em>
          <strong class="t-c color-darkgray-4F4F4F fz-25 weight-normal mt-20">다음에 더 좋은 모습으로 만나요!</strong>
        </p>
      </div>
    </div>
    <button @click="$router.replace({ name: 'BeforeLogin' })" class="align-bottom btn btn-m btn-pink">확 인</button>
  </div>
</template>

<script>
export default {
  name: "LeaveSuccess"
}
</script>

<style scoped>

</style>
