<template>
  <div class="all-content overflow-x-hidden bg-navy">
    <header class="main-header-wrap bg-navy">
      <button type="button" class="btn-user">
        <img class="profile" :src="item.path ? item.path : '/image/user.svg'" alt="사용자 사진">
      </button>
      <button type="button" class="btn-search" @click="$router.push({name:'SearchHistory'})">
        <img src="/image/icon_search.svg" alt="찾기">
      </button>
      <button type="button" class="btn-alram" @click="$router.push({name:'NotificationList'})">
        <img :src="item.newNotification ? '/image/alram_on.svg' : '/image/alram_off.svg'" alt="알림 off">
      </button>
      <span class="span-name">{{ item.businessName }}</span>
      <p class="span-id" @click="$router.push({ name : 'MyPage' })">
        {{ item.account }}
        <i class="icon-arrow-right pl-5"></i>
      </p>
    </header>
    <div class="main-wrap-footer">
      <div class="main-wrap overflow-x-clip">
        <div class="mb-12 section-radius bg-white d-flex" @click="goPaynara">
          <div class="d-flex-row">
            <div style="padding: 20px 0 17px 20px">
              <span class="txt2">결제통합 솔루션<br></span>
              <span class="txt1">ALL IN ONE, 결제나라</span>
              <br/>
              <button class="dream-button">이용안내<i class="icon-arrow-right ml-3 fz-10"></i></button>
            </div>
            <div class="ml-auto pt-10 pr-0">
              <img src="/image/main_banner.png">
            </div>
          </div>
        </div>

        <section class="section-radius bg-pink mb-10" @click="goReapPay">
          <div class="d-flex-row">
            <div style="padding: 17px 20px 17px 20px">
              <span class="static">다양한 결제수단, 매출 관리와 정산<br></span>
              <span class="mr-auto color-white fz-18 t-c weight-500" style="line-height: 30px;">페이허브</span>
            </div>
            <div class="ml-auto pt-10 pr-20">
              <img src="/image/icon-analysis.svg">
            </div>
          </div>
        </section>

        <div class="d-flex-row w-100 mb-12">
          <div class="d-flex-row grid bg-img"
               style="width: calc((100vw - 60px) / 3 * 2 + 10px) !important; background-image: url(../image/bg-quickcharge.svg);">
            <button class="color-white fz-16 w-50 t-l pl-13 pt-40"
                    style="aspect-ratio: 2;"
                    @click="goCharge">배달비<br>충전
            </button>
            <button class="color-white fz-16 w-50 pl-13 pt-40 t-l"
                    @click="showSelectRentType">월세<br>결제
            </button>
          </div>
          <div
              style="width: calc((100vw - 60px) / 3) !important; background-image: url(../image/bg-quickhistory.svg);"
              class="ml-10 grid bg-img menu-ratio">
            <button class="color-white fz-16 w-100 h-100 pl-13 pt-40 t-l"
                    @click="$router.push({ name: 'QuickPaymentHistory' })">배달비/월세<br>결제내역
            </button>
          </div>
        </div>

        <div class="d-flex-row w-100 mb-12">
          <div class="section-radius bg-purple grid pay-con menu-ratio"
               @click="$router.push({ name: 'QRScanInputAmount' })">
            <img src="/image/icon-qrpayment.svg" class="ml-13 pt-15">
            <button class="mt-10">QR 립페이<br>결제</button>
          </div>
          <div class="section-radius bg-purple ml-10 grid pay-con"
               @click="$router.push({ name: 'QRCardScanInputAmount' })">
            <img src="/image/icon-qrcardpay.svg" class="ml-13 pt-15">
            <button class="mt-10">QR 신용카드<br>결제</button>
          </div>
          <div class="section-radius bg-purple ml-10 grid pay-con" @click="$router.push({ name: 'SMSPayment' })">
            <img src="/image/icon-kakaopayment.svg" class="ml-13 pt-15">
            <button>카톡 결제</button>
          </div>
        </div>

        <div class="d-flex-row w-100">
          <div class="section-radius bg-purple grid pay-con" @click="$router.push({ name: 'HandPayment' })">
            <img src="/image/icon-handpayment.svg" class="ml-13 pt-15">
            <button>수기 결제</button>
          </div>
          <div class="section-radius bg-purple ml-10 grid pay-con menu-ratio"
               @click="$router.push({ name: 'TerminalPayment' })">
            <img src="/image/icon-terminalpayment.svg" class="ml-13 pt-15">
            <button>단말기 결제</button>
          </div>
          <div class="section-radius bg-gray ml-10 grid pg-con" @click="$router.push({ name: 'PGPaymentHistory' })">
            <button>PG 결제내역<br>확인하기</button>
            <img src="/image/icon-pghistory.svg" alt="화살표"/>
          </div>
        </div>
      </div>
    </div>

    <pin-check
        ref="PinCheck"
        :title="'PIN 확인'"
        :amount="0"
        :unit="''"
        :sub-amount="0"
        :sub-unit="''"
        :show-close="false"
    />

    <rent-type-select
        v-show="showRentType"
        :conditions="typeList"
        @selected="setSelectedType"
        @close="showRentType = false"
    />

    <form id="nice-form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb" method="post">
      <input type="hidden" name="m" value="checkplusService">
      <input type="hidden" name="EncodeData" id="nice-enc">
    </form>

  </div>
</template>

<script>
import PinCheck from "@/components/common/PinCheck";
import RentTypeSelect from "@/components/main/RentTypeSelect";

export default {
  name: "Main",
  components: {
    RentTypeSelect
    , PinCheck
  },
  data() {
    return {
      item: {
        idx: null,
        businessName: '',
        account: '',
        path: '',
        pin: false,
      },

      typeList: [
        {
          groupType: '0',
          name: '월세',
        },
        {
          groupType: '1',
          name: '관리비',
        },
        {
          groupType: '2',
          name: '보증금',
        },
        {
          groupType: '3',
          name: '수리비',
        },
        // {
        //   groupType: '4',
        //   name: '교육비',
        // },
        {
          groupType: '5',
          name: '기타',
        },
      ],
      showRentType: false,
      nice: '',
      authedCI: true,
      historyLength: false,
    }
  },
  mounted() {
    if (localStorage.getItem('checkedCI') !== '1') {
      this.getCIRegistInfo()  // ci 등록여부 조회
    } else {
      this.getMainInfo()
    }
    this.getRentLength()

    if (this.$route.params.loadPage) {
      // this.$router.push({name: this.$route.params.loadPage, params: { linkIdx : this.$route.params.linkIdx }})
      let loadPage = this.$route.params.loadPage
      let linkIdx = this.$route.params.linkIdx
      if (loadPage === 'ChargePaymentHistoryDetail') { // 배달비(상세)
        this.$router.push({name: loadPage, params: {quickIdx: linkIdx}})
      } else if (loadPage === 'ChargeSalesHistoryDetail') {  // 월세(상세)
        this.$router.push({name: loadPage, params: {monthlyPaymentIdx: linkIdx}})
      } else if (loadPage === 'PaymentHistoryDetail') {  // 결제(상세)
        this.$router.push({name: loadPage, params: {idx: linkIdx}})
      } else if (loadPage === 'NoticeList') { // 공지사항
        this.$router.push({name: loadPage})
      } else if (loadPage === 'QnaList') { // QNA
        this.$router.push({name: loadPage, params: {idx: linkIdx}})
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_CHECK_COMPLETED_AUTH, this.checkCompletedCI)
  },
  methods: {
    getRentLength() {
      this.historyLength = false
      this.$get(this.$LOG_MONTHLY, this.$options.name + '_getRentPaymentList', (result) => {
        console.log(result.data)
        console.log(result.data.length)
        this.historyLength = result.data.length > 0;
      }, (result) => {
        this.httpError(result.data)
      })
    },
    checkCompletedCI() {
      if (!this.authedCI && this.nice === '') {
        this.notify('error', '본인인증 후 이용가능합니다.', false)
        this.$router.replace({name: 'BeforeLogin'})
      }
    },
    getCIRegistInfo() {
      this.$get(this.$AUTH_CI, 'main_ci', (result) => {
        if (result.data) {
          this.getMainInfo()

        } else { // ci 등록 안되어있음
          this.$root.$refs.Alert.showAlert('',
              '본인인증이 되어있지 않은 계정이라,<br/>본인인증이 필요합니다.',
              () => {
                window.addEventListener(this.$EVENT_CHECK_COMPLETED_AUTH, this.checkCompletedCI)

                this.authedCI = false
                this.niceAuth()
              }, false, false)
        }
      }, (result) => {
        this.httpError(result.data)
      })
    },
    getMainInfo() {
      localStorage.setItem('checkedCI', '1')

      this.$get(this.$MAIN_INFO, 'main_info', (result) => {
        this.item = result.data
        console.log(result.data, 'main data')
        if (this.item.pin !== true) {
          this.$router.push({name: 'SignUpPinRegist'})

        } else if (localStorage.getItem('firstPinChecked') !== '1') {
          //로그인, 자동 로그인 후 첫 메인에서 핀체크 한번
          this.$refs.PinCheck.showPin(() => {
            localStorage.setItem('firstPinChecked', '1')
            this.$refs.PinCheck.$emit(this.$EVENT_CLOSE_PINCHECK)

            this.$parent.$data.readyMain = true // 메인화면 load완료 값 변경
          })

        } else {
          this.$parent.$data.readyMain = true // 메인화면 load완료 값 변경
        }

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goLinkPage(){

    },
    goPaynara() {
      window.open('https://paynara.co.kr/#/guide/1')
    },
    goReapPay() {
      window.open('https://payhub.co.kr/')
    },
    showSelectRentType() {
      if(this.historyLength){
        this.$router.push({ name: 'RentBeforePayment' })
      } else {
        this.showRentType = true
      }
    },
    setSelectedType(_type) {
      this.showRentType = false

      this.$router.push({
        name: 'RentPayment', params: {
          groupType: _type.groupType,
          randomVal: Math.random().toString(),
          type: '0', //등록
        }
      })
    },
    goCharge() {
      // 계좌정보 조회
      this.$get(this.$ACCOUNT_APPROVED, 'main_checkaccount', (result) => { // 계좌정보 있으면, 충전화면으로 이동
        if(result.data.length > 0) {
          this.$router.push({name: 'Charge'})  // 충전
        } else {
          this.notify('error', '배대사 등록 후 이용가능합니다.', false)
          this.$router.push({name: 'DeliveryAgencyList'})
        }
      }, () => {
        this.notify('error', '배대사 등록 후 이용가능합니다.', false)
        this.$router.push({name: 'DeliveryAgencyList'})
      })
    },
    niceAuth() {
      this.$niceAuth((result) => {
        this.niceShow(result.enc_data)

      }, (result) => {
        console.log(result)
      })
    },
    niceShow(enc_data) {
      window.removeEventListener('message', this.niceDone)

      window.open('', 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
      window.$('#nice-enc').val(enc_data)
      window.$('#nice-form').attr('target', 'popupChk')
      window.$('#nice-form').submit()

      window.addEventListener('message', this.niceDone);
    },
    niceDone(e) {
      if (e.origin === 'https://nice.payhub.co.kr') {
        this.nice = e.data.payload

        window.removeEventListener('message', this.niceDone)

        this.niceCheck()
      }
    },
    niceCheck() {
      let formData = new FormData()
      formData.append('auth', this.nice)

      this.$post(this.$AUTH_CI, 'main_authci', formData, true, () => {
        this.authedCI = true
        this.getMainInfo()

      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({name: 'BeforeLogin'})
      })
    },
  }
}

</script>

<style scoped>

/*.bg-image {*/
/*  background-image: url("/image/main_banner.png");*/
/*}*/

.dream-button {
  margin-top: 30px;
  padding: 5px 12px;
  background: linear-gradient(264.9deg, #7B61FF -9.03%, #007FCD 112.17%);
  color: white;
  border-radius: 20px;
  font-size: 12px;
  line-height: 21px;
}

.grid {
  height: calc((100vw - 60px) / 3) !important;
}

.bg-img {
  background-size: contain;
  background-repeat: no-repeat;
}

.menu-ratio {
  aspect-ratio: 1;
}

</style>
