<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="history-select-popup-list">
      <h5>조회 월 선택</h5>
      <div class="d-flex-row jc-center" style="height: calc(100% - 110px);">
        <ul id="yearScroll">
          <li v-for="(year) in years" :key="year.date">
            <a @click="selectedYear(year)">
              <span :class="[ year.date === pickedYear ? 'active-selectedMonth' : 'not-active-selectedMonth' ]">
                {{ year.name }}
              </span>
            </a>
          </li>
        </ul>
        <ul id="monthScroll">
          <li v-for="(month) in months" :key="month.date">
            <a @click="selectedMonth(month)">
              <span :class="[ month.date === pickedMonth ? 'active-selectedMonth' : 'not-active-selectedMonth' ]">
                {{ month.name }}
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div class="d-flex-row">
        <button class="btn-gray mr-auto btn-half" @click.self="$emit('close')">취소</button>
        <button class="btn-pink ml-auto btn-half" @click="searchMonth()">확인</button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryMonthSelect",
  props: ['years', 'months'],
  data() {
    return {
      pickedYear : '',
      pickedMonth : '',
    }
  },
  mounted() {
    let current = new Date()
    this.pickedYear = String(current.getFullYear())
    let month = current.getMonth() + 1
    this.pickedMonth = month < 10 ? '0' + month : month
  },

  methods: {
    selectedYear(_year) {
      this.$emit('selectedYear', _year)
      this.pickedYear = _year.date
    },

    selectedMonth(_month) {
      this.$emit('selectedDate', _month)
      this.pickedMonth = _month.date
    },

    searchMonth() {
      this.$emit('selected', this.pickedYear, this.pickedMonth)
      this.$emit('close')
    },
  },
  watch: {
    '$parent.showMonthlyList': {
      handler: function(val) {
        if (val) {
          let yearIndex = 0
          let monthIndex = 0
          for (let i=0;i<this.years.length;i++) {
            if (this.years[i].date === this.pickedYear) {
              yearIndex = i
              break
            }
          }
          window.document.getElementById('yearScroll').scrollTop = yearIndex * 72

          for (let i=0;i<this.months.length;i++) {
            if (this.months[i].date === this.pickedMonth) {
              monthIndex = i
              break
            }
          }
          window.document.getElementById('monthScroll').scrollTop = monthIndex * 72
        }
      }
    }
  }

}
</script>

<style scoped>

</style>
