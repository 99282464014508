<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>기타 정보</p>
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pb-80">
        <p class="fz-16">배달앱</p>
        <div class="charge-form">
          <div v-for="(delivery, index) in deliveryAccountList" :key="index">
            <p class="ml-10 color-gray fz-13" :class="{ 'pt-5' : index === 0, 'pt-25' : index > 0 }">
              {{ delivery.name }}</p>
            <div class="d-flex-row mt-5">
              <div class="select">
                <RPInputBorder
                    :type="'text'"
                    :model="delivery.id"
                    :placeholder="'아이디'"
                    @update="(_model) => {delivery.id=_model}"
                />
              </div>
              <div class="select ml-10">
                <RPInputBorder
                    :type="'password'"
                    :model="delivery.password"
                    :placeholder="'비밀번호'"
                    @update="(_model) => {delivery.password=_model}"
                />
              </div>
            </div>
            <span class="color-red">{{ deliveryErrors[index].error }}</span>
          </div>
        </div>
        <p class="mt-30 fz-16">카드매출</p>
        <div class="charge-form">
          <p class="ml-10 color-gray fz-13">여신금융협회<span class="color-red">*</span></p>
          <div class="d-flex-row mt-5">
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :model="creditId"
                  :placeholder="'아이디'"
                  @update="(_model) => {this.creditId=_model}"
              />
            </div>
            <div class="select ml-10">
              <RPInputBorder
                  :type="'password'"
                  :model="creditPw"
                  :placeholder="'비밀번호'"
                  @update="(_model) => {this.creditPw=_model}"
              />
            </div>
          </div>
          <button class="fz-13 mt-10 ml-auto" style="display: block;" @click="goCreditHP">여신금융협회 회원가입하러가기 <i
              class="icon-arrow-right"></i>
          </button>
        </div>
        <p class="mt-30">VAN사 정보</p>
        <div class="tab-btn">
          <ul>
            <li :class="{ 'active-left' : selectedTab === 0 }"><a @click="selectedTab = 0">직접입력</a></li>
            <li :class="{ 'active-right' : selectedTab === 1 }"><a @click="selectedTab = 1">사진업로드</a></li>
          </ul>
        </div>
        <div class="charge-form pt-0" v-if="selectedTab === 0">
          <div v-for="(van, index) in vanAccountList" :key="index">
            <div class="border-bottom mt-20" v-show="index > 0"></div>
            <div class="select-wrap" :class="{ 'mt-20' : index > 0}">
              <button class="select" v-if="van.idx !== null" @click="openVanList(index)">{{ van.name }}
              </button>
              <button class="select color-gray" v-else @click="openVanList(index)">VAN사를 선택해주세요</button>
            </div>

            <div class="d-flex-row mt-10">
              <div class="select">
                <RPInputBorder
                    :type="'text'"
                    :model="van.id"
                    :placeholder="'아이디'"
                    @update="(_model) => {van.id=_model}"
                />
              </div>
              <div class="select ml-10">
                <RPInputBorder
                    :type="'password'"
                    :model="van.password"
                    :placeholder="'비밀번호'"
                    @update="(_model) => {van.password=_model}"
                />
              </div>
            </div>
            <span class="color-red">{{ vanErrors[index].error }}</span>
          </div>

          <div class="d-flex-row mt-10">
            <button class="mr-auto btn-van-account"
                    v-show="vanAccountList.length > 1" @click="deleteVanAccountList">- 삭제
            </button>
            <button class="ml-auto btn-van-account"
                    v-show="vanAccountList.length < 5" @click="addVanAccountList">+ 추가
            </button>
          </div>

        </div>

        <div class="charge-form pt-0" v-else>
          <button class="fz-13 mb-20 ml-auto" style="display: block;" @click.self="openVanInfoPopup">VAN사 정보
            확인 방법 <i class="icon-arrow-right"/>
          </button>
          <div class="d-flex-row" :class="{ 'mt-10' : index > 0 }" v-for="(file, index) in vanFileList" :key="index">
            <span class="color-darkgray ml-10 w-20" style="align-self: center">{{ file.title }}</span>

            <div class="input-box question-input-box d-flex-row w-60">
              <input type="text" class="w-100" style="background: transparent" readonly
                     v-model="file.fileName"/>
              <button v-show="file.fileName !== ''" type="button" class="btn-delete-van-file"
                      @click="deleteVanFileList(index)"><i
                  class="icon-close color-darkgray"></i></button>
            </div>

            <input ref="fileUploader" type="file" class="input-box question-input-box w-60" @change="upload($event, index)"
                   v-bind:id="'imageFileUpload0' + (index+1)" accept="image/*" hidden>
            <button class="w-20" style="align-self: center"
                    @click="uploadImage(index)"><img src="/image/upload_file.svg">
            </button>
          </div>
          <span class="color-red">{{ errors.vanFiles }}</span>
          <button class="btn-add-van-file"
                  v-show="vanFileList.length < 5"
                  @click="addVanFileList">+ 추가
          </button>
        </div>

      </div>
      <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="goNext">다음</button>
    </div>

    <van-select
        v-show="showVanList"
        :van-list="vanList"
        @selected="setSelectedVan"
        @close="showVanList=false"
    />

    <van-info-confirm
        v-show="showVanInfoPopup"
        @close="showVanInfoPopup=false"
    />
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import VanSelect from "@/components/user/signup/VanSelect";
import VanInfoConfirm from "@/components/user/signup/VanInfoConfirm";

export default {
  name: "SsoSignUp02",
  components: {
    VanInfoConfirm,
    VanSelect,
    RPInputBorder,
  },
  data() {
    return {
      id: '',
      password: '',
      shopName: '',
      shopHp: '',
      name: '',
      business: '',
      address: '',
      addressDetail: '',
      zipcode: '',
      email: '',
      branchCode: '',
      auth: '',
      files: [],

      creditId: '',
      creditPw: '',
      checkedCredit: false,

      deliveryList: [],
      deliveryAccountList: [],

      selectedTab: 0,

      showVanList: false,
      selectedVanIndex: 0,
      vanList: [],
      vanAccountList: [
        {
          // vanCode: '',
          idx: null,
          name: '',
          id: '',
          password: '',
          checked: false,
        },
      ],

      showVanInfoPopup: false,
      vanFileList: [
        {
          title: '사진첨부',
          fileName: '',
          file: null,
        }
      ],

      deliveryErrors: [],
      vanErrors: [{error: ''}],

      errors: {
        credit: '',
        vanFiles: '',
      }
    }
  },
  mounted() {
    // 이전 값(아이디, 비밀번호, 상호명, 대표자명, 사업자 등록번호, 사업자 주소, 필요서류(3개))
    this.auth = this.$route.params.auth
    this.id = this.$route.params.id
    this.password = this.$route.params.password
    this.shopName = this.$route.params.shopName
    this.shopHp = this.$route.params.shopHp
    this.name = this.$route.params.name
    this.business = this.$route.params.business
    this.address = this.$route.params.address
    this.addressDetail = this.$route.params.addressDetail
    this.zipcode = this.$route.params.zipcode
    this.email = this.$route.params.email
    this.branchCode = this.$route.params.branchCode
    this.auth = this.$route.params.auth
    this.files = this.$route.params.files

    this.getDeliveryList()
    this.getVanList()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'SignUp01') {
        vm.clearData()
      }
    })
  },
  methods: {
    clearData() {
      this.auth = this.$route.params.auth
      this.id = this.$route.params.id
      this.password = this.$route.params.password
      this.shopName = this.$route.params.shopName
      this.shopHp = this.$route.params.shopHp
      this.name = this.$route.params.name
      this.business = this.$route.params.business
      this.address = this.$route.params.address
      this.addressDetail = this.$route.params.addressDetail
      this.zipcode = this.$route.params.zipcode
      this.email = this.$route.params.email
      this.branchCode = this.$route.params.branchCode
      this.auth = this.$route.params.auth
      this.files = this.$route.params.files

      if (this.$route.params.isClear){
        this.creditId = ''
        this.creditPw = ''

        this.checkedCredit = false

        this.deliveryList = []
        this.deliveryAccountList = []

        this.selectedTab = 0

        this.showVanList = false
        this.selectedVanIndex = 0

        this.vanList = []
        this.vanAccountList = [
          {
            // vanCode: '',
            idx: null,
            name: '',
            id: '',
            password: '',
            checked: false,
          }
        ]

        this.showVanInfoPopup = false
        this.vanFileList = [
          {
            title: '사진첨부',
            fileName: '',
            file: null,
          }
        ]

        this.deliveryErrors = []
        this.vanErrors = [{error: ''}]
        this.errors = {
          credit: '',
          vanFiles: '',
        }
      }

      this.getDeliveryList()
      this.getVanList()
    },
    getDeliveryList() {
      this.$get(this.$DELIVERY, 'signup02_delivery',(result) => {
        this.deliveryList = result.data

        for (let i = 0; i < this.deliveryList.length; i++) {
          this.deliveryAccountList.push({
            idx: this.deliveryList[i].idx,
            name: this.deliveryList[i].name,
            id: '',
            password: '',
            checked: false
          })

          this.deliveryErrors.push({error: ''})
        }

        /*test start*/
        // this.deliveryErrors[0].error = '테스트'
        /*test end*/

      }, (result) => {
        this.httpError(result.data)
      })
    },
    getVanList() {
      this.$get(this.$VAN, 'signup02_van',(result) => {
        this.vanList = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
    openVanList(_index) {
      this.selectedVanIndex = _index
      this.showVanList = true
    },
    setSelectedVan(_van) {
      this.vanAccountList[this.selectedVanIndex].idx = _van.idx
      this.vanAccountList[this.selectedVanIndex].name = _van.name
      this.showVanList = false
    },
    goCreditHP() {
      window.location.href = ('https://www.cardsales.or.kr/page/member/join/joinForm')
    },
    addVanAccountList() {
      this.vanAccountList.push({idx: null, name: '', id: '', password: '', checked: false})
      this.vanErrors.push({error: ''})
    },
    deleteVanAccountList() {
      this.vanAccountList.pop()
    },
    openVanInfoPopup() {
      this.showVanInfoPopup = true
    },
    addVanFileList() {
      this.vanFileList.push({title: '', fileName: '', file: null})
    },
    deleteVanFileList(_index) {
      if (this.vanFileList.length === 1 && (_index === 0)) {
        this.vanFileList[0].title = '사진첨부'
        this.vanFileList[0].fileName = ''
        this.vanFileList[0].file = null
      } else {
        this.vanFileList.splice(_index, 1)
        this.vanFileList[0].title = '사진첨부'
      }
    },
    uploadImage(_index) {
      window.document.getElementById('imageFileUpload0' + (_index + 1)).click()
    },
    upload(e, _index) {
      // if (this.isEdit && this.item.fileIdx) {
      //   this.deleteFileIdx = this.item.fileIdx
      // }
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      this.vanFileList[_index].file = files[0]
      this.vanFileList[_index].fileName = files[0].name

      this.$refs.fileUploader[_index].value = ''
    },
    goNext() {
      // for (let i=0 ; i < this.deliveryAccountList.length ; i ++){
      //   if (this.deliveryAccountList[i].deliveryAccount !== '' && this.deliveryAccountList[i].deliveryPassword !== '' && !this.deliveryAccountList[i].checked) {
      //     this.notify('error', this.deliveryAccountList[i].deliveryName + ' 계정을 확인해주세요.')
      //     return
      //   }
      // }

      // 여신 필수!
      if (this.creditId === '' || this.creditPw === '') {
        this.notify('error', '여신금융협회 계정을 입력해주세요.')
        return
      }

      // if (!this.checkedCredit) {
      //   this.notify('error', '여신금융협회 계정을 확인해주세요.')
      //   return
      // }

      // let sendVanFileList = ''
      if (this.selectedTab === 0) { // van 직접입력
        // for (var i = 0; i < this.vanAccountList.length; i++) {
        //   if (this.vanAccountList[i].vanAccount !== '' && this.vanAccountList[i].vanPassword !== '' && !this.vanAccountList[i].checked) {
        //     this.notify('error', 'VAN 계정을 확인해주세요.')
        //     return
        //   }
        // }
      } else {  // 파일 첨부
        // for (let i=0 ; i < this.vanFileList.length ; i ++){
        //   sendVanFileList.push(this.vanFileList[i].file)
        // }
      }

      // 필수 : 아이디, 비밀번호, 상호명, 대표자명, 사업자 등록번호, 사업장 주소(우편번호, 주소), 사업장 연락처, 이메일, 필요서류(3개), 결제정보(은행, 계좌번호, 예금주명), 여신계정
      // 선택 : 주소(상세주소), 기타정보(배달의민족, 요기요, 쿠팡잇츠 계정, van사 계정 리스트, van사 사진 리스트)

      // 회원가입(step02)
      let formData = new FormData()
      let sendDeliveryAccountList = []
      this.deliveryAccountList.forEach((delivery) => {
        if (delivery.idx !== null && delivery.id !== '' && delivery.password !== '') {
          sendDeliveryAccountList.push(delivery)
        }
      })

      if (sendDeliveryAccountList.length > 0) {
        sendDeliveryAccountList.forEach((van, index) => {
          formData.append(`delivery[${index}].idx`, van.idx);
          formData.append(`delivery[${index}].id`, van.id);
          formData.append(`delivery[${index}].password`, van.password);
        })
      }

      // this.deliveryAccountList.forEach((delivery, index) => {
      //   // if (delivery.idx !== null && delivery.id !== '' && delivery.password !== ''){
      //     formData.append(`delivery[${index}].idx`, delivery.idx);
      //     formData.append(`delivery[${index}].id`, delivery.id);
      //     formData.append(`delivery[${index}].password`, delivery.password);
      //   // }
      // })

      let sendVanAccountList = []
      this.vanAccountList.forEach((van) => {
        if (van.idx !== null && van.id !== '' && van.password !== '') {
          sendVanAccountList.push(van)
        }
      })

      if (sendVanAccountList.length > 0) {
        sendVanAccountList.forEach((van, index) => {
          formData.append(`van[${index}].idx`, van.idx);
          formData.append(`van[${index}].id`, van.id);
          formData.append(`van[${index}].password`, van.password);
        })
      }

      formData.append('card.id', this.creditId)
      formData.append('card.password', this.creditPw)

      this.$post(this.$AUTH_SIGNUP_STEP02, 'signup02_signupstep02', formData, true,() => { // 회원가입(step02)
        // 성공시
        // 회원가입 처리 api
        let formData = new FormData()
        formData.append('id', this.id)
        formData.append('password', this.password)
        formData.append('passwordCk', this.password)
        formData.append('shopName', this.shopName)
        formData.append('shopHp', this.shopHp)
        formData.append('name', this.name)
        formData.append('business', this.business)
        formData.append('address', this.address)
        formData.append('addressDetail', this.addressDetail)
        formData.append('zipcode', this.zipcode)
        formData.append('email', this.email)
        formData.append('branchCode', this.branchCode)
        formData.append('auth', this.auth)
        formData.append('businessImg', this.files[0].file)  // 사업자등록증
        formData.append('bossIdImg', this.files[1].file)  // 대표자신분증
        // formData.append('accountImg', this.files[2].file) // 통장사본

        for (let i = 2; i < this.files.length ; i ++){
          if (this.files[i].file != null){
            formData.append('attachments', this.files[i].file)  // 추가 첨부서류
          }
        }

        // formData.append('delivery', JSON.stringify(this.deliveryAccountList))
        // formData.append('card', JSON.stringify(card))
        // formData.append('van', JSON.stringify(this.vanAccountList))

        if (sendDeliveryAccountList.length > 0) {
          sendDeliveryAccountList.forEach((van, index) => {
            formData.append(`delivery[${index}].idx`, van.idx);
            formData.append(`delivery[${index}].id`, van.id);
            formData.append(`delivery[${index}].password`, van.password);
          })
        }

        if (sendVanAccountList.length > 0) {
          sendVanAccountList.forEach((van, index) => {
            formData.append(`van[${index}].idx`, van.idx);
            formData.append(`van[${index}].id`, van.id);
            formData.append(`van[${index}].password`, van.password);
          })
        }
        formData.append('card.id', this.creditId)
        formData.append('card.password', this.creditPw)

        for (let i = 0; i < this.vanFileList.length; i++) {
          if (this.vanFileList[i].file !== null) {
            formData.append('vanImg', this.vanFileList[i].file)
          }
        }

        this.$post(this.$AUTH_SIGNUP, 'signup02_signup', formData, true,() => { // 회원가입
          // 회원가입 성공
          this.$router.push({name: 'SsoSignUpFinish'})

        }, (result) => {
          this.httpError(result.data)
        })

      }, (result) => {
        this.httpError(result.data)
      })
    },
    changeNextBtn() {
      if (this.creditId !== '' && this.creditPw !== '') {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-pink"
      } else {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      }
    }
  },
  watch: {
    creditId() {
      this.changeNextBtn()
    },
    creditPw() {
      this.changeNextBtn()
    }
  }
}
</script>

<style scoped>
.btn-van-account {
  border: 1px solid #C4C4C4;
  width: 60px;
  height: 27px;
  border-radius: 50vh;
  color: #B8B8B8;
  font-size: 13px;
}

.btn-delete-van-file {
  position: relative;
  align-self: center;
  width: 24px;
  height: 24px;
  margin-left: 5px;
}

.btn-add-van-file {
  border: 1px solid #C4C4C4;
  width: 60px;
  height: 27px;
  border-radius: 50vh;
  display: block;
  margin-left: auto;
  margin-top: 10px;
  color: #B8B8B8;
}

</style>
