<template>
  <div class="all-content bg-white">
    <div class="content-wrap">
      <div class="main-wrap pb-80 content-row b-0" style="height: calc(100% - 52px);">
        <div class="charge-form">
          <em class="pink-title">{{ typeName }}명 변경</em>

          <RPInput class="mt-30"
              :type="'text'"
              :placeholder="placeHolder"
              :model="rentName"
              @update="(_model) => {this.rentName=_model}"
          />

        </div>
      </div>

      <div class="d-flex-row">
        <button class="btn-gray mr-auto btn-half" @click="$router.back()">취소</button>
        <button class="btn-pink ml-auto btn-half" @click="changeRentName">변경</button>
      </div>

    </div>
  </div>
</template>

<script>
import RPInput from "../../common/RPInput";
export default {
  name: "ChangeRentName",
  components: {RPInput},
  data() {
    return {
      typeName: '월세',
      placeHolder: '변경할 월세명을 입력해주세요.',
      rentName: '',
      monthlyPaymentIdx : 1,
      errors: {
        rentName: '',
      }
    }
  },
  mounted() {
    this.rentName = this.$route.params.oldRentName
    this.monthlyPaymentIdx = this.$route.params.monthlyPaymentIdx

    let type = this.$route.params.type
    if (type === '1') {
      this.typeName = '관리비'
    } else if (type === '2') {
      this.typeName = '보증금'
    } else if (type === '3') {
      this.typeName = '수리비'
    } else if (type === '5') {
      this.typeName = '기타'
    }
    this.placeHolder = '변경할 ' + this.typeName + '명을 입력해주세요.'

  },
  methods: {
    errorClear() {
      this.errors = {
        rentName: '',
      }
    },
    changeRentName() {
      this.errorClear()

      let formData = new FormData()
      formData.append('monthlyPaymentIdx', this.monthlyPaymentIdx)
      formData.append('name', this.rentName)
      this.$put(this.$MONTHLY_RENAME.replace('?', this.monthlyPaymentIdx), this.$options.name + '_changeRentName', formData, true,() => {
        this.notify('success', '성공적으로 변경되었습니다.', false)
        this.$router.back()
        // this.$router.replace({name: 'ChargeSalesHistoryDetail', params: { monthlyPaymentIdx : this.monthlyPaymentIdx, isPaying: this.$route.params.iPaying, type: this.$route.params.type } })
      }, (result) => {
        // this.notify('error', '월세명 변경에 문제가 발생했습니다.')
        this.httpError(result.data)
      })
    }
  }
}
</script>

<style scoped>

</style>