<template>
  <div class="all-content">
    <div class="certification-wrap">
      <div class="certification-box bg-pink">
        <span>PIN</span>
        <p class="mb-30">PAY 결제 시 2차 인증에 필요합니다.<br>
        핀번호를 입력하세요.</p>
        <div class="certification-bullets">
          <span :class="{'bullets-active' : pin.length >= 1}"></span>
          <span :class="{'bullets-active' : pin.length >= 2}"></span>
          <span :class="{'bullets-active' : pin.length >= 3}"></span>
          <span :class="{'bullets-active' : pin.length >= 4}"></span>
          <span :class="{'bullets-active' : pin.length >= 5}"></span>
          <span :class="{'bullets-active' : pin.length >= 6}"></span>
        </div>
      </div>
      <div class="number-area">
        <ul>
          <li>
            <button @click="numBtn('1')">1</button>
            <button @click="numBtn('2')">2</button>
            <button @click="numBtn('3')">3</button>
          </li>
          <li>
            <button @click="numBtn('4')">4</button>
            <button @click="numBtn('5')">5</button>
            <button @click="numBtn('6')">6</button>
          </li>
          <li>
            <button @click="numBtn('7')">7</button>
            <button @click="numBtn('8')">8</button>
            <button @click="numBtn('9')">9</button>
          </li>
          <li>
            <button @click="cancel()" class="cancel">취소</button>
            <button @click="numBtn('0')">0</button>
            <button @click="backspace()">
              <img src="/image/cancel.svg" alt="지우기">
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignUpPinRegist",
  data() {
    return {
      pin: '',
    }
  },
  methods: {
    numBtn(_num) {
      this.pin = this.pin + _num
      if (this.pin.length === 6 ) {
        this.$router.replace({name:'SignUpPinCheck', params:{old_pin:this.pin}})
      }
    },
    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },
    cancel() {
      this.$root.$refs.Confirm.showDialog('', '핀번호를 설정하지 않을경우<br/>결제나라를 정상적으로 사용할 수 없습니다.<br/>' +
          '서비스를 종료 하시겠습니까?', '확인', '취소', () => {
        //자동로그인 실패 처리(로컬 스토리지 토큰 비우기)
        sessionStorage.removeItem('accessToken')
        sessionStorage.removeItem('refreshToken')
        localStorage.removeItem('passToken')
        // localStorage.removeItem('useLocalBio')
        this.$router.replace({ name: 'Intro' })
      }, () => {

      })
    }
  }
}
</script>

<style scoped>

</style>