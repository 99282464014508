<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <p>재결제하기</p>
      <button type="button" class="btn-close" @click="$router.back()"><i class="icon-close"></i></button>
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pt-0">
        <div class="charge-form mb-50">
          <p class="mb-10 fz-18 color-darkgray-4F4F4F">납입 타입</p>
          <div class="select-disable t-c fz-16 color-darkgray" style="padding: 11px 0;">
            {{ repayData.type }}
          </div>

          <p class="mt-20 mb-10 fz-18 color-darkgray-4F4F4F">납입 예정일</p>
          <div class="select-disable t-c fz-16 color-darkgray" style="padding: 11px 0;">
            {{ repayData.date }}
          </div>

          <p class="mt-20 mb-10 fz-18">납입 상세</p>
          <div class="select-disable payment-detail">
            <div class="d-flex-row pb-15 jc-between">
              <span>납입금액</span>
              <span>{{ repayData.amount | numberWithCommas }} 원</span>
            </div>
            <div class="d-flex-row pb-15 jc-between border-bottom">
              <span>수수료</span>
              <span>+ {{ repayData.fee | numberWithCommas }} 원</span>
            </div>
            <div class="d-flex-row jc-between pt-15">
              <span>총 결제금액</span>
              <span>{{ repayData.total | numberWithCommas }} 원</span>
            </div>
          </div>

          <p class="mb-10 mt-30 fz-18">결제 카드</p>

          <div class="select-disable t-c fz-16 color-darkgray" style="padding: 11px 0;">
            {{ repayData.card + ' ' }}{{ repayData.cardNum | cardNumberMasking }}
          </div>

          <div class="payment-sign-box mt-40">
            <div class="checks-box">
              <div class="checks-circle">
                <input type="checkbox" id="autoLogin" @click="agree = !agree">
                <label for="autoLogin">약관 전체 동의</label>
              </div>
            </div>
            <div class="regist-box">
              <div class="checks" @click="goTerm(term.idx)" v-for="(term, index) in termList" :key="index">
                <input type="checkbox" v-bind:id="'chk' + (index + 1)" disabled v-model="agree">
                <label :for="'chk' + (index + 1)" class="color-gray-B8B8B8 fz-15">(필수) {{ term.name }}</label>
                <button type="button" class="btn"><i class="icon-arrow-right color-gray-B8B8B8"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="align-bottom btn btn-m btn-pink" @click="payment">결제</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationRentRepay",
  data() {
    return {
      idx: '',

      repayData: {
        type: '',
        date: '',
        amount: '',
        fee: '',
        total: '',

        card: '',
        cardNum: '',
        main: false,
      },

      agree: false,   // 약관동의

      errors: {
        agree: '',  // 약관동의
      },

      termList: [],
    }
  },
  mounted() {
    this.idx = this.$route.params.idx

    this.getRepay()
    this.getTermList()
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'RentTerm') {
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }

    next()
  },
  methods: {
    getRepay() { // 재결제
      this.$get(this.$MONTHLY_REPAYMENT + '/' + this.idx, 'notificationrentrepay_getrepay', (result) => {
        this.repayData = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
    getTermList() {
      this.$get(this.$TERMS, 'rentpaymentinfoinput_terms', (result) => {
        this.termList = result.data

      }, (result) => {
        this.httpError(result.data)
      })
    },
    goTerm(_index) {
      this.$router.push({name: 'RentTerm', params: {idx: _index}})
    },
    payment(){  // 재결제
      let formData = new FormData()
      formData.append('monthlyIdx', this.idx)

      this.$put(this.$MONTHLY_REPAYMENT + '/' + this.idx, 'notificationrentrepay_payment', formData, true, () => {
        this.notify('success', '재결제가 완료되었습니다.', false)
        this.$router.back()

      }, (result) => {
        this.httpError(result.data)
      })
    }
  }
}
</script>

<style scoped>

</style>