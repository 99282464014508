<template>
  <div class="all-content">
    <div class="bg-white content-row-new">
      <div class="confirm-list">
        <p>
          <img class="check-pink mb-30 mt-50" src="/image/check_pink.png" alt="캐릭터">
          <em class="pink-title mb-10">가입신청이 완료되었습니다.</em>
          <small class="t-c mt-10 fz-16 color-darkgray-4F4F4F">
            서류심사는 영업일 기준 3~4일 소요되며,<br>
            서류 재요청이 필요한 경우 유선, 문자, 메일로<br>
            연락을 드릴 수 있습니다 :)
          </small>
        </p>
        <p>
          <img src="/image/character_cheer.svg" alt="캐릭터">
        </p>
      </div>
    </div>
    <button class="btn-l btn-pink" @click="$router.push({name:'Login'})">확 인</button>
  </div>
</template>

<script>
export default {
  name: "SignUpFinish"
}
</script>

<style scoped>

</style>