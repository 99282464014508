<template>
  <div class="all-content bg-white contact-select">
    <header class="header-wrap border-bottom">
      <p>내 연락처에서 찾기</p>
      <button type="button" class="btn-close" @click="select"><i
          class="icon-close"></i></button>
    </header>
    <div class="content-wrap of-auto pb-30">
      <div class="main-wrap pb-25">
<!--        <p class="mb-10">ㄱ</p>-->
<!--        <div class="select mb-10 px-20" v-for="(contact, index) in contactList" :key="index">-->
<!--          <div class="input-close border-zero">-->
<!--            <div class="mt-15 checks-circle-black">-->
<!--              <input type="checkbox" :id="'chk' + index" v-model="contact.isChecked" @click="selectedContact(contact, index)">-->
<!--              <label :for="'chk' + index" class="fz-16">{{ contact.name }}</label>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="fixed-beginning" v-if="displayBeginning !== ''">
          <strong class="fz-15 color-darkgray">{{ displayBeginning }}</strong>
        </div>
        <div v-for="(char, index) in charCodeList" :key="index">
          <div v-if="char.dataCount > 0" :class="{ 'pt-30' : index > 0 }">
<!--            <strong :id="'charKeyDiv' + index" class="fz-15 color-darkgray" v-if="index === (charCodeList.length - 1)">#</strong>-->
            <strong :id="'charKeyDiv' + index" class="fz-15 color-darkgray">{{ char.key }}</strong>
            <div id="contactDiv" class="select mb-10 px-20" v-for="(contact, contactindex) in displayContactInfo[char.key]" :key="'contact' + contactindex" :class="{ 'mt-10' : contactindex === 0 }">
              <div class="input-close border-zero">
                <div class="mt-15 checks-circle-black">
                  <input type="checkbox" :id="'chk' + contact.idx" v-model="contact.isChecked"
                         @click="selectedContact(char, contact, contactindex)">
                  <label :for="'chk' + contact.idx" class="fz-16 color-darkgray-4F4F4F">{{ contact.name }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <button class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="select">총<span
        class="fz-18 color-yellow">{{ selectedContactCnt }}명</span> 선택완료
    </button>
  </div>
</template>

<script>
export default {
  name: "SMSContactSelect",
  data() {
    return {
      goodsName: '',  // 상품명
      amount: '', // 결제금액
      taxType: '',  // 과세여부(0: 과세, 1: 비과세)
      sendType: '', // 발송타입(0: 즉시발송, 1: 예약발송)
      sendTime: '', // 예약발송 시간
      memo: '', // 메모
      additionType: '', // 주소요청여부(0: 요청안함 1:주소요청)

      contactList: [],  // 연락처 리스트
      selectedContactCnt: 0,  // 연락처 선택 cnt
      clientContactList: [],  // 발송 전화번호 리스트

      charCodeList: [], // 자음 코드 리스트
      displayContactInfo: {},

      scrollY: 0,
      displayBeginning: '',
    }
  },
  mounted() {
    this.goodsName = this.$route.params.goodsName
    this.amount = this.$route.params.amount
    this.taxType = this.$route.params.taxType
    this.sendType = this.$route.params.sendType
    this.sendTime = this.$route.params.sendTime
    this.memo = this.$route.params.memo
    this.additionType = this.$route.params.additionType
    this.contactList = this.$route.params.contactList
    this.selectedContactCnt = this.$route.params.selectedContactCnt
    this.clientContactList = this.$route.params.clientContactList

    this.initDisplayContactList()

    window.addEventListener('scroll', this.onScrollEvent, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollEvent, true)
  },
  methods: {
    onScrollEvent(e){
      this.scrollY = e.target.scrollTop

      let showBeginning = false
      for(let i=0 ; i < this.charCodeList.length ; i++) {
        if (this.charCodeList[i].dataCount > 0) {
          if (this.scrollY >= window.document.getElementById('charKeyDiv' + i).offsetTop) {
            this.displayBeginning = this.charCodeList[i].key
            showBeginning = true
          }
        }
      }

      if (!showBeginning){
        this.displayBeginning = ''
      }
    },
    initDisplayContactList(){
      this.charCodeList =  [{"key":"ㄱ", "value":"가".charCodeAt(0), "dataCount":0},
        {"key":"ㄴ", "value":"나".charCodeAt(0), "dataCount":0},
        {"key":"ㄷ", "value":"다".charCodeAt(0), "dataCount":0},
        {"key":"ㄹ", "value":"라".charCodeAt(0), "dataCount":0},
        {"key":"ㅁ", "value":"마".charCodeAt(0), "dataCount":0},
        {"key":"ㅂ", "value":"바".charCodeAt(0), "dataCount":0},
        {"key":"ㅅ", "value":"사".charCodeAt(0), "dataCount":0},
        {"key":"ㅇ", "value":"아".charCodeAt(0), "dataCount":0},
        {"key":"ㅈ", "value":"자".charCodeAt(0), "dataCount":0},
        {"key":"ㅊ", "value":"차".charCodeAt(0), "dataCount":0},
        {"key":"ㅋ", "value":"카".charCodeAt(0), "dataCount":0},
        {"key":"ㅌ", "value":"타".charCodeAt(0), "dataCount":0},
        {"key":"ㅍ", "value":"파".charCodeAt(0), "dataCount":0},
        {"key":"ㅎ", "value":"하".charCodeAt(0), "dataCount":0},
        {"key":"힣", "value":"힣".charCodeAt(0), "dataCount":0},
        {"key":"A", "value":"A", "dataCount":0},
        {"key":"B", "value":"B", "dataCount":0},
        {"key":"C", "value":"C", "dataCount":0},
        {"key":"D", "value":"D", "dataCount":0},
        {"key":"E", "value":"E", "dataCount":0},
        {"key":"F", "value":"F", "dataCount":0},
        {"key":"G", "value":"G", "dataCount":0},
        {"key":"H", "value":"H", "dataCount":0},
        {"key":"I", "value":"I", "dataCount":0},
        {"key":"J", "value":"J", "dataCount":0},
        {"key":"K", "value":"K", "dataCount":0},
        {"key":"L", "value":"L", "dataCount":0},
        {"key":"M", "value":"M", "dataCount":0},
        {"key":"N", "value":"N", "dataCount":0},
        {"key":"O", "value":"O", "dataCount":0},
        {"key":"P", "value":"P", "dataCount":0},
        {"key":"Q", "value":"Q", "dataCount":0},
        {"key":"R", "value":"R", "dataCount":0},
        {"key":"S", "value":"S", "dataCount":0},
        {"key":"T", "value":"T", "dataCount":0},
        {"key":"U", "value":"U", "dataCount":0},
        {"key":"V", "value":"V", "dataCount":0},
        {"key":"W", "value":"W", "dataCount":0},
        {"key":"X", "value":"X", "dataCount":0},
        {"key":"Y", "value":"Y", "dataCount":0},
        {"key":"Z", "value":"Z", "dataCount":0},
        {"key":"#", "value":"#", "dataCount":0}]

      // this.displayContactInfo['#'] = []
      for(let i=0 ; i < this.charCodeList.length ; i++) {
        this.displayContactInfo[this.charCodeList[i].key] = []
      }

      for(let i=0 ; i < this.contactList.length ; i++) {
        let person = this.contactList[i].name
        let isContain = false
        for(let j=0 ; j < this.charCodeList.length; j++) {
          if(person.charCodeAt(0) >= this.charCodeList[j].value && person.charCodeAt(0) < this.charCodeList[j+1].value) {
            this.displayContactInfo[this.charCodeList[j].key].push({"idx": i, "name": person, "isChecked": this.contactList[i].isChecked, "phone": this.contactList[i].phone})
            this.charCodeList[j].dataCount += 1
            isContain = true
            break;
          } else if (person.substring(0,1).toUpperCase() === this.charCodeList[j].value){
            this.displayContactInfo[this.charCodeList[j].key].push({"idx": i, "name": person, "isChecked": this.contactList[i].isChecked, "phone": this.contactList[i].phone})
            this.charCodeList[j].dataCount += 1
            isContain = true
            break;
          }
        }
        if(!isContain) {
          this.displayContactInfo[this.charCodeList[this.charCodeList.length - 1].key].push({"idx": i, "name": person, "isChecked": this.contactList[i].isChecked, "phone": this.contactList[i].phone})
          this.charCodeList[this.charCodeList.length - 1].dataCount += 1
        }
      }
      // console.log(this.displayContactInfo)
    },
    selectedContact(_char, _contact, _index){
      let hasContact = false

      for (let i = 0; i < this.clientContactList.length; i++) {
        if (this.clientContactList[i].phone === _contact.phone) {
          hasContact = true
          // this.contactList[_index].isChecked = false
          this.displayContactInfo[_char.key][_index].isChecked = false
          this.contactList[this.displayContactInfo[_char.key][_index].idx].isChecked = false
          this.clientContactList.splice(i, 1)
          this.selectedContactCnt -= 1
          return
        }
      }

      if (!hasContact) {
        // this.contactList[_index].isChecked = true
        this.displayContactInfo[_char.key][_index].isChecked = true
        this.contactList[this.displayContactInfo[_char.key][_index].idx].isChecked = true
        this.clientContactList.push(_contact)
        this.selectedContactCnt += 1
      }

    },
    select() {
      this.$router.push({name: 'SMSContactAdd', params: {
          goodsName: this.goodsName,
          amount: this.amount,
          taxType: this.taxType,
          sendType: this.sendType,
          sendTime: this.sendTime,
          memo: this.memo,
          additionType: this.additionType,
          clientContactList: this.clientContactList}})
    }
  },
}

</script>

<style scoped>
.fixed-beginning {
  position: fixed;
  top: 60px;
  left: 0;
  height: 35px;
  width: 100%;
  background: #FAFAFA;
  z-index: 50;
  padding-left: 20px;
  padding-top: 7px;
}

</style>
