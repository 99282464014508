<template>
  <div class="all-content">
    <div class="bg-white content-row-new">
      <div class="confirm-list">
        <p>
          <img src="/image/character_hat_sad.svg" alt="캐릭터">
          <span class="mt-20 color-darkgray-4F4F4F">{{ this.amount | numberWithCommas }}원<br>충전 실패하였습니다.</span>
          <i class="mt-20 t-c">결제가 실패하였습니다.<br>다시 한번 시도하세요.</i>
        </p>
        <ul class="price-list" v-if="amount">
          <li>
            <strong>배대사</strong>
            <em>{{ selectedDeliveryAgency.nickname ? selectedDeliveryAgency.nickname : '이름없음(' + selectedDeliveryAgency.idx + ')'}} ({{ selectedDeliveryAgency.bankName }} {{ selectedDeliveryAgency.accountNumber }} {{ selectedDeliveryAgency.depositor }})</em>
          </li>
          <li>
            <strong>카드번호</strong>
            <em>{{ selectedCard.name + ' ' }}{{ selectedCard.number | cardNumberMasking }}</em>
          </li>
          <li>
            <strong>할부개월</strong>
            <em>{{ installment.name }}</em>
          </li>
          <li>
            <strong>결제일시</strong>
            <em>{{ new Date() | moment('YYYY-MM-DD HH:mm:ss') }}</em>
          </li>
          <li class="b-none">
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex-row">
      <button class="btn-gray mr-auto btn-half" @click="$router.back()">취소</button>
      <button class="btn-pink ml-auto btn-half" @click="rePayment">다시 결제</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChargeFail",
  data() {
    return {
      amount: '',
      selectedCard: null,
      selectedDeliveryAgency: null,
      installment: [],
    }
  },
  mounted() {
    this.amount = this.$route.params.amount
    this.selectedDeliveryAgency = this.$route.params.selectedDeliveryAgency
    this.installment = this.$route.params.installment
    this.selectedCard = this.$route.params.selectedCard
  },
  methods: {
    rePayment() {
      this.$router.replace({
        name: 'Charge',
        params: {
          amount: this.amount,
          selectedCard: this.selectedCard,
          selectedDeliveryAgency: this.selectedDeliveryAgency,
          selectedInstallment: this.installment
        }
      })
    }
  }
}
</script>

<style scoped>

</style>