<template>
  <div class="all-content">
    <div class="certification-wrap">
      <div class="certification-box bg-pink">
        <span class="fz-28">핀번호 재확인</span>
        <p class="mb-30">핀번호 확인을 위해<br/>다시 한번 동일한 핀번호를 입력해주세요.</p>
        <div class="certification-bullets">
          <span :class="{'bullets-active' : pin.length >= 1}"></span>
          <span :class="{'bullets-active' : pin.length >= 2}"></span>
          <span :class="{'bullets-active' : pin.length >= 3}"></span>
          <span :class="{'bullets-active' : pin.length >= 4}"></span>
          <span :class="{'bullets-active' : pin.length >= 5}"></span>
          <span :class="{'bullets-active' : pin.length >= 6}"></span>
        </div>
        <button class="btn-under color-white reset-pin-btn" @click="$router.replace({name:'ChangePinRegist',params:{old_pin:old_pin, type:type}})">핀번호 재입력</button>
      </div>
      <div class="number-area">
        <ul>
          <li>
            <button @click="numBtn('1')">1</button>
            <button @click="numBtn('2')">2</button>
            <button @click="numBtn('3')">3</button>
          </li>
          <li>
            <button @click="numBtn('4')">4</button>
            <button @click="numBtn('5')">5</button>
            <button @click="numBtn('6')">6</button>
          </li>
          <li>
            <button @click="numBtn('7')">7</button>
            <button @click="numBtn('8')">8</button>
            <button @click="numBtn('9')">9</button>
          </li>
          <li>
            <button @click="cancel()" class="cancel">취소</button>
            <button @click="numBtn('0')">0</button>
            <button @click="backspace()">
              <img src="/image/cancel.svg" alt="지우기">
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePinRegistRE",

  data() {
    return {
      old_pin:'',
      new_pin:'',
      pin: '',
      type: '0',
    }
  },
  mounted() {
    this.type = this.$route.params.type
    this.old_pin = this.$route.params.old_pin
    this.new_pin = this.$route.params.new_pin
  },

  methods: {
    numBtn(_num) {
      if (this.pin.length === 6) {
        return
      }

      this.pin = this.pin + _num
      if (this.pin.length === 6 ) {
        let formData = new FormData()
        let url = ''
        if (this.type === '1'){ // 핀찾기-변경
          url = this.$USER_RESET

          formData.append('pinNumber', this.new_pin)
          formData.append('pinNumberCheck', this.pin)

        } else {  // 마이페이지-핀변경
          url = this.$USER_PIN

          formData.append('existingPin', this.old_pin)
          formData.append('renewalPin', this.new_pin)
          formData.append('renewalPinCheck', this.pin)

          if (this.old_pin.length === 6) {
            formData.append('oldPin', this.old_pin)
          } else {
            this.notify('error', '잘못된 접근입니다.', false)
            this.pin = ''
            return
          }
        }

        this.$put(url, 'changepinregistre_numbtn', formData, true,() => {
          // window.setPin(this.pin)
          this.$router.replace({name: 'ChangePinSuccess'})
        }, (result) => {
          this.httpError(result.data)
          this.pin = ''
        })
      }
    },

    backspace() {
      if (this.pin.length === 6) {
        return
      }
      this.pin = this.pin.substr(0, this.pin.length - 1)
    },
    cancel() {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
