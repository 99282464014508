<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
    </header>
    <div class="header-wrap history-main-pay-tab bg-white t-c d-flex-row w-100 sub-header border-bottom">
      <div class="pb-10 w-50 active">결제내역</div>
      <div class="pb-10 w-50" @click="$router.replace({ name: 'SMSReservationHistory' })">카톡예약</div>
<!--      <div class="pb-10 w-auto" @click="$router.replace({ name: 'SMSReservationHistory', params: { tabIndex: 1 }})">-->
<!--        카톡예약-->
<!--      </div>-->
<!--      <div class="pb-10 w-auto" @click="$router.replace({ name: 'SMSReservationHistory', params: { tabIndex: 2 }})">카톡예약-->
<!--        내역-->
<!--      </div>-->
    </div>

    <div style="height: 74px; z-index: 50; position: fixed; top: 110px;" class="d-flex-row pl-25 pr-25 w-100 bg-white">
      <div class="d-flex-row mt-25 mb-30 jc-center"
           @click="openMonthlyList">
            <span class="fz-18 mr-5 color-gray-4F4F4F">
              {{ selectedYear + '년' }}
              {{ selectedMonth + '월' }}
            </span>
        <button type="button" class="btn-back"><i class="icon-arrow-down"></i></button>
      </div>
      <!--      <div>-->
      <div class="dropdown mt-20 ml-auto">
        <button id="dropBtn" class="dropbtn" @click="openSelectPaymentType">{{ paymentType | paymentTypeFilter }}<i
            id="dropIcon" class="icon-arrow-down-gray pl-13"></i></button>
        <div id="paymentDropdown" class="dropdown-content">
          <a @click="selectedPaymentType('')">전체</a>
          <a @click="selectedPaymentType('3')">QR</a>
          <a @click="selectedPaymentType('4')">바코드</a>
          <a @click="selectedPaymentType('1')">카톡</a>
          <a @click="selectedPaymentType('0')">수기</a>
          <a @click="selectedPaymentType('2')">단말기</a>
        </div>
      </div>
    </div>

    <div id="paymentInfoDiv" class="content-wrap-pg pl-25 pr-25">
      <div v-if="paymentInfo != null && paymentInfo.length > 0">
        <section v-for="(item, index) in paymentInfo" :key="index" class="p-section"
                 :class="[{ 'pt-10' : index === 0 }, { 'pt-50' : index > 0 }, { 'pb-20' : index === paymentInfo.length - 1 }]">
          <div class="d-flex-row border-bottom-4f4f4f items-center jc-between pb-10">
            <span class="fz-13 color-gray-4F4F4F">{{ item.exposureDate }}</span>
            <span class="fz-13 color-gray-4F4F4F" v-if="item.totalAmount != null">+{{ item.totalAmount | numberWithCommas }} 원</span>
          </div>
          <section v-for="(detailItem, index) in item.elements" :key="'detail' + index"
                   :class="{ 'border-bottom' : index < item.elements.length - 1 }"
                   @click="$router.push({ name : 'PaymentHistoryDetail', params : { idx: detailItem.paymentTransactionIdx }})">
            <div class="d-flex-row jc-between mt-15">
              <strong class="fz-15 color-pink">{{ detailItem.paymentType | paymentTypeFilter }}</strong>
              <span class="fz-13 color-darkgray">{{ detailItem.regDate }}</span>
            </div>
            <div class="mt-10">
              <span class="fz-15 color-gray-4F4F4F"
                    :class="[{ 'color-darkgray' : detailItem.state === '4'}, { 'cancel-text' : detailItem.state === '4' }]">{{
                  detailItem.goodsName
                }}</span>
            </div>
            <div class="d-flex-row jc-between mt-3 mb-15">
              <span class="fz-14 color-darkgray" v-if="detailItem.clientContact != null">{{
                  `${detailItem.clientContact.substring(0, 3)}-${detailItem.clientContact.substring(3, 7)}-${detailItem.clientContact.substring(7, 11)}`
                }}</span> <!-- 연락처 -->
              <span v-else></span>
              <span class="fz-18 color-gray-4F4F4F" v-if="detailItem.paymentType === '3'"
                    :class="[{ 'color-darkgray' : detailItem.state === '4'}, { 'cancel-text' : detailItem.state === '4' }]">{{
                  detailItem.amount | numberWithCommas
                }} PAY</span>
              <span class="fz-18 color-gray-4F4F4F" v-else
                    :class="[{ 'color-darkgray' : detailItem.state === '4'}, { 'cancel-text' : detailItem.state === '4' }]">{{
                  detailItem.amount | numberWithCommas
                }} 원</span>
            </div>
          </section>
        </section>
      </div>

      <div v-else class="list-none-wrap noCard">
        <div class="list-none">
          <p class="list-none-title fz-20 color-darkgray-4F4F4F">결제내역이 없습니다.</p>
        </div>
      </div>

      <history-month-select
          ref="historyMonthSelect"
          v-show="showMonthlyList"
          :years="yearlyDateList"
          :months="monthlyDateList"
          @close="showMonthlyList = false"
          @selected="getSelected"
      />
    </div> <!-- /content-wrap -->
  </div>
</template>

<script>
import HistoryMonthSelect from "../HistoryMonthSelect";

export default {
  name: "PGPaymentHistory",
  components: {HistoryMonthSelect},
  data() {
    return {
      paymentType: '',
      selectedYear: '',
      selectedMonth: '',

      paymentInfo: [],

      showMonthlyList: false,
      yearlyDateList: [],
      monthlyDateList: [
        {
          date: '01',
          name: '1월',
        },
        {
          date: '02',
          name: '2월',
        },
        {
          date: '03',
          name: '3월',
        },
        {
          date: '04',
          name: '4월',
        },
        {
          date: '05',
          name: '5월',
        },
        {
          date: '06',
          name: '6월',
        },
        {
          date: '07',
          name: '7월',
        },
        {
          date: '08',
          name: '8월',
        },
        {
          date: '09',
          name: '9월',
        },
        {
          date: '10',
          name: '10월',
        }, {
          date: '11',
          name: '11월',
        }, {
          date: '12',
          name: '12월',
        },
      ],
    }
  },
  created() {
  },
  mounted() {
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'RequestCancelSuccess') {
        vm.getTransactionMonths()

      } else if (from.name === 'Main' || from.name === 'SMSReservationHistory') {
        vm.initData()
      }
    })
  },
  methods: {
    initData(){
      let current = new Date()
      let month = current.getMonth() + 1
      this.selectedYear = String(current.getFullYear())
      this.selectedMonth = month < 10 ? '0' + month : String(month)

      this.initYearlyDateList()
      this.$refs.historyMonthSelect.pickedYear = this.selectedYear
      this.$refs.historyMonthSelect.pickedMonth = this.selectedMonth
      this.getTransactionMonths()
    },
    openSelectPaymentType() {
      if (!document.getElementById("dropBtn").classList.contains("dropbtn-active")) {
        document.getElementById("dropBtn").classList.add("dropbtn-active")
        document.getElementById("dropIcon").className = "icon-arrow-up-gray pl-10"
      } else {
        document.getElementById("dropBtn").classList.remove("dropbtn-active")
        document.getElementById("dropIcon").className = "icon-arrow-down-gray pl-10"
      }

      document.getElementById("paymentDropdown").classList.toggle("show")
    },
    selectedPaymentType(_index) {
      this.paymentType = _index
      document.getElementById("dropBtn").classList.remove("dropbtn-active")
      document.getElementById("dropIcon").className = "icon-arrow-down-gray pl-10"
      document.getElementById("paymentDropdown").classList.remove("show")

      window.scrollY = window.$('#paymentInfoDiv').scrollTop()

      this.getTransactionMonths()
    },
    initYearlyDateList() {
      this.yearlyDateList = []

      let currentYear = new Date().getFullYear()
      for (let i = 0; i <= 10; i++) {
        let year = currentYear - (10 - i)
        this.yearlyDateList.push({date: year.toString(), name: year + '년'})
      }
    },
    openMonthlyList() {
      this.showMonthlyList = true
    },
    getSelected(_pickedYear, _pickedMonth) {
      this.selectedYear = _pickedYear
      this.selectedMonth = _pickedMonth

      this.getTransactionMonths()
    },
    getTransactionMonths() {
      let params = new URLSearchParams()

      if (this.paymentType !== '') {
        params.append('paymentType', this.paymentType)
      }

      params.append('date', `${this.selectedYear}-${this.selectedMonth}`)

      this.$get(this.$TRANSACTION_MONTHS + '?' + params.toString(), this.$options.name + '_getTransactionMonths', (result) => {
        this.paymentInfo = result.data

      }, (result) => {
        this.httpError(result.data)

      })
    },
  },
  filters: {
    paymentTypeFilter: (value) => {
      if (value === '0') {
        return '수기'
      } else if (value === '1' || value === '5') {
        return '카톡'
      } else if (value === '2') {
        return '단말기'
      } else if (value === '3') {
        return 'QR'
      } else if (value === '4') {
        return '바코드'
      } else if (value === '') {
        return '전체'
      }
    },
  },
}
</script>

<style scoped>
.select-wrap::after {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
  content: "\e901";
  pointer-events: none;
  color: #808080;
  font-family: 'icomoon' !important;
  font-size: 13px;
}

.dropbtn {
  background-color: #FFFFFF;
  color: #808080;
  padding: 10px;
  font-size: 13px;
  border: 1px solid #C4C4C4;
  border-radius: 5px;
  box-sizing: border-box;
  /*width: 70px;*/
  /*height: 36px;*/
}

.dropbtn-active {
  border: 1.5px solid #4F4F4F;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  /*border: 1.5px solid #4F4F4F;*/
}

/* The container <div> - needed to position the dropdown content */
.dropdown::after {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFFFFF;
  border: 0.5px solid #DDDDDD;
  min-width: 70px;
  z-index: 1;
  border-radius: 5px;
  margin-top: 5px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  font-size: 13px;
}

.dropdown-content a:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.dropdown-content a:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #F2F3F5;
  -webkit-tap-highlight-color: transparent !important;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}

</style>