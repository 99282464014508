<template>
  <div class="all-content bg-white" id="quickDiv">
    <header class="header-wrap border-bottom">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>배달비/월세 결제내역</p>
    </header>
    <div class="header-wrap quick-history-tab bg-white t-c d-flex-row w-100 sub-header border-bottom">
      <div class="pb-10 w-50per active">배달비</div>
      <div class="pb-10 w-50per" @click="$router.replace({name:'RentPaymentHistory'})">월세</div>
    </div>
    <div class="content-wrap-quick bg-gray">
      <!-- 배달비 결제내역 -->
      <div>
        <section class="top-box2 bg-white">
          <div class="d-flex-row mt-20 mb-30 jc-center"
               @click="openMonthlyList">
            <span class="fz-18 mr-5 color-gray-4F4F4F">
              {{ selectedYear + '년' }}
              {{ selectedMonth + '월' }}
            </span>
            <button type="button" class="btn-back"><i class="icon-arrow-down"></i></button>
          </div>
          <span class="color-darkgray">{{ startDate }} ~ {{ endDate }}</span>
          <section class="all-amount-radius bg-pink mt-10" style="padding: 16px 15px">
            <div class="d-flex-row">
              <span class="mr-auto color-white fz-18">총 합계</span>
              <span class="ml-auto color-white fz-24">{{ totalSum === null ? 0 : totalSum | numberWithCommas }} 원</span>
            </div>
          </section>
        </section><!--/top-box2 -->

        <div v-if="loaded && items.length === 0">
          <div class="list-none-wrap noCard">
            <div class="list-none">
              <p class="list-none-title fz-20 color-darkgray-4F4F4F">결제내역이 없습니다.</p>
            </div>
          </div>
        </div>

        <div v-else-if="items.length > 0" class="bg-gray">
          <section class="payment-history-section" v-for="(item, index) in items" :key="index">
            <!-- v-for 들어갈 부분(1) -->
            <div class="white-box-br-10">
              <div class="p-20">
                <div class="d-flex-row border-bottom jc-between">
                  <span class="fz-18 mb-20" :class="[item.groupDate * 1 === day * 1 ? 'color-pink' : '']">{{
                      item.groupDate * 1 === day * 1 ? '오늘' : (item.groupDate * 1) + '일'
                    }}</span>
                  <span class="fz-18 mb-20">{{ item.groupSum | numberWithCommas }}원</span>
                </div>
                <div class="mt-20" v-for="(item2, index) in item.elements" :key="index"
                     @click="$router.push({ name: 'ChargePaymentHistoryDetail', params: { quickIdx: item2.idx } })">
                  <span class="color-darkgray fz-14">{{ convertDate(item2.createAt) }}</span>

                  <div>
                    <div class="d-flex-row mt-5 jc-between">
                      <span class="fz-16">결제</span>
                      <span class="fz-16">{{ item2.totalAmount | numberWithCommas }}원</span>
                    </div>
                  </div>
                  <div class="d-flex-row jc-between">
                    <span class="fz-16">수수료 ( VAT별도 )</span>
                    <span class="fz-16">{{ item2.fee | numberWithCommas }}원</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <history-month-select
            ref="historyMonthSelect"
            v-show="showMonthlyList"
            :years="yearlyDateList"
            :months="monthlyDateList"
            @close="showMonthlyList = false"
            @selected="getSelected"
        />
      </div> <!-- /배달비 결제내역 -->
    </div> <!-- /content-wrap -->
  </div>
</template>

<script>
import HistoryMonthSelect from "@/components/history/HistoryMonthSelect";
import moment from 'moment';

export default {
  name: "QuickPaymentHistory",
  components: {HistoryMonthSelect},
  data() {
    return {
      selectedYear: '',
      selectedMonth: '',
      oldYear: '',
      oldMonth: '',

      day: '',

      page: 1,
      size: 10,
      loaded: false,

      showMonthlyList: false,

      items: [],

      startDate: '',
      endDate: '',
      totalSum: 0,

      yearlyDateList: [],
      monthlyDateList: [
        {
          date: '01',
          name: '1월',
        },
        {
          date: '02',
          name: '2월',
        },
        {
          date: '03',
          name: '3월',
        },
        {
          date: '04',
          name: '4월',
        },
        {
          date: '05',
          name: '5월',
        },
        {
          date: '06',
          name: '6월',
        },
        {
          date: '07',
          name: '7월',
        },
        {
          date: '08',
          name: '8월',
        },
        {
          date: '09',
          name: '9월',
        },
        {
          date: '10',
          name: '10월',
        }, {
          date: '11',
          name: '11월',
        }, {
          date: '12',
          name: '12월',
        },
      ],

    }
  },
  created() {
  },
  mounted() {
    window.$('#quickDiv').on('scroll', this.onScrollEvent)
  },
  beforeDestroy() {
    window.$('#quickDiv').off('scroll', this.onScrollEvent)
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'Main' || from.name === 'RentPaymentHistory') {
        vm.initData()
      }
    })
  },
  methods: {
    initData(){
      let current = new Date()
      this.selectedYear = String(current.getFullYear())
      let month = current.getMonth() + 1
      this.selectedMonth = month < 10 ? '0' + month : String(month)
      this.day = String(current.getDate())

      this.initYearlyDateList()
      this.$refs.historyMonthSelect.pickedYear = this.selectedYear
      this.$refs.historyMonthSelect.pickedMonth = this.selectedMonth
      this.getQuickPaymentList()
    },
    convertDate(_date) {
      let convertedDate = ''

      let createdDate = new Date(_date) // format : 2021-11-23T16:39:51
      let current = new Date()
      let minus = current.getTime() - createdDate.getTime()

      if (Math.floor(minus / 1000) < 60) { // 1분 이내
        convertedDate = Math.floor(minus / 1000) + '초 전'

      } else if (Math.floor(minus / (1000 * 60 * 60)) < 24) { // 24시간 이내, 오전(오후) 00:00
        convertedDate = moment(createdDate).format('A HH:mm')

      } else { // YYYY.MM.DD 오전(오후) 00:00
        convertedDate = moment(createdDate).format('YYYY.MM.DD A HH:mm')
      }

      return convertedDate
    },
    onScrollEvent() {
      if (window.$('#quickDiv').scrollTop() + window.$('#quickDiv').innerHeight() >= window.$('#quickDiv')[0].scrollHeight - 10) {
        this.getQuickPaymentList()
      }
    },
    initYearlyDateList() {
      this.yearlyDateList = []
      for (let i = 0; i <= 10; i++) {
        let year = new Date().getFullYear() - (10 - i)
        this.yearlyDateList.push({date: year.toString(), name: year + '년'})
      }
    },
    openMonthlyList() {
      this.showMonthlyList = true
    },
    getSelected(_pickedYear, _pickedMonth) {
      this.selectedYear = _pickedYear
      this.selectedMonth = _pickedMonth

      this.getQuickPaymentList()
    },
    getQuickPaymentList() {
      this.loaded = false

      if (this.oldYear !== this.selectedYear || this.oldMonth !== this.selectedMonth) {
        this.items = []
        this.page = 1
        this.size = 10
      }

      let params = new URLSearchParams()
      params.append('page', this.page)
      params.append('size', this.size)
      params.append('date', `${this.selectedYear}-${this.selectedMonth}`)

      this.$get(this.$QUICK_LIST + '?' + params.toString(), this.$options.name + '_getQuickPaymentList', (result) => {
        this.oldYear = this.selectedYear
        this.oldMonth = this.selectedMonth

        this.startDate = result.data.data.startDate
        this.endDate = result.data.data.endDate
        this.totalSum = result.data.data.totalSum

        if (result.data.data.groupList.length > 0) {
          this.items = this.items.concat(result.data.data.groupList)
          this.page++
        }

        this.loaded = true

      }, (result) => {
        this.httpError(result.data)
      })
    },
  },
}
</script>

<style scoped>

</style>