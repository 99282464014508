<template>
  <div class="all-content bg-white sms-pay">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>수기 결제</p>
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pb-80">
        <div class="charge-form pt-0">
          <p class="mb-10 fz-16 mt-30">상품명<span class="color-red">*</span></p>
          <div class="d-flex-row">
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'상품명을 입력해주세요'"
                  :model="itemName"
                  @update="(_model) => {this.itemName = _model}"
                  :input-mode="'text'"
              />
            </div>
          </div>
          <p class="mb-10 fz-16 mt-30">결제금액<span class="color-red">*</span></p>
          <div class="d-flex-row">
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'결제금액을 입력해주세요.(100원 이상)'"
                  :model="payAmt"
                  @update="updatePayAmt"
                  :input-mode="'decimal'"
              />
            </div>
          </div>
          <p class="mb-10 mt-30 fz-16">부과세</p>
          <div class="tab-tax">
            <div :class="{ activeTax: isTax }" @click="isTax = !isTax">과세</div>
            <div :class="{ activeNoTax: !isTax }" @click="isTax = !isTax">면세</div>
          </div>
          <p class="mb-10 fz-16 mt-30">고객번호<span class="color-red">*</span></p>
          <div class="d-flex-row">
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'휴대폰 번호를 입력해주세요'"
                  :model="phone"
                  :max-length="11"
                  @update="(_model) => {this.phone = _model}"
                  :input-mode="'numeric'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="goNext">다음</button>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";

export default {
  name: "HandPayment",
  components: {
    RPInputBorder,
  },
  data() {
    return {
      itemName: '',
      payAmt: '',
      isTax: true,
      phone: '',
      manualPaymentType: ''
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'Main') {
        vm.clearData()
      }
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$PAYMENT_HANDWRITTEN, this.$options.name + '_getPaymentHandwritten', (result) => {
        console.log(result.data, 'handwritten')
        this.manualPaymentType = result.data.manualPaymentType

      }, (result) => {
        this.httpError(result.data)
      })
    },
    clearData() {
      this.itemName = ''
      this.payAmt = ''
      this.isTax = true
      this.phone = ''
    },
    goNext() {
      if (this.itemName === '') {
        this.notify('error', '상품명을 입력해주세요.')
        return
      }
      if (this.payAmt === '') {
        this.notify('error', '결제금액을 입력해주세요.')
        return
      }
      if (this.payAmt * 1 < 100) {
        this.notify('error', '결제금액을 100원 이상 입력해주세요.')
        return
      }
      if (this.phone === '') {
        this.notify('error', '휴대폰 번호를 입력해주세요.')
        return
      }
      this.$router.push({
        name: 'HandPaymentCardInfo',
        params: {
          itemName: this.itemName,
          payAmt: this.payAmt,
          phone: this.phone,
          isTax: (this.isTax ? '0' : '1'),
          type: this.manualPaymentType
        }
      })
    },
    updatePayAmt(_model) {
      this.payAmt = _model
      this.showPartPay = Number(this.payAmt) >= 50000
    },
    changeNextBtn() {
      if (this.itemName === '' || (this.payAmt * 1 <= 0) || this.phone === '') {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      } else {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-pink"
      }
    },
  },
  watch: {
    itemName() {
      this.changeNextBtn()
    },
    payAmt() {
      this.changeNextBtn()
    },
    phone() {
      this.changeNextBtn()
    },
  }
}
</script>

<style scoped>

</style>
