<template>
  <div class="all-content bg-white sms-pay">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>{{ isScanMode ? 'QR 스캔' : 'QR 생성' }}</p>
      <button type="button" class="btn-close" @click="changeMode()">
        <img v-show="!isScanMode" src="/image/footer_menu2.svg">
        <img v-show="isScanMode" src="/image/qr_code_black.svg">
      </button>
    </header>
    <div class="content-wrap h-auto">
      <div class="main-wrap pb-80">
        <div class="charge-form pt-0">
          <p class="mb-10 fz-16 mt-30">결제금액</p>
          <div class="d-flex-row">
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'결제금액을 입력해주세요'"
                  :model="payAmt"
                  @update="updatePayAmt"
                  :input-mode="'decimal'"
              />
            </div>
          </div>
          <span class="color-red">{{ errors.payAmt }}</span>
        </div>
      </div>
      <button id="nextBtn" class="align-bottom btn btn-m btn-dgray-4D4D4D" @click="goNext">결제</button>
    </div>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";

export default {
  name: "QRScanInputAmount",
  components: {
    RPInputBorder,
  },
  data() {
    return {
      isScanMode: false,
      errors: {
        payAmt: '',
      },
      payAmt:'',
    }
  },
  mounted() {
    window.addEventListener(this.$EVENT_QR_STRING, this.setQr)
  },
  beforeDestroy() {
    window.removeEventListener(this.$EVENT_QR_STRING, this.setQr)
  },
  methods: {
    errorClear() {
      this.errors = {
        payAmt: '',
      }
    },
    updatePayAmt(_model) {
      this.payAmt = _model
    },
    changeMode() {
      this.isScanMode = !this.isScanMode
    },
    goNext() {
      this.errorClear()
      if (this.payAmt === '') {
        this.errors.payAmt = '결제금액을 입력해주세요'
        return
      }

      if (this.payAmt <= 0) {
        this.errors.payAmt = '0원보다 큰 금액을 입력해주세요.'
        return
      }

      if (!/^[0-9-][0-9,.]*$/.test(this.payAmt)) {
        this.errors.payAmt = '결제금액을 정확하게 입력해주세요.'
        return
      }

      if (this.isScanMode) {  // qr스캔
        window.openQRReader('QR 스캔')

      } else {  // qr생성
        this.$router.push({name: 'QRPayment', params: {amount: this.payAmt}})
      }
    },
    setQr(e) {
      let qr = e.detail
      console.log('qr 인식 결과 : ' + qr)
      // console.log('qr코드 읽은 뒤 결제 진행 중 화면이동 처리')

      // 결제 중
      this.$router.push({name: 'QRPaymentFast', params: {qr: qr, amount: this.payAmt}})
    },
    changeNextBtn() {
      if (this.payAmt === '' || this.payAmt <= 0 || !/^[0-9-][0-9,.]*$/.test(this.payAmt)){
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m btn-dgray-4D4D4D"
      } else {
        window.document.getElementById("nextBtn").className = "align-bottom btn btn-m  btn-pink"
      }
    },
  },
  watch: {
    payAmt(){
      this.changeNextBtn()
    }
  }
}
</script>

<style scoped>

</style>
