<template>
  <div class="all-content">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p v-if="!isEdit">문의하기</p>
      <p v-else>문의수정</p>
    </header>
    <div class="content-wrap-qna bg-white">
      <div class="main-wrap">
        <div class="question-content select-question-wrap">
          <div class="select-wrap">
            <select class="select select-question" v-model="item.qcategoryIdx">
              <option value="">상담 분류</option>
              <option v-for="(category, index) in qnaCategory" :key="index" :value="category.idx">{{
                  category.name
                }}
              </option>
            </select>
          </div>
        </div>
        <div class="question-content">
          <input type="text" class="qna-input-box question-input-box" maxlength="40" placeholder="제목을 입력하세요"
                 v-model="item.title">
        </div>
        <div class="question-content">
          <textarea class="textarea-box textarea-line-11" maxlength="400" placeholder="내용을 입력하세요"
                    v-model="item.content"></textarea>
        </div>
        <div class="question-content">
          <button type="button" class="btn-dgray question-btn question-btn-set" @click="uploadImage()">첨부파일</button>
          <div class="d-flex-row qna-input-box question-input-box" style="padding: 0px;">
            <input type="text" class="question-input-box w-90 pl-110" readonly v-model="item.originFileName"/>
            <input type="file" class="question-input-box w-90 pl-110" @change="upload($event)"
                   id="imageFileUpload" accept="image/*" hidden>
            <button class="w-10" v-show="!isEdit && item.originFileName !== ''" @click="deleteFile"><i class="icon-close"></i></button>
          </div>
        </div>
        <div class="question-content">
          <input type="text" class="qna-input-box question-input-box fz-13" value="" disabled v-model="item.version">
        </div>
        <div class="question-content checks-basic text-underline" v-if="!isEdit">
          <input type="checkbox" id="agree" v-model="qnaAgree">
          <label for="agree">(필수)개인정보 수집,이용동의</label>
          <button type="button" class="btn pl-5" @click="goTerm"><i class="icon-arrow-right"></i></button>
        </div>
      </div>
    </div>
    <footer class="btn-wrap bg-white">
      <div class="row">
        <button v-if="!isEdit" class="btn-l btn-pink btn-round mb-50" @click="regist()">등록</button>
        <button v-else class="btn-l btn-pink btn-round mb-50" @click="edit()">수정</button>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "QnaRegist",
  data() {
    return {
      isEdit: false,
      qnaCategory: [],

      item: {
        qcategoryIdx: '',
        content: '',
        fileIdx: '',
        idx: '',
        originFileName: '',
        title: '',
        version: ''
      },

      qnaFile: null,
      qnaAgree: false,
      // deleteFileIdx: '',
    }
  },
  mounted() {
    this.clearData()
  },
  beforeRouteLeave(to, from, next){
    if (to.name === 'QnaTerm'){
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }

    next()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'QnaList' || from.name === 'QnaDetail') {
        vm.clearData()
      }
    })
  },
  methods: {
    clearData(){
      this.isEdit = false
      this.qnaCategory = []
      this.item = {
        qcategoryIdx: '',
        content: '',
        fileIdx: '',
        idx: '',
        originFileName: '',
        title: '',
        version: ''
      }
      this.qnaFile = null
      this.qnaAgree = false

      if (this.$route.params.idx && this.$route.params.idx !== '') {
        this.isEdit = true
      }
      this.$get(this.$QNA_CATEGORY, 'qnsregist_mounted', (result) => {
        this.qnaCategory = result.data

        if (this.isEdit) {
          this.qnaAgree = true
          this.$get(this.$QNA_LIST + this.$route.params.idx, 'qnaregist_mounted', (result) => {
                this.item = result.data
                console.log(this.item.qcategoryIdx)

              }, (result) => {
                this.httpError(result.data)
              }
          )

        } else {
          this.item.version = '폰기종 : ' + this.deviceInfo(window.getDeviceInfo()) + '/ OS : ' + window.getOSInfo()
        }

      }, (result) => {
        this.httpError(result.data)
      })
    },
    uploadImage() {
      window.document.getElementById('imageFileUpload').click()
    },
    upload(e) {
      // if (this.isEdit && this.item.fileIdx) {
      //   this.deleteFileIdx = this.item.fileIdx
      // }
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      this.qnaFile = files[0]
      this.item.originFileName = files[0].name
    },
    deleteFile(){
      this.qnaFile = null
      this.item.originFileName = ''
    },
    goTerm(){
      this.$router.push({name: 'QnaTerm'})
    },
    regist() {
      if (this.item.qcategoryIdx === '') {
        this.notify('error', '상담 분류를 선택해주세요.')
        return
      }
      if (this.item.title === '') {
        this.notify('error', '제목을 입력해주세요.')
        return
      }
      if (this.item.content === '') {
        this.notify('error', '내용을 입력해주세요.')
        return
      }
      if (this.qnaAgree === false) {
        this.notify('error', '동의 후 등록 가능 합니다.')
        return
      }
      let formData = new FormData()
      formData.append('categoryIdx', this.item.qcategoryIdx)
      formData.append('title', this.item.title)
      formData.append('content', this.item.content)
      formData.append('version', this.item.version)

      if (this.qnaFile !== null) {
        formData.append('multipartFile', this.qnaFile)
      }

      this.$post(this.$QNA_REGIST, 'qnaregist_regist', formData, true, () => {
        this.notify('success', '성공적으로 등록되었습니다.', false)
        this.$router.back()
      }, (result) => {
        this.httpError(result.data)
      })

    },
    edit() {
      if (this.item.qcategoryIdx === '') {
        this.notify('error', '상담 분류를 선택해주세요.')
        return
      }
      if (this.item.title === '') {
        this.notify('error', '제목을 입력해주세요.')
        return
      }
      if (this.item.content === '') {
        this.notify('error', '내용을 입력해주세요.')
        return
      }
      let formData = new FormData()
      formData.append('categoryIdx', this.item.qcategoryIdx)
      formData.append('title', this.item.title)
      formData.append('content', this.item.content)
      formData.append('originQnaIdx', this.$route.params.idx)

      // if (this.deleteFileIdx !== '') {
      //   formData.append('deleteIdx', this.deleteFileIdx)
      // }

      if (this.qnaFile !== null) {
        formData.append('multipartFile', this.qnaFile)
      }
      this.$put(this.$QNA_EDIT, 'qnaregist_edit', formData, true, () => {
        this.$root.$refs.Alert.showAlert('',
            '수정이 완료되었습니다.',
            () => {
              this.$router.back()
            }, false, false)
      }, (result) => {
        this.httpError(result.data)
      })
    },
    deviceInfo(identifier) {
      switch (identifier) {
        case "iPhone6,1", "iPhone6,2":
          return "iPhone 5s"
        case "iPhone7,2":
          return "iPhone 6"
        case "iPhone7,1":
          return "iPhone 6 Plus"
        case "iPhone8,1":
          return "iPhone 6s"
        case "iPhone8,2":
          return "iPhone 6s Plus"
        case "iPhone8,4":
          return "iPhone SE"
        case "iPhone9,1", "iPhone9,3":
          return "iPhone 7"
        case "iPhone9,2", "iPhone9,4":
          return "iPhone 7 Plus"
        case "iPhone10,1", "iPhone10,4":
          return "iPhone 8"
        case "iPhone10,2", "iPhone10,5":
          return "iPhone 8 Plus"
        case "iPhone10,3", "iPhone10,6":
          return "iPhone X"
        case "iPhone11,2":
          return "iPhone XS"
        case "iPhone11,4", "iPhone11,6":
          return "iPhone XS Max"
        case "iPhone11,8":
          return "iPhone XR"
        case "iPhone12,1":
          return "iPhone 11"
        case "iPhone12,3":
          return "iPhone 11 Pro"
        case "iPhone12,5":
          return "iPhone 11 Pro Max"
        case "iPhone12,8":
          return "iPhone SE (2nd generation)"
        case "iPhone13,1":
          return "iPhone 12 mini"
        case "iPhone13,2":
          return "iPhone 12"
        case "iPhone13,3":
          return "iPhone 12 Pro"
        case "iPhone13,4":
          return "iPhone 12 Pro Max"
        case "iPhone14,4":
          return "iPhone 13 mini"
        case "iPhone14,5":
          return "iPhone 13"
        case "iPhone14,2":
          return "iPhone 13 Pro"
        case "iPhone14,3":
          return "iPhone 13 Pro Max"
        case "iPad6,11", "iPad6,12":
          return "iPad (5th generation)"
        case "iPad7,5", "iPad7,6":
          return "iPad (6th generation)"
        case "iPad7,11", "iPad7,12":
          return "iPad (7th generation)"
        case "iPad11,6", "iPad11,7":
          return "iPad (8th generation)"
        case "iPad12,1", "iPad12,2":
          return "iPad (9th generation)"
        case "iPad4,1", "iPad4,2", "iPad4,3":
          return "iPad Air"
        case "iPad5,3", "iPad5,4":
          return "iPad Air 2"
        case "iPad11,3", "iPad11,4":
          return "iPad Air (3rd generation)"
        case "iPad13,1", "iPad13,2":
          return "iPad Air (4th generation)"
        case "iPad2,5", "iPad2,6", "iPad2,7":
          return "iPad mini"
        case "iPad4,4", "iPad4,5", "iPad4,6":
          return "iPad mini 2"
        case "iPad4,7", "iPad4,8", "iPad4,9":
          return "iPad mini 3"
        case "iPad5,1", "iPad5,2":
          return "iPad mini 4"
        case "iPad11,1", "iPad11,2":
          return "iPad mini (5th generation)"
        case "iPad14,1", "iPad14,2":
          return "iPad mini (6th generation)"
        case "iPad6,3", "iPad6,4":
          return "iPad Pro (9.7-inch)"
        case "iPad7,3", "iPad7,4":
          return "iPad Pro (10.5-inch)"
        case "iPad8,1", "iPad8,2", "iPad8,3", "iPad8,4":
          return "iPad Pro (11-inch) (1st generation)"
        case "iPad8,9", "iPad8,10":
          return "iPad Pro (11-inch) (2nd generation)"
        case "iPad6,7", "iPad6,8":
          return "iPad Pro (12.9-inch) (1st generation)"
        case "iPad7,1", "iPad7,2":
          return "iPad Pro (12.9-inch) (2nd generation)"
        case "iPad8,5", "iPad8,6", "iPad8,7", "iPad8,8":
          return "iPad Pro (12.9-inch) (3rd generation)"
        case "iPad8,11", "iPad8,12":
          return "iPad Pro (12.9-inch) (4th generation)"
        case "iPad13,4", "iPad13,5", "iPad13,6", "iPad13,7":
          return "iPad Pro (11-inch) (3rd generation)"
        case "iPad13,8", "iPad13,9", "iPad13,10", "iPad13,11":
          return "iPad Pro (12.9-inch) (5th generation)"
        default:
          return identifier
      }
    }
  },
}
</script>

<style scoped>

</style>
