<template>
  <div class="all-content bg-white">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>비밀번호 변경</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap pt-0">
        <div class="charge-form">
          <p class="mb-10">신규 비밀번호</p>
          <div class="select">
            <RPInputBorder
                :type="'password'"
                :placeholder="'비밀번호(영문, 숫자, 특수문자 포함 8자 이상)'"
                :model="password"
                :max-length="20"
                @update="(_model) => {this.password=_model}"
            />
          </div>
          <span class="color-red">{{ errors.password }}</span>

          <p class="mb-10 mt-20">비밀번호 확인</p>
          <div class="select">
            <RPInputBorder
                :type="'password'"
                :placeholder="'비밀번호를 한번 더 입력해주세요.'"
                :model="passwordCk"
                :max-length="20"
                @update="(_model) => {this.passwordCk=_model}"
            />
          </div>
          <span class="color-red">{{ errors.passwordCk }}</span>
        </div>
      </div>
      <button class="align-bottom btn btn-m btn-pink" @click="changePassword()">비밀번호 변경</button>
    </div>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";

export default {
  name: "SsoFindPwChange",
  components: {
    RPInputBorder
  },
  data() {
    return {
      id: '',
      auth: '',
      password: '',
      passwordCk: '',
      errors: {
        password: '',
        passwordCk: ''
      },
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.auth = this.$route.params.auth
  },
  methods: {
    errorClear() {
      this.errors = {password: '', passwordCk: ''}
    },
    changePassword() {
      this.errorClear()

      if (this.password === '') {
        this.errors.password = '비밀번호를 입력해주세요.'
        return
      }

      if (this.passwordCk === '') {
        this.errors.passwordCk = '비밀번호를 한번 더 입력해주세요.'
        return
      }

      if (this.password !== this.passwordCk) {
        this.errors.passwordCk = '비밀번호가 일치하지 않습니다.'
        return
      }

      if (!this.validatePw(this.password)) {
        this.errors.password = '비밀번호는 영문, 숫자, 특수문자 포함 8자 이상으로 입력해주세요.'
        return
      }

      // api 연동
      let formData = new FormData()
      formData.append('id', this.id)
      formData.append('auth', this.auth)
      formData.append('password', this.password)
      formData.append('passwordCk', this.passwordCk)

      this.$put(this.$AUTH_UPDATE_FIND_PW, 'findpwchange_updatefindpw', formData, true,() => {
        this.$router.replace({name: 'SsoFindPwChangeFinish'})

      }, (result) => {
        if (result.data.error.length > 0) {
          for (let i = 0; i < result.data.error.length; i++) {
            let err = result.data.error[i]
            this.errors[err.field] = err.message
          }
        } else if (result.data.message) {
          this.errors.password = result.data.message
        } else {
          this.errors.password = '사용할 수 없는 비밀번호입니다.'
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
