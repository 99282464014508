<template>
  <div class="overlay" @click.self="$emit('close')">
    <div class="select-list">
      <div class="d-flex-row jc-between">
        <h5>유형 선택</h5>
        <button type="button" class="btn-close" @click="$emit('close')"><i class="icon-close"></i></button>
      </div>
      <ul>
        <li v-for="(condition, index) in conditions" :key="index">
          <a @click="selected(condition)">
            <span>{{ condition.name }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "RentTypeSelect",
  props: ['conditions'],
  methods: {
    selected(_condition) {
      this.$emit('selected', _condition)
    }
  }
}
</script>

<style scoped>

</style>